import { Injectable } from '@angular/core';
import { PaginationModel } from '@app/models';
import { QueryCommon } from '@app/models/app/query-common';
import { BaseModel } from '@app/models/base.model';
import { NewsModel } from '@app/models/news/news.model';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private url = 'feed';

  constructor(private httpService: HttpService) {}

  getList(params: Partial<QueryCommon> = {}): Observable<PaginationModel<NewsModel>> {
    return this.httpService.getRequest<PaginationModel<NewsModel>>(`${this.url}`, true, params);
  }

  getFeed(id: string): Observable<BaseModel<NewsModel>> {
    return this.httpService.getRequest(`${this.url}/${id}`, true);
  }
}
