import { NO_IMAGE_URL } from '@app/models/app.config';

export class UserShortInfoModel {
  public id: string;
  public expert_id: string;
  public pet_owner_id: string;
  public full_name: string;
  public image_url: string;

  public getImageUrl(): string {
    return this.image_url ? this.image_url : NO_IMAGE_URL;
  }
}
