import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { AspectRatioComponent } from './aspect-ratio/aspect-ratio.component';
import { ImageShellComponent } from './image-shell/image-shell.component';
import { TextShellComponent } from './text-shell/text-shell.component';

@NgModule({
  declarations: [AspectRatioComponent, ImageShellComponent, TextShellComponent],
  imports: [CommonModule, IonicModule, LazyLoadImageModule],
  exports: [AspectRatioComponent, ImageShellComponent, TextShellComponent],
})
export class ShellModule {}
