// angular
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { TranslationBrowserModule } from '@app/shared/translation/translation-browser';
import { environment } from '@environments/environment';
// components
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import * as Sentry from '@sentry/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StateTransferInitializerModule } from '@nguniversal/common';
import { Router } from '@angular/router';

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    AppModule,
    StateTransferInitializerModule,
    BrowserTransferStateModule,
    TranslationBrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    { provide: 'ORIGIN_URL', useValue: location.origin },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppBrowserModule {}
