import { Injectable } from '@angular/core';
import { BaseModel } from '@app/models/base.model';
import { PaginationModel } from '@app/models/pagination.model';
import { TemplateListingModel } from '@app/models/template/template.listing.model';
import { TemplateModel } from '@app/models/template/template.model';
import { TemplatePreviewModel } from '@app/models/template/template.preview.model';
import { HttpService } from '@app/services';
import { Observable } from 'rxjs';
import { ListRequest, SaveTemplateRequest } from '@app/models/requests';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private url = 'template';

  constructor(private httpService: HttpService) {}

  getList(queryParams: ListRequest): Observable<PaginationModel<TemplateModel>> {
    return this.httpService.getRequest<PaginationModel<TemplateModel>>(this.url, true, queryParams);
  }

  getTemplate(id: string): Observable<TemplateListingModel> {
    return this.httpService.getRequest(`${this.url}/${id}`, true);
  }

  /**
   * Preview Template from user side
   * @param params
   */
  previewTemplateForUser(params: Record<string, unknown>): Observable<BaseModel<TemplatePreviewModel>> {
    return this.httpService.postRequest(`${this.url}/user/preview/render`, params, true);
  }

  /**
   * Preview Template
   * @param params
   */
  previewTemplate(params: Record<string, unknown>): Observable<BaseModel<TemplatePreviewModel>> {
    return this.httpService.postRequest(`${this.url}/preview/render`, params, true);
  }

  /**
   * Send template to user
   * @param params
   */
  sendTemplate(params: Record<string, unknown>): Observable<BaseModel<TemplatePreviewModel>> {
    return this.httpService.postRequest(`${this.url}/preview/send`, params, true);
  }

  previewPDFTemplate(params: {
    sampledata: string;
    lang: string;
    text: string;
    basetemplate: string;
    type: string;
  }): Observable<Blob> {
    return this.httpService.postRequest(`${this.url}/preview/render`, params, true, true);
  }

  deleteTemplate(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  saveTemplate(
    id: string,
    params: SaveTemplateRequest,
    image: File | Blob = null,
    isImageUpdated: boolean = false
  ): Observable<void> {
    if (id) {
      // Need this case because user might try to save expert data without selecting image
      // In this case we must not pass image as it might be null
      if (isImageUpdated) {
        return this.httpService.multiPartFormDataRequest('PUT', `${this.url}/${id}`, params, true, 'image', image);
      }
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      if (isImageUpdated) {
        return this.httpService.multiPartFormDataRequest('POST', `${this.url}`, params, true, 'image', image);
      }
      return this.httpService.postRequest(`${this.url}`, params, true);
    }
  }
}
