import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AppState } from '@app/store/app.state';
import { Observable, Subscription } from 'rxjs';
import { CountryModel } from '@app/models';
import { environment } from '@environments/environment';
import { SelectItem } from '@app/shared/components/select-list/select-list';
import { AppService, RegionService } from '@app/services';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-country-switcher',
  styleUrls: ['./country-switcher.scss'],
  templateUrl: './country-switcher.html',
})
export class CountrySwitcherComponent implements OnInit, OnDestroy {
  @Select(AppState.country) country$: Observable<CountryModel>;
  country = '';
  isModalOpen = false;
  selectItems: SelectItem[] = [];
  subscriptions = new Subscription();

  constructor(
    private appService: AppService,
    private regionService: RegionService,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.country$.subscribe((country) => {
        if (!country || !environment.availableCountries.find(item => item == country.short)) {
          this.openModal();
        } else {
          this.country = country.short;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadSelectItems() {
    this.regionService.getCountryList().pipe(
      map((response) => {
        return environment.availableCountries.map((countryCode) => {
          const country = response.data.find((c) => c.short === countryCode);
          return {
            iconClass: 'fi fi-' + countryCode.toLowerCase(),
            label: country.name,
            value: countryCode,
          };
        });
      })
    ).subscribe((selectItems) => {
      this.selectItems = selectItems.sort((a, b) => a.label.localeCompare(b.label));
    });
  }

  openModal() {
    if (!this.selectItems.length)
      this.loadSelectItems();

    this.isModalOpen = true;
  }

  selectedItem(value: unknown) {
    const countryCode = value as string;
    const locale = this.store.selectSnapshot(AppState.locale)?.substring(0, 2) + '-' + countryCode;
    const newUrl = this.appService.replaceLocaleRouteSegment(this.router.url, locale, false);
    this.isModalOpen = false;
    this.selectItems = [];
    this.router.navigateByUrl(newUrl);
  }
}