import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './app-button.html',
  styleUrls: ['./app-button.scss'],
})
export class AppButtonComponent {
  @Input() color: 'blue-1' | 'blue-2' | 'green-1' | 'pink-1' = 'blue-1';
  @Input() disabled = false;
  @Input() iconName = '';
  @Input() iconSrc = '';
  @Input() invert = false;
  @Input() label = '';
  @Input() outline = false;
  @Input() roundedSides: 'both' | 'left' | 'right' = 'both';

  get classes(): string {
    const classes = [
      'tw-w-full',
      'tw-h-[38px]',
      'tw-px-4',
      'tw-flex',
      'tw-flex-row',
      'tw-justify-center',
      'tw-items-center',
      'tw-gap-2',
      'tw-text-sm',
      'tw-font-bold',
      'tw-whitespace-nowrap'
    ];

    if (this.roundedSides === 'both') {
      classes.push('tw-rounded-[18px]');
    } else if (this.roundedSides === 'left') {
      classes.push('tw-rounded-l-[18px]');
    } else if (this.roundedSides === 'right') {
      classes.push('tw-rounded-r-[18px]');
    }

    if (this.outline) {
      classes.push('tw-border-solid');
      classes.push('tw-border-[1px]');
      classes.push('tw-border-' + this.textColor);
    }

    classes.push('tw-text-' + (this.disabled ? this.disabledTextColor : this.textColor));
    classes.push('tw-bg-' + (this.disabled ? this.disabledBackgroundColor : this.backgroundColor));

    return classes.join(' ');
  }

  colorClass(color: string): string {
    const colorMap: Record<string, string> = {
      'blue-1': 'blue-500',
      'blue-2': 'blue-550',
      'green-1': 'green-500',
      'pink-1': 'pink-500'
    };
    return colorMap[color];
  }

  disabledColorClass(color: string): string {
    const colorMap: Record<string, string> = {
      'blue-1': 'blue-100',
      'blue-2': 'blue-100',
      'green-1': 'green-100',
      'pink-1': 'pink-100'
    };
    return colorMap[color];
  }

  get textColor(): string {
    if (this.invert !== this.outline) {
      return this.colorClass(this.color);
    }
    return 'white';
  }

  get backgroundColor() {
    if (this.invert !== this.outline) {
      return 'white';
    }
    return this.colorClass(this.color);
  }

  get disabledTextColor() {
    if (this.invert !== this.outline) {
      return this.disabledColorClass(this.color);
    }
    return 'white';
  }

  get disabledBackgroundColor() {
    if (this.invert !== this.outline) {
      return 'white';
    }
    return this.disabledColorClass(this.color);
  }
}
