import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class UserInfoGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  // this guard makes sure taht the user info is loaded into the store
  // before any other guard rely on it
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.getUserInfo().pipe(map(() => true));
  }
}
