import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

export type QRCodeExportFormat = 'png' | 'pdf' | 'svg';

@Injectable({
  providedIn: 'root',
})
export class QrCodeService {
  private url = 'main/qrcode';

  constructor(private httpService: HttpService) {}

  getQrCode(code: string, format: QRCodeExportFormat): Observable<Blob> {
    return this.httpService.getRequest(
      this.url,
      true,
      {
        code,
        format,
      },
      true
    );
  }
}
