import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('token') == null) {
      this.router.navigate(['/auth/login'], {
        queryParams: {
          displayMessage: 'error_messages.login_direct_msg',
          redirectLink: state.url,
        },
      });
      return false;
    }
    return true;
  }
}
