<button
  *ngIf="type == 'icon'"
  class="share-icon tw-p-0 tw-m-0"
  (click)="share($event)"
  [disabled]="disabled"
>
  <ion-icon class="p-3" [style]="iconColor ? 'color:' + iconColor : null" name="share-social-outline"></ion-icon>
</button>
<app-button
  *ngIf="type == 'button'"
  label="common.share"
  class="tw-w-full tw-p-0 tw-m-0"
  color="blue-2"
  [outline]="true"
  (click)="share($event)"
  [disabled]="disabled"
>
</app-button>
