<div (click)="openModal()" class="tw-h-full tw-flex">
  <span class="side-menu-label">{{ 'language.' + language | translate }}</span>
  <ion-icon class="arrow-icon"[src]="'./assets/icon/arrow_down.svg'"></ion-icon>
</div>

<app-modal [isOpen]="isModalOpen" (modalDidDismiss)="isModalOpen = false">
  <app-modal-header [title]="'side_menu.change_language' | translate" (closed)="isModalOpen = false">
  </app-modal-header>
  <app-select-list [items]="selectItems" (selectedItem)="selectedItem($event)">
  </app-select-list>
</app-modal>