import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  /**
   * Get Cached Items If already Saved, If not then return results from api.
   * @param fetchFunction: A function to fetch the data
   * @param objectKey: Cache Object key
   * @param objectExpireKey: Cache Object Expire time key
   * @param forceFetch: Force to fetch Items from server
   */
  getCachedItems<T>(
    fetchFunction: () => Observable<T>,
    objectKey: string,
    objectExpireKey: string,
    forceFetch: boolean = false
  ): Observable<T> {
    // TODO: temporarily disabled the cache because of pet species updates
    localStorage.removeItem(objectKey);
    // Check local storage for expire time and remove saved items if expire time is over
    const expireTime = localStorage.getItem(objectExpireKey);
    if (expireTime !== null) {
      if (Number(expireTime) <= Date.now()) {
        localStorage.removeItem(objectKey);
      }
    }

    // Check to see if items are already saved in local storage and return local storage items if possible,
    // If not then return results from api.
    let returnData: Observable<T>;
    const savedItems = localStorage.getItem(objectKey);
    if (savedItems === null || forceFetch) {
      returnData = fetchFunction();
      returnData.subscribe((data) => {
        localStorage.setItem(objectKey, JSON.stringify(data));
        const expiredTime = Date.now() + environment.cacheTime * 24 * 60 * 60 * 1000;
        localStorage.setItem(objectExpireKey, expiredTime.toString());
      });
    } else {
      const items = JSON.parse(savedItems) as T;
      returnData = new Observable<T>((observer) => {
        observer.next(items);
        observer.complete();
      });
    }
    return returnData;
  }
}
