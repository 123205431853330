import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AppEventType, EventQueueService, InfoIntroService } from '../services';
import { AppState } from '@app/store/app.state';
import { map } from 'rxjs/operators';
import { UserModel } from '@app/models';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard implements CanActivate {
  constructor(
    private eventQueue: EventQueueService,
    private infoIntroService: InfoIntroService,
    private router: Router,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (route.params.key) {
      void this.infoIntroService.showIntro(route.params.key as string, true);
    }
    const queryParams = route.queryParams;
    if (queryParams.type === 'incoming_call') {
      this.eventQueue.dispatch(AppEventType.IncomingCall, {
        user_id: queryParams.userId as string,
        room_id: queryParams.roomId as string,
      });
    }

    return this.store.selectOnce(AppState.user).pipe(
      map((user: UserModel) => {
        if (user && user.role === 'admin') {
          this.router.navigate(['/admin/dashboard']);
          return false;
        }
        return true;
      })
    );
  }
}
