import { Injectable } from '@angular/core';
import { PetOwnerListingModel, ServerVersion, UserModel } from '@app/models';
import { QueryCommon } from '@app/models/app/query-common';
import { BaseModel } from '@app/models/base.model';
import { InvestorsRQ } from '@app/models/investors/investors-rq';
import { PaginationModel } from '@app/models/pagination.model';
import {
  ContactFormRequest,
  SaveUserRequest,
  SaveUserSettingsRQ,
  SendInviteRequest,
  UserGetTransactionsRequest,
  UserRegisterRequest,
} from '@app/models/requests';
import { LoginModel } from '@app/models/user/login.model';

import { TransactionModel } from '@app/models/user/transaction.model';
import { UserInfoModel } from '@app/models/user/user.info.model';
import { UserListingModel } from '@app/models/user/user.listing.model';
import { UserShortInfoListingModel } from '@app/models/user/user.short.info.listing.model';
import { AppState } from '@app/store/app.state';

import { AppEventType, EventQueueService, HttpService } from '@app/services';
import { LogoutUserAction, UpdateUserAction, UpdateUserInfoAction } from '@app/store/app.action';
import { Store } from '@ngxs/store';
import { Observable, of, ReplaySubject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

@Injectable()
export class UserService {
  private url = 'user';
  private deviceId: string;

  constructor(
    private apiService: HttpService,
    private eventQueue: EventQueueService,
    private store: Store
  ) {
    this.deviceId = localStorage.getSettingValue('device_id') as string;
  }

  public isLoggedIn(): boolean {
    return localStorage.getItem('token') != null;
  }

  /**
   * Set Device Id
   * @param deviceId: Device Id
   */
  public setDeviceId(deviceId: string): void {
    this.deviceId = deviceId;
    localStorage.saveSettingValue('device_id', deviceId);
  }

  /**
   * Get Server Version
   */
  public getServerVersion(): Observable<ServerVersion> {
    return this.apiService.getRequest(`version`, true, {});
  }
  /**
   * Get Device Id
   */
  public getDeviceId(): string {
    return this.deviceId;
  }

  /**
   * Get user info from api
   */
  public getUserInfo(): Observable<UserInfoModel|null> {
    if (!this.isLoggedIn()) {
      return of<null>(null);
    }

    const observable = this.apiService
      .getRequest<BaseModel<UserInfoModel>>('main', true)
      .pipe(
        map((data) => new UserInfoModel().deserialize(data.data)),
        tap((data) => {
          this.store.dispatch(new UpdateUserInfoAction(data));
        })
      );
    observable.subscribe();
    return observable;
  }

  /**
   * Request Investor Information
   */
  public requestInvestorInformation(params: InvestorsRQ): Observable<void> {
    return this.apiService.postRequest(`contact/investor`, params, true);
  }

  /**
   * Register Device
   */

  public registerDevice(): Promise<boolean | void> {
    return new Promise<boolean | void>((resolve) => {
      if (!this.deviceId || !localStorage.token) {
        resolve();
        return;
      }
      this.apiService.postRequest('devices/register', { token: this.deviceId, type: 'web' }, true).subscribe(
        () => {
          resolve();
        },
        () => {
          resolve();
        }
      );
    });
  }

  public saveBankAccount(bankAccountName: string, bankAccountNumber: string): Observable<unknown> {
    const params = {
      bank_account_number: bankAccountNumber,
      bank_account_name: bankAccountName,
    };
    const user = this.store.selectSnapshot(AppState.user);
    return this.apiService.putRequest(`${this.url}/${user.id}`, params, true);
  }

  public login(email: string): Observable<void> {
    return this.apiService.postRequest('auth/login', { email }, false);
  }

  public otpLogin(email: string, otp: string): Observable<UserInfoModel> {
    const postData = {
      email,
      otp,
    };
    const subject = new ReplaySubject<UserInfoModel>();
    this.apiService.postRequest('auth/otp-login', postData, false).subscribe(
      (loginModel: LoginModel) => {
        localStorage.setItem('token', loginModel.data.token);
        localStorage.clearAllItems();
        localStorage.setItem('token', loginModel.data.token);
        this.getUserInfo().subscribe((user) => {
          this.eventQueue.dispatch(AppEventType.SignIn, user);
          subject.next(user);
          subject.complete();
        });
      },
      (error) => {
        subject.error(error);
      }
    );
    return subject;
  }

  public register(postData: UserRegisterRequest, image: Blob, socialLogin: boolean): Observable<UserInfoModel> {
    // Clear local storage except specific fields
    const onboardingPets = localStorage.getItem('onboardingPets');
    const currentToken = localStorage.token as string;
    const redirectLink = localStorage.redirectLink as string;
    localStorage.clearAllItems();
    localStorage.setItem('onboardingPets', onboardingPets);
    localStorage.setItem('token', currentToken);
    localStorage.setItem('redirectLink', redirectLink);

    return this.apiService.multiPartFormDataRequest('POST', 'auth/register', postData, true, 'image', image);
  }

  removeDevice(): Observable<void> {
    this.eventQueue.dispatch(AppEventType.SignOut, null);
    return this.apiService.deleteRequest(`devices/${this.deviceId}`, {}, true);
  }

  public logout(): Observable<void> {
    return this.apiService.postRequest<void>('auth/logout', {}, true).pipe(
      finalize(() => {
        // Clear User
        this.store.dispatch(new LogoutUserAction());
        localStorage.clearAllItems();
      })
    );
  }

  getList(queryParams: QueryCommon): Observable<PaginationModel<UserModel>> {
    return this.apiService.getRequest<PaginationModel<UserModel>>(this.url, true, queryParams);
  }

  /**
   * Get User short info
   * @param id: User Id
   */
  getUserFrom(id: string): Observable<UserListingModel> {
    return this.apiService.getRequest<UserListingModel>(`${this.url}/${id}`, true);
  }

  /**
   * Get User short info
   * @param id: User Id
   */
  getUserShortInfo(id: string): Observable<UserShortInfoListingModel> {
    return this.apiService.getRequest<UserShortInfoListingModel>(`${this.url}/${id}/shortinfo`, true);
  }

  /**
   * Get Pet Owner From User Id
   * @param id: User Id
   */
  getPetOwner(id: string): Observable<PetOwnerListingModel> {
    return this.apiService.getRequest(`${this.url}/${id}/pet_owner/details`, true);
  }

  /**
   * Delete User
   * @param id: User Id
   */
  deleteUser(id: string): Observable<void> {
    return this.apiService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  /**
   * Delete User From User Side
   */
  hardDeleteUser(): Observable<void> {
    return this.apiService.deleteRequest(`${this.url}/me/delete-account`, {}, true);
  }

  /**
   * Save User
   * @param id: User Id
   * @param params: User Data
   */
  saveUser(id: string, params: SaveUserRequest | UserModel | SaveUserSettingsRQ): Observable<UserListingModel> {
    if (id) {
      return this.apiService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      return this.apiService.postRequest(`${this.url}`, params, true);
    }
  }

  /**
   * Send Invite
   * @param expertId: string
   * @param params: Parameters
   */
  sendInvite(expertId: string, params: SendInviteRequest): Observable<void> {
    return this.apiService.postRequest(`${this.url}/expert/${expertId}/invite`, params, true);
  }

  /**
   * Submit Contact Form
   * @param params: Params
   */
  submitContactForm(params: ContactFormRequest): Observable<void> {
    return this.apiService.postRequest(`main/contact`, params, true);
  }

  /**
   * Get User Transaction List
   * @param params: Params
   */
  getTransactions(params: Partial<UserGetTransactionsRequest> = {}): Observable<PaginationModel<TransactionModel>> {
    return this.apiService.getRequest(`${this.url}/me/transactions`, true, params);
  }

  public loadUserIntoStore(): Observable<UserModel> {
    if (!this.isLoggedIn()) {
      this.store.dispatch(new UpdateUserInfoAction(null));
      return of<UserModel>(null);
    }

    return this.getUserInfo().pipe(
      map((result) => {
        return result.user;
      }),
      tap((result) => {
        this.store.dispatch(new UpdateUserAction(result));
      })
    );
  }
}
