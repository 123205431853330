<div [ngClass]="class" class="image-container-view">
  <app-aspect-ratio [ratio]="{w:1, h:1}">
    <app-image-shell
      animation="gradient"
      class="image-view"
      [type]="type"
      [src]="imageURL"
      [imageText]="imageText"
    ></app-image-shell>
  </app-aspect-ratio>
</div>
