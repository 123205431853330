import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AppState } from '@app/store/app.state';
import { concatAll, map } from 'rxjs/operators';
import { ExpertModel, UserModel } from '@app/models';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExpertOnboardingGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.store.selectOnce(AppState.user).pipe(
      map((user: UserModel) => {
        if (!user) return of(true);
        if (user.role == 'expert') {
          return this.store.selectOnce(AppState.experts).pipe(
            map((experts: ExpertModel[]) => {
              if (!experts || experts.length == 0) {
                this.router.navigateByUrl('/onboarding/pet-expert/find-profile');
                return false;
              } else if (!experts[0].reviewed_by_user) {
                this.router.navigateByUrl('/onboarding/pet-expert/profile');
                return false;
              } else {
                return true;
              }
            })
          );
        } else {
          return of(true);
        }
      }),
      concatAll()
    );
  }
}
