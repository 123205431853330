import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { AuthModuleGuard, ExpertOnboardingGuard, LocaleGuard, RoleGuard } from '@app/guards';
import { RegistrationCompletedGuard } from '@app/guards';
import { CustomPreloadingStrategy } from '@app/custom.preloading.strategy';
import { UserInfoResolver } from '@app/user.info.resolver';
import { UserInfoGuard } from '@app/guards/user.info.guard';
import { environment } from '@environments/environment';
import { AppComponent } from '@app/app.component';

const routes: Routes = getRoutes();

function getRoutes() {
  if (typeof window !== 'undefined' && window?.location.hostname.endsWith('.' + environment.micrositeHost)) {
    return [
      {
        path: '',
        loadChildren: () => import('./pages/microsite/microsite.module').then((m) => m.MicrositeModule),
      }
    ];
  }

  return [
    {
      canActivate: [LocaleGuard, UserInfoGuard],
      matcher: (url) => {
        if (url.length > 0 && url[0].path.match(/^[a-zA-Z]{2}(-[a-zA-Z]{2})?$/)) {
          return {
            consumed: [url[0]],
            posParams: {
              locale: new UrlSegment(url[0].path as string, {}),
            },
          };
        } else {
          return {
            consumed: [],
          };
        }
      },
      children: [
        {
          path: 'auth',
          loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
          canActivate: [AuthModuleGuard],
          data: { preload: 'nologin' },
        },
        {
          path: 'admin',
          loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule),
          canActivate: [RoleGuard],
          data: { preload: 'admin', roles: ['admin'] },
        },
        {
          path: 'pet-owner',
          loadChildren: () => import('./pages/pet-owner/pet-owner.module').then((m) => m.PetOwnerModule),
          canActivate: [RoleGuard, RegistrationCompletedGuard],
          data: { preload: 'petowner', roles: ['petowner'] },
        },
        {
          path: '',
          loadChildren: () => import('./pages/common/common.module').then((m) => m.CommonModule),
          canActivate: [ExpertOnboardingGuard, RegistrationCompletedGuard],
          data: { preload: 'all' },
          resolve: {
            userInfo: UserInfoResolver,
          },
        },
        {
          path: 'expert',
          loadChildren: () => import('./pages/expert/expert.module').then((m) => m.ExpertModule),
          canActivate: [ExpertOnboardingGuard, RoleGuard, RegistrationCompletedGuard],
          data: { preload: 'expert', roles: ['expert'] },
        },
        {
          path: 'intro',
          loadChildren: () => import('./pages/intro/intro.module').then((m) => m.IntroModule),
          data: { preload: 'nologin' },
        },
        {
          path: 'microsite',
          loadChildren: () => import('./pages/microsite/microsite.module').then((m) => m.MicrositeModule),
        },
        {
          path: 'onboarding',
          loadChildren: () => import('./pages/onboarding/onboarding.module').then((m) => m.OnboardingModule),
          data: { preload: 'nologin' },
        },
        {
          path: 'social',
          children: environment.socialMedia?.map(link => ({
            path: link.type,
            component: AppComponent,
            canActivate: [() => {
              if (typeof window !== undefined) {
                window.location.href = link.url;
              }
            }],
          })),
        },
        {
          path: '**',
          redirectTo: 'page-not-found',
        },
      ],
    },
  ];
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategy,
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabledBlocking', // TODO: this is required for serverside, can we use it only for 'server'?
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
