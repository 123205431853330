import {
  Component, ComponentRef,
  createNgModule,
  Injector,
  NgModuleRef, ViewChild,
  ViewContainerRef
} from '@angular/core';
import { AppEventType, EventQueueService } from '@app/services';
import { CallViewModel } from '@app/models/call/call.view.model';
import { type AppCallComponent } from '@app/shared/components/call';

@Component({
  selector: 'app-call-wrapper',
  templateUrl: './app-call-wrapper.component.html',
  styleUrls: ['./app-call-wrapper.component.scss'],
})
export class AppCallWrapperComponent {
  @ViewChild('rootView', { read: ViewContainerRef }) rootView: ViewContainerRef;
  componentRef: ComponentRef<AppCallComponent>;

  constructor(
    private eventQueue: EventQueueService,
    private viewContainerRef: ViewContainerRef,
    private injector: Injector
  ) {
    this.eventQueue.subscribe<CallViewModel>(AppEventType.StartCall, (data) => {
      this.startCall(data);
    });
  }

  async startCall(data: CallViewModel): Promise<void> {
    await this.loadComponent();
    this.toggleCallPopup(true);
    this.componentRef.instance.startCall(data);
  }

  closePopup() {
    this.toggleCallPopup(false);
  }

  async loadComponent(): Promise<void> {
    if (!this.componentRef) {
      type CallComponentsModule = import('@app/shared/components/call').CallComponentsModule;

      return import(/* webpackChunkName: 'call-components' */ '@app/shared/components/call').then(
        ({ CallComponentsModule, AppCallComponent }) => {
          const ngModuleRef: NgModuleRef<CallComponentsModule> = createNgModule(
            CallComponentsModule,
            /* parentInjector */ this.injector,
          );

          this.componentRef = this.viewContainerRef.createComponent(AppCallComponent, { ngModuleRef });
          this.componentRef.instance.closePopup.subscribe(() => {
            this.closePopup();
          });
          this.rootView.insert(this.componentRef.hostView);
        },
      );
    }
  }

  toggleCallPopup(visible: boolean): void {
    const incomingPopup: HTMLElement = document.querySelector('app-call-wrapper');
    incomingPopup.style.display = visible ? 'unset' : 'none';
    this.componentRef.instance.isViewActive = visible;
  }
}