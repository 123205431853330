import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
  
  @Input() title: string;
  @Input() showBackArrow: boolean;
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() backButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  
  doClose() {
    this.closed.emit();
  }
  
  back() {
    this.backButtonClicked?.emit();
  }
}
