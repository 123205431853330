import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/index';
import { Observable } from 'rxjs';
import { BaseModel } from '@app/models/base.model';
import { MediaModel } from '@app/models/media/media.model';
import { v4 } from 'uuid';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private url = 'media';

  constructor(private httpService: HttpService) {}

  /**
   * Upload File
   * @param file
   * @param path
   */
  uploadTempFile(file: Blob | File): Observable<{ data: { name: string } }> {
    return this.httpService.multiPartFormDataRequest('POST', `file/upload`, {}, true, 'file', file);
  }

  /**
   * Get directory listing
   */
  getList(path: string): Observable<BaseModel<MediaModel[]>> {
    return this.httpService.getRequest(this.url, true, { path });
  }

  /**
   * Create Directory
   * @param path
   */
  createDirectory(path: string): Observable<void> {
    return this.httpService.postRequest(`${this.url}`, { path }, true);
  }

  /**
   * Upload File
   * @param file
   * @param path
   */
  uploadFile(file: File, path: string): Observable<string> {
    return this.httpService.multiPartFormDataRequest<BaseModel<{ url: string }>>('POST', `${this.url}/upload`,
      { path }, true, 'file', file).pipe(
      map((data) => data.data.url)
    );
  }

  uploadMicrositeFile(expert_id: string, file: File): Observable<string> {
    const path = 'microsite';
    const fileName = (v4() as string) + '.' + file.name.split('.').pop();
    return this.httpService.multiPartFormDataRequest<BaseModel<{ url: string }>>('POST', `${this.url}/upload`,
      { expert_id, path: path + '/' + fileName }, true, 'file', file).pipe(
      map((data) => data.data.url)
    );
  }

  /**
   * Delete resource
   * @param path
   */
  deleteResource(path: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}`, { path }, true);
  }
}
