import { Injectable } from '@angular/core';
import { PaginationModel, UserExpertModel } from '@app/models';
import { BaseModel } from '@app/models/base.model';
import { UserExpertListingModel } from '@app/models/expert/user.expert.listing.model';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';
import { QueryCommon } from '@app/models/app/query-common';

@Injectable({
  providedIn: 'root',
})
export class UserExpertService {
  private url = 'user_expert';

  constructor(private httpService: HttpService) {}

  /**
   * Create User Expert
   * @param userExpert: User Expert
   */
  createUserExpert(userExpert: UserExpertModel): Observable<BaseModel<UserExpertListingModel>> {
    return this.httpService.postRequest(`${this.url}`, userExpert, true);
  }

  getUserExpertList(expertId: string, queryParams?: QueryCommon): Observable<PaginationModel<UserExpertModel>> {
    return this.httpService.getRequest(`${this.url}/${expertId}`, true, queryParams);
  }

  /**
   * Update User Expert
   * @param expertId: Expert Id
   * @param userExpert User Expert
   */
  updateUserExpert(expertId: string, userExpert: UserExpertModel): Observable<BaseModel<UserExpertListingModel>> {
    return this.httpService.putRequest(`${this.url}/${expertId}`, userExpert, true);
  }

  /**
   * Delete User Expert
   * @param expertId: Expert Id
   */
  deleteUserExpert(expertId: string): Observable<BaseModel<UserExpertListingModel>> {
    return this.httpService.deleteRequest(`${this.url}/${expertId}`, {}, true);
  }
}
