import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppState } from '@app/store/app.state';
import { map } from 'rxjs/operators';
import { UserModel } from '@app/models';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const roles = route.data.roles as Array<string>;
    return this.store.selectOnce(AppState.user).pipe(
      map((user: UserModel) => {
        if (!user) {
          this.router.navigate(['/auth/login'], {
            queryParams: {
              displayMessage: 'error_messages.login_direct_msg',
              redirectLink: state.url,
            },
          });
          return false;
        }

        if (roles.indexOf(user.role) < 0) {
          this.router.navigate(['/']);
          return false;
        }

        return true;
      })
    );
  }
}
