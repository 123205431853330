import { Injectable } from '@angular/core';
import { PaginationModel } from '@app/models';
import { VatRateModel } from '@app/models/vat-rate/vat-rate.model';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class VatRateService {
  private url = 'vat_rate';

  constructor(private httpService: HttpService) {}

  getList(): Observable<PaginationModel<VatRateModel>> {
    return this.httpService.getRequest<PaginationModel<VatRateModel>>(`${this.url}`, true, {});
  }
}
