import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '@app/services';
import { UserInfoModel } from '@app/models/user/user.info.model';

@Injectable({ providedIn: 'root' })
export class UserInfoResolver implements Resolve<UserInfoModel> {
  constructor(private userService: UserService) {}

  resolve(): Observable<UserInfoModel> | Promise<UserInfoModel> | UserInfoModel {
    if (!this.userService.isLoggedIn()) return of(null);
    return this.userService.getUserInfo();
  }
}
