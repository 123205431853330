<ion-spinner class="spinner"></ion-spinner>
  <img
    *ngIf="fitImage == 'cover'"
    [class]="imageClass"
    [ngClass]="['inner-img', fitImage]"
    #image
    loading="lazy"
    [src]="_internalSrc"
    [alt]="_alt"
    (error)="imageError()"
    (load)="loaded()"
  />

  <div *ngIf="fitImage == 'contain'" class="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center">
    <img
      [class]="imageClass"
      [ngClass]="['inner-img', fitImage]"
      #image
      loading="lazy"
      [src]="_internalSrc"
      [alt]="_alt"
      (error)="imageError()"
      (load)="loaded()"
    />
  </div>

<div class="text-img-view" *ngIf="_loadingFailed && _placeholderFailed && imageText">
  <span class="text-label" [style]="'color:' + getTextColor()">{{ imageText.substr(0, 1).toUpperCase() }}</span>
</div>