import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-text-shell',
  templateUrl: './text-shell.component.html',
  styleUrls: ['./text-shell.component.scss'],
})
export class TextShellComponent {
  _data = '';
  _defaultValue: string;
  _showAnimation: boolean;

  @HostBinding('class.text-loaded') textLoaded = false;

  @Input() type = 'text';
  @Input() set data(val: string) {
    this._data = val !== undefined && val !== null ? val : '';

    this.textLoaded = this._data && this._data !== '' && this._data !== this._defaultValue;
  }

  @Input() set showAnimation(val: boolean) {
    this._showAnimation = val;
    this.textLoaded = !val;
  }

  @Input() set defaultValue(val: string) {
    this._defaultValue = val;
    if (this._defaultValue && !this._data) {
      this.textLoaded = true;
    } else if (this._defaultValue === this._data) {
      this.textLoaded = false;
    }
  }
}
