import { Deserializable } from '@app/models/deserializable.model';
import { MissedPetModel } from '@app/models/missed-pet/missed-pet.model';
import { PetBreedModel } from '@app/models/pet-breed/pet-breed.model';
import { PetSpeciesModel } from '@app/models/pet-species/pet-species.model';
import { PetImageModel } from '@app/models/pet/pet.image.model';
import { UserModel } from '@app/models/user/user.model';
import { GpsTrackerModel } from '@app/models/pet/gps.tracker.model';

export class PetModel implements Deserializable {
  id: string;
  user_id: string;
  name: string;
  image: string;
  image_url: string;
  birthdate: Date;
  amicus: string;
  weight: number;
  sterilized: boolean;
  castrated: boolean;
  status: string;
  missed_since: Date;
  dead_since: Date;
  missed_pet_id: string;
  pet_species_id: string;
  gender: string;

  user: UserModel;
  pet_breeds: PetBreedModel[];
  pet_species: PetSpeciesModel;
  missed_pet: MissedPetModel;
  gps_tracker: GpsTrackerModel;

  images: PetImageModel[];

  deserialize(input: unknown): this {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Object.assign(this, input);
  }

  get profilePicture(): string {
    return this.images.length > 0 ? this.images[0].image_url : '';
  }
}
