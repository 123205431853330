import { Injectable } from '@angular/core';
import { PaginationModel, PetSpeciesListingModel, PetSpeciesModel } from '@app/models/';
import { SavePetSpeciesRequest } from '@app/models/requests';
import { CacheService, HttpService } from '@app/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class PetSpeciesService {
  private url = 'pet_species';
  private SPECIES_KEY = 'totalSpecies';
  private SPECIES_EXPIRE_KEY = 'species_expire_time';
  constructor(private httpService: HttpService, private cacheService: CacheService) {}

  getAll(): Observable<PetSpeciesModel[]> {
    const lang = this.httpService.lang;
    return this.cacheService.getCachedItems<PetSpeciesModel[]>(
      () => {
        return this.getList({ page_length: 1000 }).pipe(
          map((result: PaginationModel<PetSpeciesModel>) => {
            return result.data.data;
          })
        );
      },
      `${this.SPECIES_KEY}_${lang}`,
      `${this.SPECIES_EXPIRE_KEY}_${lang}`,
      true
    );
  }

  getList(queryParams: Record<string, unknown> = {}): Observable<PaginationModel<PetSpeciesModel>> {
    return this.httpService.getRequest<PaginationModel<PetSpeciesModel>>(`${this.url}`, false, queryParams);
  }

  getPetSpecies(id: string): Observable<PetSpeciesListingModel> {
    return this.httpService.getRequest(`${this.url}/${id}`, true);
  }

  deletePetSpecies(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  savePetSpecies(id: string, params: SavePetSpeciesRequest, image: Blob): Observable<void> {
    if (id) {
      return this.httpService.multiPartFormDataRequest('PUT', `${this.url}/${id}`, params, true, 'image', image);
    } else {
      return this.httpService.multiPartFormDataRequest('POST', `${this.url}`, params, true, 'image', image);
    }
  }
}
