import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AppEventType, AppService, EventQueueService, HttpService } from '@app/services';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { VersionReadyEvent } from '@angular/service-worker';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  private static reloading: boolean;
  private updates: SwUpdate;
  private updateDelay = 5;

  constructor(
    private appService: AppService,
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: Platform,
    private translate: TranslateService,
    private http: HttpService,
    private eventQueue: EventQueueService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.updates = this.injector.get(SwUpdate);
      if (this.updates.isEnabled) {
        this.updates.checkForUpdate().then(() => {
          // checked update
        });
        interval(5 * 60 * 1000).subscribe(() =>
          this.updates
            .checkForUpdate()
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .then(() => {})
        );
      }
    }

    this.eventQueue.subscribe(AppEventType.UpdateAvailable, (force: boolean) => this.reload(force));
  }

  public checkForUpdates(): void {
    if (isPlatformBrowser(this.platformId)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const updatesAvailable = this.updates.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          current: evt.currentVersion,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          available: evt.latestVersion,
        }))
      );

      updatesAvailable.subscribe(() => this.reload());
    }
  }

  private reload(force = false): void {
    return; // TODO: find a solution for terraform ane re-enable

    if (UpdateService.reloading) {
      return;
    }
    if (!force) {
      this.http.checkVersion();
      return;
    }
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.appService.showSpinner('pwa.new_version_available', { number: this.updateDelay });
    UpdateService.reloading = true;
    this.updates.activateUpdate().then(() => setTimeout(() => document.location.reload(), this.updateDelay * 1000));
  }
}
