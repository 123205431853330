import { Injectable } from '@angular/core';
import { BaseListingModel } from '@app/models/base.listing.model';
import { CurrencyModel } from '@app/models/currency/currency.model';
import { CacheService, HttpService } from '@app/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private CURRENCY_KEY = 'totalCurrencyList';
  private CURRENCY_EXPIRE_KEY = 'currency_expire_time';
  constructor(private httpService: HttpService, private cacheService: CacheService) {}

  getCurrencyList(): Observable<BaseListingModel<CurrencyModel>> {
    const lang = this.httpService.lang;
    return this.cacheService.getCachedItems<BaseListingModel<CurrencyModel>>(
      () => {
        return this.httpService.getRequest<BaseListingModel<CurrencyModel>>('main/currencies');
      },
      `${this.CURRENCY_KEY}_${lang}`,
      `${this.CURRENCY_EXPIRE_KEY}_${lang}`
    );
  }
}
