import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppService } from '@app/services/app.service';
import { Store } from '@ngxs/store';
import { AppState } from '@app/store/app.state';

@Injectable({
  providedIn: 'root'
})
export class MauticService {
  constructor(
    private appService: AppService,
    private router: Router,
    private store: Store,
  ) {
    if (this.appService.isBrowser() && environment.mauticBaseUrl) {
      this.addMauticScript();

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.sendPageView();
        }
      });
    }
  }

  addMauticScript() {
    /* eslint-disable */
    (function(w,d,t,u,n,a,m){w['MauticTrackingObject']=n;
      w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},a=d.createElement(t),
        m=d.getElementsByTagName(t)[0];a.async=1;a.src=u;m.parentNode.insertBefore(a,m)
    })(window,document,'script', environment.mauticBaseUrl + '/mtc.js','mt');
    /* eslint-enable */
  }

  sendPageView() {
    window['mt']('send', 'pageview', {
      'preferred_locale': this.store.selectSnapshot(AppState.locale),
    });
  }
}