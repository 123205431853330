import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FeatureService } from '@app/services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard implements CanLoad {
  constructor(private router: Router, private featureService: FeatureService) {}

  canLoad(route: Route): Observable<boolean | UrlTree> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const feature: string = (route?.data as any)?.feature as string;

    if (!feature) {
      return of(true);
    }

    return this.featureService.isActive(feature).pipe(
      map((active) => {
        if (active) {
          return true;
        } else {
          return this.router.createUrlTree(['/']);
        }
      })
    );
  }
}
