import { AddressModel } from '@app/models/address/address.model';
import { MapPinModel } from '@app/models/map/map.pin.model';
import { UserModel } from '@app/models/user/user.model';

export class PetOwnerModel {
  id: string;
  username: string;
  phone: string;
  mobile: string;
  description: string;
  user_id: string;
  pin_id: string;
  pin: MapPinModel;
  lat: number;
  lng: number;
  address_id: string;
  address: AddressModel;
  image_url: string;
  image: string;
  user: UserModel = new UserModel();
  connected_since: string;
  isChecked: boolean;
  allow_friend_requests: boolean;
  is_visible: boolean;
  checked: boolean;
}
