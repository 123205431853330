import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppEventType, AppService, EventQueueService, UserService } from '@app/services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { UserInfoModel } from '@app/models/user/user.info.model';

@Injectable({
  providedIn: 'root',
})
export class OneSignalService {
  oneSignalId: string;
  constructor(
    private appService: AppService,
    private eventQueue: EventQueueService,
    private router: Router,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  public async init(): Promise<void> {
    if (!environment.oneSignalAppId || environment.environment == 'e2e') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    if (typeof window !== 'undefined' && window?.location.hostname.endsWith('.' + environment.micrositeHost)) {
      return;
    }
    // eslint-disable-next-line
    const OneSignal = window['OneSignal'];
    if (OneSignal) {
      return;
    }

    const settings = await this.oneSignalSettings()
    // eslint-disable-next-line
    window['OneSignalDeferred'] = window['OneSignalDeferred'] || [];
    // eslint-disable-next-line
    window['OneSignalDeferred'].push(function(OneSignal) {
      OneSignal.init(settings);
    });

    this.addScript('https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js', () => {
      const interval = setInterval(() => {
        if (!window['OneSignal']) {
          return;
        }
        clearInterval(interval);

        this.oneSignalInstance.Notifications.addEventListener('permissionChange', (isSubscribed) => {
          if (isSubscribed) {
            this.updateDeviceId();
          }
        });

        void this.initOneSignal();

        this.eventQueue.subscribe(AppEventType.SignIn, (userInfo: UserInfoModel) => {
          this.oneSignalInstance.login(userInfo.user.id);
        });
        this.eventQueue.subscribe(AppEventType.SignOut, () => {
          this.oneSignalInstance.logout();
        });
      }, 1000);
    });
  }

  addScript(fileSrc: string, callback: () => void): void {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.onload = callback;
    script.src = fileSrc;
    head.appendChild(script);
  }

  protected async oneSignalSettings() {
    const actionMessage = (await this.translateService
      .get('onesignal.confirmation_text')
      .toPromise()) as Promise<string>;
    const acceptButtonText = (await this.translateService.get('onesignal.ok_text').toPromise()) as Promise<string>;
    const cancelButtonText = (await this.translateService
      .get('onesignal.no_thanks_text')
      .toPromise()) as Promise<string>;

    return {
      appId: environment.oneSignalAppId,
      allowLocalhostAsSecureOrigin: true,
      promptOptions: {
        slidedown: {
          prompts: [
            {
              type: 'push', // current types are "push" & "category"
              autoPrompt: false,
              text: {
                /* limited to 90 characters */
                actionMessage,
                /* acceptButton limited to 15 characters */
                acceptButton: acceptButtonText,
                /* cancelButton limited to 15 characters */
                cancelButton: cancelButtonText,
              },
            },
          ],
        },
      },
    };
  }

  private async initOneSignal(): Promise<void> {
    if (this.userService.isLoggedIn()) {
      setTimeout(() => {
        this.checkIfSubscribed();
      }, 1000);
    }
  }

  updateDeviceId(): void {
    let retries = 0;
    const interval = setInterval(() => {
      if (!this.oneSignalInstance.User?.PushSubscription?.id) {
        retries++;
        if (retries > 10) {
          clearInterval(interval);
        }
        return;
      }
      clearInterval(interval);

      const userId = this.oneSignalInstance.User.PushSubscription.id as string;
      if (userId) {
        this.oneSignalId = userId;
        this.userService.setDeviceId(userId);
        void this.userService.registerDevice();
      }
    });
  }

  get oneSignalInstance(): any {
    // eslint-disable-next-line
    return window['OneSignal'];
  }

  checkIfSubscribed(): void {
    const isEnabled = this.oneSignalInstance.Notifications.permission as boolean;
    if (isEnabled) {
      this.updateDeviceId();
    } else {
      this.oneSignalInstance.Slidedown.promptPush();
    }
  }
}
