<ion-app>
  <app-side-menu>
    <ion-router-outlet id="main-content">
      <app-pwa-popup *ngIf="showAddToHomeScreenPopup" (hidePopup)="showAddToHomeScreenPopup = false"></app-pwa-popup>
      <app-info-intro class="app-popup-view"></app-info-intro>
      <app-incoming-call-popup class="app-popup-view"></app-incoming-call-popup>
      <app-service-unavailable class="app-popup-view"></app-service-unavailable>
      <app-call-wrapper style="display: none"></app-call-wrapper>
      <app-audio></app-audio>
      <app-spinner></app-spinner>
      <ng-template appOverlayPopup></ng-template>
    </ion-router-outlet>
    <ng-container *ngIf="toast$ | async; let toast">
      <ngb-toast *ngIf="toast.option" [ngClass]="toast.option.classname" [autohide]="true">
        {{ toast.message | translate }}
      </ngb-toast>
    </ng-container>
  </app-side-menu>
</ion-app>
