import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { InfoModel } from '@app/models/info/info.model';
import { InfoService } from '@app/services';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InfoIntroService {
  // Observable string sources
  private introSubjectSource = new Subject<[string, string, InfoModel[]]>();

  // Observable string streams
  introSubjectSource$ = this.introSubjectSource.asObservable();

  constructor(private infoService: InfoService, @Inject(PLATFORM_ID) private platformId: Platform) {}
  async showIntro(key: string, force: boolean = false): Promise<void> {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const keyName = `intro_info_${key}_shown`;
    if (localStorage.getSettingValue(keyName) && !force) {
      return;
    }
    const params = {
      key,
    };
    const infoData = await this.infoService.getList(params).toPromise();
    if (!infoData?.data?.data || infoData?.data?.data?.length < 2) {
      return;
    }
    let infoList = infoData.data.data;
    localStorage.saveSettingValue(keyName, true);
    infoList = infoList.sort((el1, el2) => {
      return el1.sort > el2.sort ? 1 : -1;
    });
    const title = infoList[0].title;
    infoList.splice(0, 1);
    const appIntroMenu: HTMLElement = document.querySelector('app-info-intro');
    appIntroMenu.style.display = 'unset';
    this.introSubjectSource.next([key, title, []]);
    setTimeout(() => {
      this.introSubjectSource.next([key, title, infoList]);
    }, 10);
  }
}
