import { PetSpeciesTextModel } from '@app/models/pet-species/pet-species.text.model';

export class PetSpeciesModel {
  id: string;
  name: string;
  name_plural: string;
  image: string;
  image_url: string;
  sort: number;
  parent_species_id: string;

  texts: PetSpeciesTextModel[] = [];
}
