import { Component, EventEmitter, Input, Output } from '@angular/core';

export type PopupOptionsType = {
  titleTextKey: string;
  htmlTextKey: string;
  buttons: { key: string; textKey: string; handler: () => void }[];
};

@Component({
  selector: 'app-overlay-popup',
  templateUrl: './app-overlay-popup.component.html',
  styleUrls: ['./app-overlay-popup.component.scss'],
})
export class AppOverlayPopupComponent {
  @Output() buttonClicked = new EventEmitter<string>();
  @Output() popupClosed = new EventEmitter<void>();

  @Input() options: PopupOptionsType;

  close(): void {
    this.popupClosed.emit();
  }

  click(key: string): void {
    const button = this.options.buttons.find((item) => item.key == key);
    if (button.handler) button.handler();
    this.buttonClicked.emit(key);
    this.popupClosed.emit();
  }
}
