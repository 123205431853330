import { Injectable } from '@angular/core';
import { QueryCommon } from '@app/models/app/query-common';
import { HazardListingModel } from '@app/models/hazard/hazard.listing.model';
import { HazardModel } from '@app/models/hazard/hazard.model';
import { PaginationModel } from '@app/models/pagination.model';
import { SaveHazardRequest } from '@app/models/requests';
import { Observable } from 'rxjs';
import { HttpService, MediaService } from '@app/services';
import { concatAll, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HazardService {
  private url = 'hazard';

  constructor(private httpService: HttpService, private mediaService: MediaService) {}

  getList(params: Partial<QueryCommon>): Observable<PaginationModel<HazardModel>> {
    return this.httpService.getRequest<PaginationModel<HazardModel>>(`${this.url}`, true, params);
  }

  getHazard(id: string): Observable<HazardListingModel> {
    return this.httpService.getRequest<HazardListingModel>(`${this.url}/${id}`);
  }

  saveHazard(id: string, params: SaveHazardRequest, file: Blob): Observable<HazardListingModel> {
    return this.mediaService.uploadTempFile(file).pipe(
      map((response) => {
        const paramsWithFile = {
          ...params,
          image: response.data.name,
        };

        if (id) {
          return this.httpService.putRequest<HazardListingModel>(`${this.url}/${id}`, paramsWithFile);
        } else {
          return this.httpService.postRequest<HazardListingModel>(`${this.url}`, paramsWithFile);
        }
      }),
      concatAll()
    );
  }

  removeHazard(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }
}
