import { Injectable } from '@angular/core';
import { InvoiceModel } from '@app/models/invoice/invoice.model';

@Injectable({
  providedIn: 'root',
})
export class CsvDataService {
  downloadInvoiceAsCSV(invoiceModel: InvoiceModel): void {
    const data: Record<string, string | Date | number>[] = [];
    const fileName = `${invoiceModel.type}_${invoiceModel.id}`;
    invoiceModel.invoice_lines.forEach((line) => {
      data.push({
        description: line.text,
        additionalText: line.additional_text,
        quantity: line.quantity,
        currency: invoiceModel.currency,
        amount: line.amount,
        totalAmount: invoiceModel.amount,
        vatRateText: line.vat_rate?.name,
        vatRateAmount: line.vat_amount,
      });
    });
    this.exportToCsv(fileName, data);
  }

  exportToCsv(filename: string, rows: Record<string, string | Date | number>[]): void {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows
        .map((row) => {
          return keys
            .map((k) => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k];
              cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');
              if (cell.search(/([",\n])/g) >= 0) {
                cell = `"${cell}"`;
              }
              return cell;
            })
            .join(separator);
        })
        .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${filename}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
