import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pwa-popup',
  templateUrl: './app-pwa-popup.component.html',
  styleUrls: ['./app-pwa-popup.component.scss'],
})
export class AppPwaPopupComponent {
  @Output() hidePopup: EventEmitter<void> = new EventEmitter<void>();
  constructor(private router: Router) {}
  closeAddToHomeScreenPopup(): void {
    localStorage.saveSettingValue('is_pwa_popup_shown', true);
    this.hidePopup.emit();
  }

  addToHomeScreenButtonTapped(): void {
    localStorage.saveSettingValue('is_pwa_popup_shown', true);
    this.hidePopup.emit();
    this.router.navigateByUrl('/content/homescreen-ios');
  }
}
