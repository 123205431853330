import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransferState } from '@angular/platform-browser';
import { TranslationService } from '@app/shared/translation';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { TranslationBrowserLoaderService } from './translation-browser-loader.service';
import { environment } from '@environments/environment';

export function translateStaticLoader(http: HttpClient, transferState: TransferState): TranslationBrowserLoaderService {
  return new TranslationBrowserLoaderService(
    '/assets/i18n/',
    `.json?v=${environment.client_version}`,
    transferState,
    http
  );
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateStaticLoader,
        deps: [HttpClient, TransferState],
      },
    }),
  ],
  providers: [TranslationService],
})
export class TranslationBrowserModule {}
