import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  AdminMenus,
  ExpertModel,
  IMenu,
  IntroMenus, MessageModel,
  PetExpertMenus,
  PetOwnerMenus,
  PetOwnerModel,
  UserModel,
} from '@app/models';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { AnimationController, MenuController, NavController } from '@ionic/angular';
import { AppEventType, AppService, EventQueueService, FeatureService, UserService } from '@app/services';
import { Select } from '@ngxs/store';
import { AppState } from '@app/store/app.state';
import slug from 'slug';

@Component({
  selector: 'app-side-menu',
  templateUrl: 'side-menu.html',
  styleUrls: ['side-menu.scss'],
})
export class SideMenuComponent implements OnInit, OnDestroy {
  @Select(AppState.expert) expert$: Observable<ExpertModel>;
  @Select(AppState.user) user$: Observable<UserModel>;
  @Select(AppState.userFullName) userFullName$: Observable<string>;
  @Select(AppState.userImageUrl) userImageUrl$: Observable<string>;
  @Select(AppState.petowner) petOwner$: Observable<PetOwnerModel>;
  @ViewChild('background', { static: false }) background: ElementRef<HTMLElement>;
  @ViewChild('sideMenu', { static: false }) sideMenu: ElementRef<HTMLElement>;
  @ViewChild('menu') menu: ElementRef;
  isOpen = false;
  menuItems: IMenu[] = [];
  expertModel: ExpertModel;
  petOwnerModel: PetOwnerModel;
  selectedMenuIndex: number;
  selectedPath = '';
  userModel: UserModel;
  subscriptions = new Subscription();

  constructor(
    private animationCtrl: AnimationController,
    private appService: AppService,
    private changeDetector: ChangeDetectorRef,
    private eventQueue: EventQueueService,
    private featureService: FeatureService,
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    private renderer: Renderer2,
    private router: Router,
    private userService: UserService
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.selectedMenuIndex = this.menuItems.findIndex(
        (el) => el.subMenus && el.subMenus.filter((el1) => el1.url === event.url).length > 0
      );
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.user$.subscribe((user) => {
        this.userModel = user;
        this.changeMenuItems(user);
      })
    );
    this.subscriptions.add(
      this.expert$.subscribe((expert) => {
        this.expertModel = expert;
      })
    );
    this.subscriptions.add(
      this.petOwner$.subscribe((petOwner) => {
        this.petOwnerModel = petOwner;
      })
    );
    this.subscriptions.add(
      this.router.events.subscribe((event: RouterEvent) => {
        if (event && event.url) {
          this.selectedPath = event.url;
        }
      })
    );
    this.subscriptions.add(
      this.eventQueue.subscribe<MessageModel>(AppEventType.OpenSideMenu, () => {
        this.open();
      })
    );
    this.subscriptions.add(
      this.eventQueue.subscribe<MessageModel>(AppEventType.CloseSideMenu, () => {
        this.close();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async open() {
    this.isOpen = true;
    this.changeDetector.detectChanges();

    const sideMenu = this.sideMenu.nativeElement;
    this.renderer.setStyle(sideMenu, 'display', 'initial');
    const animation = this.animationCtrl
      .create()
      .addElement(sideMenu)
      .duration(150)
      .iterations(1)
      .fromTo('opacity', '0', '1');

    const background = this.background.nativeElement;
    this.renderer.setStyle(background, 'opacity', 'initial');
    const bgAnimation = this.animationCtrl
      .create()
      .addElement(background)
      .duration(150)
      .iterations(1)
      .fromTo('opacity', '0', '0.25');

    await Promise.all([animation.play(), bgAnimation.play()]);
  }

  async close() {
    const sideMenu = this.sideMenu.nativeElement;
    const animation = this.animationCtrl
      .create()
      .addElement(sideMenu)
      .duration(150)
      .iterations(1)
      .fromTo('opacity', '1', '0');

    const background = this.background.nativeElement;
    const bgAnimation = this.animationCtrl
      .create()
      .addElement(background)
      .duration(150)
      .iterations(1)
      .fromTo('opacity', '0.25', '0');

    await Promise.all([animation.play(), bgAnimation.play()]);
    animation.destroy();
    bgAnimation.destroy();

    this.isOpen = false;
  }

  openProfile(): void {
    this.menuCtrl.close();
    this.closeBtnTapped();
    if (this.userModel?.role === 'petowner') {
      this.router.navigateByUrl('/pet-owner/profile');
    } else {
      this.router.navigateByUrl('/expert/profile');
    }
  }

  async closeBtnTapped(): Promise<void> {
    this.close();
  }

  changeMenuItems(userData: UserModel): void {
    switch (userData?.role) {
      case 'petowner':
        this.menuItems = PetOwnerMenus;
        break;
      case 'expert':
        this.menuItems = PetExpertMenus;
        break;
      case 'admin':
        this.menuItems = AdminMenus;
        break;
      default:
        this.menuItems = IntroMenus;
    }
  }

  async menuItemTapped(menuItem: IMenu, i: number): Promise<void> {
    if (!menuItem.subMenus || menuItem.subMenus.length < 1) {
      await this.menuCtrl.close();
      await this.closeBtnTapped();
    }
    if (menuItem.title === 'menu.logout') {
      await this.logout();
      return;
    }
    if (menuItem.subMenus && menuItem.subMenus.length > 0) {
      if (this.selectedMenuIndex === i) {
        this.selectedMenuIndex = null;
      } else {
        this.selectedMenuIndex = i;
      }
      return;
    }
    await this.router.navigateByUrl(menuItem.url);
  }

  async subMenuItemTapped(menuItem: IMenu): Promise<void> {
    await this.closeBtnTapped();
    await this.menuCtrl.close();
    await this.router.navigateByUrl(menuItem.url);
  }

  showMenuItem(item: IMenu): Observable<boolean> {
    if (!item.feature) return of(true);
    return this.featureService.isActive(item.feature);
  }

  async logout(): Promise<void> {
    const confirm = await this.appService.confirmPopup('common.logout_confirm');
    if (!confirm) {
      return;
    }
    this.appService.showSpinner();
    if (this.userService.getDeviceId()) {
      this.userService.removeDevice().subscribe(
        () => {
          this.logOutUser();
        },
        () => {
          this.logOutUser();
        }
      );
    } else {
      this.logOutUser();
    }
  }

  logOutUser(): void {
    this.userService.logout().subscribe(
      () => {
        this.appService.hideSpinner();
        void this.navCtrl.navigateRoot(['/auth/login']);
      },
      () => {
        this.appService.hideSpinner();
      }
    );
  }

  getExpertUrl(): string {
    return this.expertModel ? `/search/expert/${this.expertModel.id}/${slug(this.expertModel.practice_name)}` : null;
  }

  getPetOwnerUrl(): string {
    return this.petOwnerModel ? `/search/pet-owner/${this.petOwnerModel.id}/${slug(this.petOwnerModel.username)}` : null;
  }
}
