import { MapPinModel } from '@app/models/map/map.pin.model';
import { PetSpeciesModel } from '@app/models/pet-species/pet-species.model';
import { ExpertCategoryModel } from '@app/models/expert-category/expert.category.model';
import { AddressModel } from '@app/models/address/address.model';
import { ContactModel } from '../contact.model';
import { CalendarAvailabilityModel } from './calendar-availability.model';
import { PetBreedModel } from '@app/models';

export class ExpertModel {
  id: string;
  active_subscriptions: string[];
  additional_designation: string;
  additional_info: string;
  address: AddressModel = new AddressModel();
  address_id: string;
  allow_messages_notconnected: boolean;
  business_hours: CalendarAvailabilityModel[] = [];
  company_uid: string;
  contacts: ContactModel[] = [];
  correspondence_languages: string[] = [];
  created_at: Date;
  currency: string;
  email: string;
  emergency_call_rate: number;
  emergency_phone: string;
  emergency_phone_conditions: string;
  emergency_service_allowed: boolean;
  entry_type: string;
  excel_id: string;
  expert_categories: ExpertCategoryModel[] = [];
  expert_emergency_times: CalendarAvailabilityModel[] = [];
  expert_video_times: CalendarAvailabilityModel[] = [];
  fax: string;
  firstname: string;
  group_name: string;
  has_emergency: boolean;
  has_emergency_call: boolean;
  has_emergency_phone: boolean;
  has_microsite: boolean;
  has_premium_entry: boolean;
  has_video_call: boolean;
  image: string;
  image_url: string;
  is_active: boolean;
  lastname: string;
  lat: number;
  lng: number;
  my_role?: string;
  no_ads: boolean;
  organization_name: string;
  pet_breeds: PetBreedModel[];
  pet_species: PetSpeciesModel[] | string[];
  phone: string;
  pin: MapPinModel = new MapPinModel();
  pin_id: string;
  practice_name: string;
  results_position: string;
  reviewed_by_user: boolean;
  social_links: Record<string, string>;
  video_call_rate: number;
  website: string;
}
