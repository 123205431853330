<div id="add-to-home-screen-container">
  <div class="top-view">
    <img src="./assets/icons/icon-128x128.png" class="logo-img" alt="Logo" />
    <div class="text-view">
      <span class="title-label" translate="pwa.add_to_home_screen_title"></span>
      <span class="description-label">rosypet.com</span>
    </div>
    <button class="tw-m-1 menu-close-button" (click)="closeAddToHomeScreenPopup()">
      <ion-icon name="close"></ion-icon>
    </button>
  </div>
  <app-button class="tw-m-4" (click)="addToHomeScreenButtonTapped()">
    {{ 'pwa.add_to_home_screen_button' | translate }}
  </app-button>
</div>
