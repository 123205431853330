export type MapPinType =
  | 'myself'
  | 'event'
  | 'expert'
  | 'hazard'
  | 'missed_pet'
  | 'missed'
  | 'offer'
  | 'pet'
  | 'pet_owner'
  | 'robidog_toilet'
  | 'robidog_dispenser';

export class MapPinModel {
  id: string;
  lng?: number;
  lat?: number;
  text: string;
  type: MapPinType;
  item_id: string;
  distance?: number;
  data?: Record<string, unknown>;
  image_url?: string;
  tags?: string[];
  city?: string;
  address_string?: string;
  street?: string;
  street_number?: string;
}
