import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AppOverlayPopupDirective } from '@app/layout/app-overlay-popup/app-overlay-popup.directive';
import { LayoutModule } from '@app/layout/layout.module';
import { EventQueueService, OneSignalService, UpdateService, UserService } from '@app/services';
import { GlobalErrorHandler, GlobalHttpInterceptor } from '@app/utils';

import { ComponentsModule } from '@app/shared/components/components.module';
import { TranslationService } from '@app/shared/translation';
import { AppState } from '@app/store/app.state';

import { environment } from '@environments/environment';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { ShareModule } from 'ngx-sharebuttons';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

function initLanguage(translateService: TranslationService) {
  return () => translateService.initLocale();
}

@NgModule({
  declarations: [AppComponent, AppOverlayPopupDirective],
  imports: [
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    NgbModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    NgxsModule.forRoot([AppState]), NgxsStoragePluginModule.forRoot(),
    NgxsModule.forRoot([AppState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'NGXS store',
      disabled: environment.production,
    }),
    AppRoutingModule,
    LazyLoadImageModule,
    ...(environment.googleAnalyticsId && environment.googleAnalyticsId !== ''
      ? [NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsId)]
      : []),
    ShareModule,
    LayoutModule,
    ComponentsModule,
  ] as (unknown[] | Type<unknown> | ModuleWithProviders<unknown>)[],
  providers: [
    EventQueueService,
    {
      provide: LOCALE_ID,
      deps: [TranslationService],
      useFactory: (translationService: TranslationService) => translationService.getCurrentLocaleString(),
    },
    StatusBar,
    OneSignalService,
    UserService,
    UpdateService,
    SplashScreen,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initLanguage, multi: true, deps: [TranslationService] },
  ],
})
export class AppModule {}
