<div id="incoming-call-container">
  <app-image-viewer class="caller-image-view" [imageURL]="incomingUser?.getImageUrl()"></app-image-viewer>
  <div id="call-info-container">
    <div class="top-container">
      <div class="user-name-view">
        <app-text-shell
          animation="bouncing"
          [data]="incomingUser?.full_name"
          class="caller-name-label"
        ></app-text-shell>
        <span
          *ngIf="roomData"
          class="incoming-label"
          [translate]="roomData.type === 'emergency' ? 'common.incoming_emergency_call' : 'common.incoming_video_call'"
        >
        </span>
      </div>
      <button class="tw-m-1" id="close-popup-btn" (click)="closePopup()">
        <ion-icon name="close"></ion-icon>
      </button>
    </div>
    <div class="divider"></div>
    <div class="buttons-view">
      <app-button
        id="accept-call-btn"
        class="tw-m-1"
        label="common.accept"
        (click)="acceptCallBtnTapped()"
      >
      </app-button>
      <app-button
        class="tw-m-1"
        color="pink-1"
        label="common.reject"
        id="reject-call-btn"
        (click)="rejectCallBtnTapped()"
      >
      </app-button>
    </div>
  </div>
</div>
