import { Injectable } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { PaginationModel } from '@app/models';
import { QueryCommon } from '@app/models/app/query-common';
import { BaseModel } from '@app/models/base.model';
import { StripeIntentModel } from '@app/models/product/stripe.intent.model';
import { ProductListingModel } from '@app/models/product/product.listing.model';
import { ProductModel } from '@app/models/product/product.model';
import { UserProductModel } from '@app/models/product/userProductModel';
import { AppService, HttpService } from '@app/services';
import { Observable } from 'rxjs';
import { SaveProductRequest } from '@app/models/requests';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private url = 'product';
  private selectedPath: string;

  constructor(
    private httpService: HttpService,
    private appService: AppService,
    private router: Router,
    private store: Store
  ) {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event && event.url) {
        this.selectedPath = event.url;
      }
    });
  }

  getList(params: Partial<QueryCommon> = {}): Observable<PaginationModel<ProductModel>> {
    return this.httpService.getRequest<PaginationModel<ProductModel>>(`${this.url}`, true, params);
  }

  getProduct(id: string, frequency: string = null): Observable<ProductListingModel> {
    const params = {};
    if (frequency) {
      params['frequency'] = frequency;
    }
    return this.httpService.getRequest(`${this.url}/${id}`, true, params);
  }

  deleteProduct(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  saveProduct(id: string, params: SaveProductRequest): Observable<void> {
    if (id) {
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      return this.httpService.postRequest(`${this.url}`, params, true);
    }
  }
  getUserSubscriptions(userId: string = 'me'): Observable<BaseModel<UserProductModel[]>> {
    return this.httpService.getRequest<BaseModel<UserProductModel[]>>(`user/${userId}/products`, true);
  }

  changeSubscriptionStatus(
    key: string,
    status: boolean,
    frequency: string = 'year',
    user_id: string = null
  ): Observable<BaseModel<ProductModel[]>> {
    const params = {
      key,
      frequency,
      user_id,
    };
    const subscriptionUrl = this.url + '/' + (status ? 'subscribe' : 'unsubscribe');
    return this.httpService.postRequest<BaseModel<ProductModel[]>>(subscriptionUrl, params, true);
  }

  /**
   * Check If Payment Method exists
   * If not, it will display popup to add payment method
   */
  checkPaymentMethod(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const user = this.store.selectSnapshot(AppState.user);
      if (!user) {
        this.router.navigateByUrl('/').then(() => {
          return resolve(false);
        });
      } else if (!user.card_last_four) {
        this.appService
          .confirmPopup('finances.subscriptions.no_payment_method', 'common.okay', 'common.cancel')
          .then((res) => {
            if (res && this.selectedPath.indexOf('finances/subscriptions') < 0) {
              // Only Display subscription view
              // when user isn't in Subscription view
              this.router.navigateByUrl('/finances/add-payment-method');
            }
            return resolve(false);
          });
      } else {
        return resolve(true);
      }
    });
  }

  initPaymentMethod(): Observable<BaseModel<StripeIntentModel>> {
    return this.httpService.postRequest<BaseModel<StripeIntentModel>>(`stripe/payment/init`, {}, true);
  }

  updatePaymentMethod(paymentMethod: string): Observable<BaseModel<StripeIntentModel>> {
    return this.httpService.putRequest<BaseModel<StripeIntentModel>>(
      `stripe/payment/update`,
      { payment_method: paymentMethod },
      true
    );
  }
}
