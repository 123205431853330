import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { AppState } from '@app/store/app.state';
import { UserModel } from '@app/models';
import { map, takeUntil } from 'rxjs/operators';

/**
 * This custom preloading strategy aims to optimize package weight depending on user role.
 * Set route.data.preload to define when the route (module) should be loaded.
 * 'all'      always load
 * 'nologin'  only load if the user is not logged in
 * 'login'    only load if the user is logged in
 * 'petowner' only load if the user is logged in as a pet owner
 * 'expert'   only load if the user is logged in as an expert
 * 'admin'    only load if the user is logged in as an admin
 * If the `preload` data field is not present the route is not preloaded.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomPreloadingStrategy implements PreloadingStrategy {
  private constructor(private store: Store) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const preload: string = (route?.data as any)?.preload as string;
    if (preload) {
      if (preload == 'all') {
        return load();
      }

      if (!localStorage.token) {
        if (preload == 'nologin') {
          return load();
        }
      } else {
        const stopSignal$ = new Subject<void>();
        return this.store.select(AppState.user).pipe(
          map((user: UserModel) => {
            if (user == null) return of(null);

            stopSignal$.next();

            if (preload == 'login') {
              return load();
            }
            if (preload == 'petowner' && user.role == 'petowner') {
              return load();
            }
            if (preload == 'expert' && user.role == 'expert') {
              return load();
            }
            if (preload == 'admin' && user.role == 'admin') {
              return load();
            }

            return of(null);
          }),
          takeUntil(stopSignal$)
        );
      }
    }

    return of(null);
  }
}
