import { Component } from '@angular/core';
import { AppEventType, EventQueueService } from '@app/services';

@Component({
  selector: 'app-service-unavailable',
  templateUrl: './app-service-unavailable.component.html',
  styleUrls: ['./app-service-unavailable.component.scss'],
})
export class AppServiceUnavailableComponent {
  constructor(private eventQueue: EventQueueService) {
    this.eventQueue.subscribe(AppEventType.ServiceUnavailable, () => {
      const el: HTMLElement = document.querySelector('app-service-unavailable');
      el.style.display = 'unset';
    });
  }

  reloadPage(): void {
    location.reload();
  }
}
