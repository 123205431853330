import { MapPinModel, UserModel } from '@app/models';
import { PetSpeciesModel } from '@app/models/pet-species/pet-species.model';

export class HazardModel {
  id: string;
  title: string;
  description: string;
  pin_id: string;
  created_at: Date;
  since: Date | null;
  until: Date | null;
  user_id: string;
  image_url: string;
  user: UserModel;
  pin: MapPinModel;
  pet_species: PetSpeciesModel[];
}
