import { Injectable } from '@angular/core';
import { BaseListingModel } from '@app/models/base.listing.model';
import { CountryModel } from '@app/models/region/country.model';
import { CacheService, HttpService } from '@app/services';
import { Observable } from 'rxjs';
import {  PaginationModel, RegionModel } from "@app/models";
import { map } from "rxjs/operators";
import { RegionListingModel } from "@app/models/region/region.listing.model";
import { BaseModel } from "@app/models/base.model";

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  private COUNTRY_KEY = 'totalCountryList';
  private COUNTRY_EXPIRE_KEY = 'country_expire_time';
  constructor(private httpService: HttpService, private cacheService: CacheService) {}

  getCountryList(): Observable<BaseListingModel<CountryModel>> {
    const lang = this.httpService.lang;
    return this.cacheService.getCachedItems<BaseListingModel<CountryModel>>(
      () => {
        return this.httpService.getRequest<BaseListingModel<CountryModel>>('country', false);
      },
      `${this.COUNTRY_KEY}_${lang}`,
      `${this.COUNTRY_EXPIRE_KEY}_${lang}`
    );
  }

  getCountryByCode(code: string): Observable<CountryModel> {
    return this.getCountryList().pipe(
      map(result => {
        return result.data.find(item => item.short == code);
      })
    );
  }

  getRegions(
    countryId: string, parentRegionId: string|null = null, onlyRootNodes: boolean|null = null
  ): Observable<PaginationModel<RegionModel>> {
    const params = {
      country_id: countryId,
      parent_region_id: parentRegionId,
      only_root_nodes: onlyRootNodes,
      page_length: 1000,
    };
    return this.httpService.getRequest<PaginationModel<RegionModel>>('region', false, params);
  }

  getRegion(id: string): Observable<BaseModel<RegionListingModel>> {
    return this.httpService.getRequest<BaseModel<RegionListingModel>>(`region/${id}`);
  }
}
