import { Injectable } from '@angular/core';
import { PetListingModel } from '@app/models';
import { QueryCommon } from '@app/models/app/query-common';
import { BaseModel } from '@app/models/base.model';
import { PaginationModel } from '@app/models/pagination.model';
import { OnboardingPetModel } from '@app/models/pet/onboarding.pet.model';
import { PetImagesModel } from '@app/models/pet/pet.images.model';
import { PetModel } from '@app/models/pet/pet.model';
import { SavePetRequest } from '@app/models/requests';
import { fetchAsBlob } from '../utils/util';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class PetService {
  private url = 'pet';

  constructor(private httpService: HttpService) {}

  getList(params: Partial<QueryCommon> = {}): Observable<PaginationModel<PetModel>> {
    return this.httpService.getRequest<PaginationModel<PetModel>>(`${this.url}`, true, params);
  }

  getPet(id: string): Observable<PetListingModel> {
    return this.httpService.getRequest(`${this.url}/${id}`, true);
  }

  addOnboardingPet(pet: OnboardingPetModel): void {
    const onboardingPets = this.getOnboardingPets();
    onboardingPets.push(pet);
    localStorage.setItem('onboardingPets', JSON.stringify(onboardingPets));
  }

  getOnboardingPets(): OnboardingPetModel[] {
    let onboardingPets: OnboardingPetModel[] = [];
    if (localStorage.onboardingPets) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const savedPets = JSON.parse(localStorage.getItem('onboardingPets'));
        onboardingPets = savedPets as OnboardingPetModel[];
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    return onboardingPets;
  }

  saveOnboardingPet(userId: string): void {
    const onboardingPets = this.getOnboardingPets();
    if (onboardingPets && onboardingPets.length > 0) {
      onboardingPets.forEach(async (el) => {
        let image: Blob;
        if (el.image) {
          image = await fetchAsBlob(el.image);
        }
        const params = {
          user_id: userId,
          name: el.name,
          pet_species_id: el.petSpecies,
          birthdate: el.birthdate,
          status: 'home',
          pet_breeds: el.petBreeds ? el.petBreeds.split(',') : [],
        };
        this.httpService.postRequest<PetListingModel>(`${this.url}`, params, true).subscribe(async (data) => {
          if (image) {
            await this.addPetImages(data.data.pet.id, [image]).toPromise();
          }
        });
      });
    }
    localStorage.removeItem('onboardingPets');
  }

  deletePet(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  addPetImages(id: string, images: Blob[]): Observable<BaseModel<PetImagesModel>> {
    return this.httpService.multiPartFormDataRequestMultiple(
      'POST',
      `${this.url}/${id}/image/upload`,
      {},
      true,
      'images',
      images
    );
  }

  deletePetImage(imageId: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${imageId}/image`, {}, true);
  }

  setMainPhoto(imageId: string): Observable<BaseModel<PetImagesModel>> {
    return this.httpService.postRequest(`${this.url}/${imageId}/image/main`, {}, true);
  }

  savePet(id: string, params: SavePetRequest): Observable<PetListingModel> {
    if (id) {
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      return this.httpService.postRequest(`${this.url}`, params, true);
    }
  }
}
