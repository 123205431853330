<div>
  <p class="title-label">{{title}}</p>
  <swiper *ngIf="infoList.length > 0" #slides class="cloud-container-view">
    <ng-template swiperSlide *ngFor="let infoData of infoList; let i = index">
      <app-aspect-ratio [ratio]="{w: 343, h: 239}">
        <div
          class="cloud-view"
          [style.background-image]="'url(/assets/img/cloud_' + (i % 3 + 1) + '.svg)'"
          [style.background-position]="getBackgroundPosition(i)"
        >
          <div class="cloud-content-view">
            <span class="info-title-label">{{infoData.title}}</span>
            <span class="info-description-label">{{infoData.text}}</span>
            <div class="button-view">
              <app-button
                *ngIf="i > 0"
                class="tw-w-[90px] tw-m-w-[90px] tw-h-[30px] tw-m-1 tw-mr-6"
                label="common.previous"
                (click)="previousBtnTapped()"
              >
              </app-button>
              <app-button class="tw-w-[90px] tw-m-w-[90px] tw-h-[30px] tw-m-1" color="pink-1" (click)="nextBtnTapped(i)">
                {{(i >= infoList.length - 1 ? 'common.done' : 'common.next') | translate}}
              </app-button>
            </div>
            <button id="close-button" (click)="closeAppIntro()">
              <ion-icon name="close-outline"></ion-icon>
            </button>
          </div>
        </div>
      </app-aspect-ratio>
    </ng-template>
  </swiper>
</div>
