<div id="overlay-popup-container">
  <button class="tw-mt-3 tw-p-1 close-btn" (click)="close()">
    <ion-icon name="close"></ion-icon>
  </button>
  <span class="title-label" [translate]="options.titleTextKey"></span>
  <div class="sub-container">
    <div class="description-label" [innerHTML]="options.htmlTextKey | translate"></div>
    <div id="button-area">
      <app-button
        *ngFor="let button of options.buttons"
        (click)="click(button.key)"
        class="tw-m-1"
        color="pink-1"
        [label]="button.textKey"
      >
      </app-button>
    </div>
  </div>
</div>
