import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnDestroy {
  @Input() isOpen: boolean;
  @Output() modalDidDismiss: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('modal') modal: IonModal;

  async ngOnDestroy(): Promise<boolean> {
    return this.modal?.dismiss();
  }
}
