import { Injectable } from '@angular/core';
import { OfferListingModel } from '@app/models/offer/offer.listing.model';
import { OfferModel } from '@app/models/offer/offer.model';
import { PaginationModel } from '@app/models/pagination.model';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';
import { ListRequest } from '@app/models/requests';
import { OfferCategoryListingModel, OfferCategoryModel } from "@app/models";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  private url = 'offer';

  constructor(private httpService: HttpService) {}

  getCategories(parentCategoryId: string|null, includeChildren = false): Observable<OfferCategoryModel[]> {
    return this.httpService.getRequest<PaginationModel<OfferCategoryModel>>(`offer-category`, true, {
      parent_category_id: parentCategoryId,
      include_children: includeChildren,
    }).pipe(
      map(item => item.data as any as OfferCategoryModel[])
    );
  }
  
  getPopularCategories(): Observable<OfferCategoryModel[]> {
    return this.httpService.getRequest<PaginationModel<OfferCategoryModel>>(`offer-category/popular`).pipe(
      map(item => item.data as any as OfferCategoryModel[])
    )
  }
  
  getCategory(id: string): Observable<OfferCategoryListingModel> {
    return this.httpService.getRequest<OfferCategoryListingModel>(`offer-category/${id}`);
  }
  
  getList(params: ListRequest = {}): Observable<PaginationModel<OfferModel>> {
    return this.httpService.getRequest<PaginationModel<OfferModel>>(`${this.url}`, true, params);
  }

  getOffer(id: string): Observable<OfferListingModel> {
    return this.httpService.getRequest<OfferListingModel>(`${this.url}/${id}`);
  }

  deleteOffer(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }
}
