import { Component, EventEmitter, Input, Output } from '@angular/core';

export type SelectItem = {
  expandable?: boolean,
  icon?: string|null,
  iconClass?: string,
  label: string|null,
  value?: unknown|null
};

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.html',
  styleUrls: ['./select-list.scss'],
})
export class SelectListComponent {
  @Input() items: SelectItem[];
  @Output() selectedItem: EventEmitter<unknown> = new EventEmitter<unknown>();

  identifyItem(index, item: SelectItem) {
    return item.value;
  }
}
