import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { AppState } from '@app/store/app.state';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { CountryModel } from '@app/models';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-social-media',
  styleUrls: ['./social-media.scss'],
  templateUrl: './social-media.html',
})
export class SocialMediaComponent implements OnInit, OnDestroy {
  @Select(AppState.country) country$: Observable<CountryModel>;
  country = '';
  @Select(AppState.locale) locale$: Observable<string>;
  language = '';
  socialMediaLinks: { type: string, url: string }[] = [];
  subscriptions = new Subscription();

  ngOnInit() {
    this.subscriptions.add(
      combineLatest([
        this.country$,
        this.locale$,
      ]).subscribe(([country, locale]) => {
        this.country = country?.short;
        this.language = locale?.substring(0, 2);
        this.loadSocialMedia();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadSocialMedia() {
    this.socialMediaLinks = (environment.socialMedia ?? [])
      .filter((socialMedia) =>
        (!socialMedia.country || socialMedia.country === this.country)
        &&
        (!socialMedia.language || socialMedia.language === this.language)
      ).map((socialMedia) => {
        return {
          type: socialMedia.type,
          url: socialMedia.url,
        };
      });
  }
}