import { Component } from '@angular/core';
import { ISpinnerState } from '@app/models';
import { AppState } from '@app/store/app.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './app-spinner.component.html',
  styleUrls: ['./app-spinner.component.scss'],
})
export class AppSpinnerComponent {
  isSpinnerLoading = false;
  @Select(AppState.spinner) spinnerState$: Observable<ISpinnerState>;
  loadingMessage: string;
  messageParams = {};

  constructor() {
    this.spinnerState$.subscribe((state) => {
      if (state && state.show) {
        this.loadingMessage = state.message;
        this.messageParams = state.messageParams;
        this.isSpinnerLoading = true;
      } else {
        this.isSpinnerLoading = false;
      }
    });
  }
}
