import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/services';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-share-button',
  templateUrl: './app-share-button.component.html',
  styleUrls: ['./app-share-button.component.scss'],
})
export class AppShareButtonComponent {
  @Input() viewTitle = '';
  @Input() shareTitle = '';
  @Input() shareDescription = '';
  @Input() shareURL = '';
  @Input() disabled: boolean;
  @Input() iconColor: string;
  @Input() type: 'icon' | 'button' = 'icon';
  isCordova = false;

  constructor(
    public router: Router,
    private translate: TranslateService,
    private platform: Platform,
    private appService: AppService,
    private alertController: AlertController
  ) {
    this.isCordova = this.appService.isBrowser() && this.platform.is('cordova');
  }

  getURL(): string {
    if (!this.shareURL) {
      return '';
    }
    if (this.shareURL.includes('http://') || this.shareURL.includes('https://')) {
      return this.shareURL;
    }
    return window?.location.origin + this.shareURL;
  }

  isMobile(): boolean {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return this.isCordova || isMobile;
  }

  async share(event: MouseEvent): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    if (this.isMobile() && typeof navigator.share !== 'undefined') {
      this.mobileShare();
      return;
    }
    const alert = await this.alertController.create({
      mode: 'ios',
      header: this.translate.instant(this.viewTitle) as string,
      buttons: [
        {
          text: String(this.translate.instant('share.copy_to_clipboard')),
          handler: () => this.copyBtnTapped(),
        },
        {
          text: String(this.translate.instant('share.facebook')),
          handler: () => this.shareFacebook(),
        },
        {
          text: String(this.translate.instant('share.twitter')),
          handler: () => this.shareTwitter(),
        },
        {
          text: String(this.translate.instant('share.email')),
          handler: () => this.shareEmail(),
        },
      ],
    });
    await alert.present();
  }

  shareFacebook(): void {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.getURL()}`, '_blank');
  }

  shareTwitter(): void {
    window.open(`https://twitter.com/intent/tweet?url=${this.getURL()}&text=${this.shareDescription}`, '_blank');
  }

  shareEmail(): void {
    window.open(`mailto:?subject=[rosypet.com] ${this.shareTitle}&body=${this.getURL()}`);
  }

  async copyBtnTapped(): Promise<void> {
    await navigator.clipboard.writeText(this.getURL());
    await this.appService.presentSuccessToast('success_messages.copied_to_clipboard');
  }

  async mobileShare(): Promise<void> {
    const title = this.shareTitle ? String(this.translate.instant(this.shareTitle)) : '';
    const text = this.shareDescription ? String(this.translate.instant(this.shareDescription)) : '';
    try {
      await navigator.share({
        url: this.getURL(),
        title,
        text,
      });
    } catch (e) {
      if (e.toString().includes('AbortError')) {
        return;
      } else {
        throw e;
      }
    }
  }
}
