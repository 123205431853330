import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ProductService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class PaymentGuard implements CanActivate {
  constructor(private productService: ProductService) {}

  async canActivate(): Promise<boolean> {
    return await this.productService.checkPaymentMethod();
  }
}
