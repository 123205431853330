import { Injectable } from '@angular/core';
import { AddressListingModel } from '@app/models/address/address.listing.model';
import { PaginationModel } from '@app/models/pagination.model';
import { PetSpeciesModel } from '@app/models/pet-species/pet-species.model';
import { ListRequest, SaveAddressRequest } from '@app/models/requests';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private url = 'address';

  constructor(private httpService: HttpService) {}

  getList(queryParams: ListRequest): Observable<PaginationModel<PetSpeciesModel>> {
    return this.httpService.getRequest<PaginationModel<PetSpeciesModel>>(`${this.url}`, true, queryParams);
  }

  getAddress(id: string): Observable<AddressListingModel> {
    return this.httpService.getRequest(`${this.url}/${id}`, true);
  }

  delete(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  saveAddress(id: string, params: SaveAddressRequest): Observable<AddressListingModel> {
    if (id) {
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      return this.httpService.postRequest(`${this.url}`, params, true);
    }
  }
}
