import { UserModel } from '@app/models';

export class EventMapDataModel {
  id: string;
  channel_id: string;
  cost_amount: number;
  cost_currency: string;
  description: string;
  end: Date;
  external_id: string;
  external_source_url: string;
  for_expert: boolean;
  for_petowner: boolean;
  is_online: boolean;
  is_registration_required: boolean;
  location: string;
  organizer: string;
  start: Date;
  title: string;
  user_id: string;
  pin_id: string;
  image: string;
  image_url: string;
  user: UserModel;
  external_source: string;
  pet_breeds: string[];
  pet_species: string[];
  categories: { name: string }[];
  created_at: Date;
}
