import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppInfoIntroComponent } from '@app/layout/app-info-intro/app-info-intro';
import { AppIntroFooterComponent } from '@app/layout/app-intro-footer/app-intro-footer';
import { AppOverlayPopupComponent } from '@app/layout/app-overlay-popup/app-overlay-popup.component';
import { AppPwaPopupComponent } from '@app/layout/app-pwa-popup/app-pwa-popup.component';
import { AppServiceUnavailableComponent } from '@app/layout/app-service-unavailable/app-service-unavailable.component';
import { HeaderComponent } from '@app/layout/header/header.component';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { AppAudioComponent } from '@app/layout/app-audio/app-audio.component';
import { AppIncomingCallPopupComponent } from '@app/layout/app-incoming-call-popup/app-incoming-call-popup';
import { AppCallWrapperComponent } from '@app/layout/app-call/app-call-wrapper.component';
import { DeviceService } from '@app/services';
import { SwiperModule } from 'swiper/angular';
import { SideMenuComponent } from '@app/layout/side-menu/side-menu';
import { LanguageSwitcherComponent } from '@app/layout/side-menu/language-switcher/language-switcher';
import { CountrySwitcherComponent } from '@app/layout/side-menu/country-switcher/country-switcher';
import { SocialMediaComponent } from '@app/layout/side-menu/social-media/social-media';
import { BaseComponentsModule } from '@app/shared/components/base/base.components.module';

@NgModule({
  declarations: [
    AppAudioComponent,
    AppCallWrapperComponent, // <--
    AppIncomingCallPopupComponent,
    AppInfoIntroComponent,
    AppIntroFooterComponent,
    AppOverlayPopupComponent,
    AppPwaPopupComponent,
    AppServiceUnavailableComponent,
    CountrySwitcherComponent,
    FooterComponent,
    LanguageSwitcherComponent,
    HeaderComponent,
    SideMenuComponent,
    SocialMediaComponent,
  ],
  imports: [
    BaseComponentsModule,
    CommonModule,
    SharedModule,
    SwiperModule,
  ],
  providers: [DeviceService],
  exports: [
    AppAudioComponent,
    AppCallWrapperComponent,
    AppIncomingCallPopupComponent,
    AppInfoIntroComponent,
    AppIntroFooterComponent,
    AppIntroFooterComponent,
    AppOverlayPopupComponent,
    AppPwaPopupComponent,
    AppServiceUnavailableComponent,
    FooterComponent,
    HeaderComponent,
    SideMenuComponent,
  ],
})
export class LayoutModule {}
