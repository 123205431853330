import { PetBreedTextModel } from '@app/models/pet-breed/pet-breed.text.model';
import { PetSpeciesModel } from '@app/models/pet-species/pet-species.model';

export class PetBreedModel {
  id: string;
  name: string;
  pet_species_id: string;
  isChecked: boolean;
  pet_species = new PetSpeciesModel();
  texts: PetBreedTextModel[] = [];
}
