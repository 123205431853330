import { AppAddressLabelComponent } from '@app/shared/components/app-address-label/app-address-label.component';
import { AppBackButtonComponent } from '@app/shared/components/app-back-button/app-back-button-component';
import { AppDashboardItemComponent } from '@app/shared/components/app-dashboard-item/app-dashboard-item';
import { AppEmailLabelComponent } from '@app/shared/components/app-email-label/app-email-label.component';
import { AppErrorComponent } from '@app/shared/components/app-error/app-error';
import { AppImageViewerComponent } from '@app/shared/components/app-image-viewer/app-image-viewer';
import { AppInfoBoxComponent } from '@app/shared/components/app-info-box/app-info-box.component';
import { AppInfoIconComponent } from '@app/shared/components/app-info-icon/app-info-icon.component';
import { AppLogoButtonComponent } from '@app/shared/components/app-logo/app-logo-button';
import { AppNoResultViewComponent } from '@app/shared/components/app-no-result-view/app-no-result-view.component';
import { AppPaginationControlComponent } from '@app/shared/components/app-pagination-control/app-pagination-control.component';
import { AppRefresherComponent } from '@app/shared/components/app-refresher/app-refresher.component';
import { AppSearchBarComponent } from '@app/shared/components/app-search-bar/app-search-bar';
import { AppShareButtonComponent } from '@app/shared/components/app-share-button/app-share-button.component';
import { AppShareViewComponent } from '@app/shared/components/app-share-view/app-share-view';
import { AppSpinnerComponent } from '@app/shared/components/app-spinner/app-spinner.component';
import { AppSubscriptionItemComponent } from '@app/shared/components/app-subscription-item/app-subscription-item.component';
import { AppSubscriptionToggleComponent } from '@app/shared/components/app-subscription-toggle/app-subscription-toggle.component';
import { CalendarAvailabilityPipe } from '@app/shared/pipes/calendar-availability.pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { DecimalFormatterDirective } from '@app/shared/directives/decimal-formatter.directive';
import { DecimalFormatterPipe } from '@app/shared/pipes/decimal-formatter.pipe';
import { DropdownMenuComponent } from '@app/shared/components/dropdown-menu/dropdown-menu';
import { EventItemComponent } from '@app/shared/components/app-event-item/event-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LibrariesModule } from '../libraries/libraries.module';
import { ListMapToggleComponent } from '@app/shared/components/list-map-toggle/list-map-toggle-component';
import { MapEventDataComponent } from '@app/shared/components/map-data/event/map-event-data.component';
import { MapExpertDataComponent } from '@app/shared/components/map-data/expert/map-expert-data.component';
import { MapHazardDataComponent } from '@app/shared/components/map-data/hazard/map-hazard-data.component';
import { MapItemComponent } from '@app/shared/components/map-data/map-item/map-item.component';
import { MapMissedPetDataComponent } from '@app/shared/components/map-data/missed-pet/map-missed-pet-data.component';
import { MapOfferDataComponent } from './map-data/offer/map-offer-data.component';
import { MapPetOwnerDataComponent } from '@app/shared/components/map-data/pet-owner/map-pet-owner-data.component';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { NewsFeedItemComponent } from '@app/shared/components/news-feed-item/news-feed-item.component';
import { NewsViewComponent } from '@app/shared/components/news-view/news-view.component';
import { NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { PhoneNumberModule } from '@app/shared/phone-number/phone-number.module';
import { PopoverMenuComponent } from '@app/shared/components/popover-menu/popover-menu.component';
import { RosypetFloatingContainerComponent } from '@app/shared/components/rosypet-floating-container/rosypet-floating-container';
import { RosypetFloatingLabelComponent } from '@app/shared/components/rosypet-floating-label/rosypet-floating-label';
import { RosypetMaskContainerComponent } from '@app/shared/components/rosypet-mask-container/rosypet-mask-container';
import { RouterModule } from '@angular/router';
import { SelectListComponent } from '@app/shared/components/select-list/select-list';
import { ShareModule } from 'ngx-sharebuttons';
import { ShellModule } from '@app/shared/components/shell/shell.module';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { VideoFeedItemComponent } from '@app/shared/components/video-feed-item/video-feed-item.component';
import { VideoSubscriptionComponent } from './video-subscription/video-subscription.component';
import {
  AppSubscriptionMatrixComponent
} from '@app/shared/components/app-subscription-matrix/app-subscription-matrix.component';
import { BaseComponentsModule } from '@app/shared/components/base/base.components.module';

@NgModule({
  declarations: [
    AppAddressLabelComponent,
    AppBackButtonComponent,
    AppDashboardItemComponent,
    AppEmailLabelComponent,
    AppErrorComponent,
    AppImageViewerComponent,
    AppInfoBoxComponent,
    AppInfoIconComponent,
    AppLogoButtonComponent,
    AppNoResultViewComponent,
    AppPaginationControlComponent,
    AppRefresherComponent,
    AppSearchBarComponent,
    AppShareButtonComponent,
    AppShareViewComponent,
    AppSpinnerComponent,
    AppSubscriptionItemComponent,
    AppSubscriptionMatrixComponent,
    AppSubscriptionToggleComponent,
    CalendarAvailabilityPipe,
    DecimalFormatterDirective,
    DecimalFormatterPipe,
    DropdownMenuComponent,
    EventItemComponent,
    ListMapToggleComponent,
    MapEventDataComponent,
    MapExpertDataComponent,
    MapHazardDataComponent,
    MapItemComponent,
    MapMissedPetDataComponent,
    MapOfferDataComponent,
    MapPetOwnerDataComponent,
    ModalComponent,
    ModalHeaderComponent,
    NewsFeedItemComponent,
    NewsViewComponent,
    PopoverMenuComponent,
    RosypetFloatingContainerComponent,
    RosypetFloatingLabelComponent,
    RosypetMaskContainerComponent,
    SelectListComponent,
    VideoFeedItemComponent,
    VideoSubscriptionComponent,
  ],
  imports: [
    // InternationalPhonenumber pipe; backend should return formatted phone numbers (in experts and in expert map items
    BaseComponentsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    LibrariesModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgSelectModule,
    NgxYoutubePlayerModule,
    PhoneNumberModule, // TODO: remove this import; map-expert-data.component currently uses the
    ReactiveFormsModule,
    RouterModule,
    ShareModule,
    ShellModule,
  ],
  exports: [
    AppAddressLabelComponent,
    AppBackButtonComponent,
    AppDashboardItemComponent,
    AppEmailLabelComponent,
    AppErrorComponent,
    AppImageViewerComponent,
    AppInfoBoxComponent,
    AppInfoIconComponent,
    AppLogoButtonComponent,
    AppNoResultViewComponent,
    AppPaginationControlComponent,
    AppRefresherComponent,
    AppSearchBarComponent,
    AppShareButtonComponent,
    AppShareViewComponent,
    AppSpinnerComponent,
    AppSubscriptionItemComponent,
    AppSubscriptionMatrixComponent,
    AppSubscriptionToggleComponent,
    CalendarAvailabilityPipe,
    DecimalFormatterDirective,
    DecimalFormatterPipe,
    DropdownMenuComponent,
    EventItemComponent,
    ListMapToggleComponent,
    MapEventDataComponent,
    MapExpertDataComponent,
    MapHazardDataComponent,
    MapItemComponent,
    MapMissedPetDataComponent,
    MapPetOwnerDataComponent,
    ModalComponent,
    ModalHeaderComponent,
    NewsFeedItemComponent,
    NewsViewComponent,
    PopoverMenuComponent,
    RosypetFloatingContainerComponent,
    RosypetFloatingLabelComponent,
    RosypetMaskContainerComponent,
    SelectListComponent,
    ShellModule,
    VideoFeedItemComponent,
    VideoSubscriptionComponent,
  ],
  providers: [DatePipe, SocialSharing, DecimalFormatterPipe],
})
export class ComponentsModule {}
