import { addDays } from 'date-fns';

export const fetchAsBlob = (url: string): Promise<Blob> => fetch(url).then((response) => response.blob());

export const convertBlobToBase64 = (blob: Blob): Promise<string | ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export const getDaysInRange = (startDate: Date, endDate: Date): Date[] => {
  const daysInRange: Date[] = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    daysInRange.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }

  return daysInRange;
}
