import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountryModel, MapPinModel, MapPinType, RegionModel } from '@app/models';
import { PaginationModel } from '@app/models/';
import { HttpService } from '@app/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseModel } from '@app/models/base.model';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private url = 'map';

  constructor(private httpService: HttpService, private http: HttpClient) {}

  getMyCountry(): Observable<BaseModel<{ code: string, country?: CountryModel }>> {
    return this.httpService.getRequest(this.url + '/my-country', false);
  }

  getMapPins(queryParams: Record<string, unknown>): Observable<PaginationModel<MapPinModel>> {
    return this.httpService.getRequest<PaginationModel<MapPinModel>>(this.url + '/pin', true, queryParams);
  }
  
  /**
   * Return mock items from type array
   * @param types: Map Pin Type
   */
  mockItems(types: MapPinType[]): MapPinModel[] {
    const list: MapPinModel[] = [];
    for (let i = 0; i < 6; i++) {
      list.push({
        ...new MapPinModel(),
        type: types[i % types.length],
        text: '',
        data: {},
      });
    }
    return list;
  }

  getCities(tags: string[], parent_region_id: string): Observable<RegionModel[]> {
    return this.httpService.getRequest(this.url + '/pin/cities', true, { 'tags[]': tags, parent_region_id })
      .pipe(map(data => {
        return (data as any).data as RegionModel[];
      }));
  }
}
