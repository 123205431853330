import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PaginationModel } from '@app/models/';
import { QueryCommon } from '@app/models/app/query-common';
import { BaseModel } from '@app/models/base.model';
import { InvoiceModel } from '@app/models/invoice/invoice.model';
import { UnInvoiceModel } from '@app/models/invoice/uninvoice.model';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppService, EventQueueService, HttpService, UserService } from '@app/services';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private url = 'user/me/invoices';

  constructor(
    private eventQueue: EventQueueService,
    private httpService: HttpService,
    private userService: UserService,
    private router: Router,
    private appService: AppService,
    private store: Store
  ) {}

  getInvoiceList(queryParams: Partial<QueryCommon> = {}): Observable<PaginationModel<InvoiceModel>> {
    return this.httpService.getRequest<PaginationModel<InvoiceModel>>(this.url, true, queryParams);
  }

  getInvoice(id: string): Observable<BaseModel<InvoiceModel>> {
    return this.httpService.getRequest(`invoice/${id}`, true);
  }

  getUnInvoicedItems(): Observable<BaseModel<UnInvoiceModel[]>> {
    return this.httpService.getRequest<BaseModel<UnInvoiceModel[]>>('user/me/uninvoiced', true);
  }

  getUnCreditedItems(): Observable<BaseModel<UnInvoiceModel[]>> {
    return this.httpService.getRequest<BaseModel<UnInvoiceModel[]>>('user/me/uncredited', true);
  }

  async payInvoice(id: string): Promise<void> {
    const user = this.store.selectSnapshot(AppState.user);
    if (!user.card_last_four) {
      await this.router.navigateByUrl('/finances/add-payment-method');
      return;
    }
    const confirm = await this.appService.confirmPopup('finances.invoices.pay_invoice_confirm');
    if (!confirm) {
      return;
    }
    this.appService.showSpinner();
    this.httpService.postRequest('invoice/charge', { invoice_id: id }, true).subscribe(
      () => {
        this.appService.hideSpinner();
        this.appService.presentSuccessToast('success_messages.invoice_paid');
      },
      () => {
        this.appService.hideSpinner();
        this.appService.presentErrorToast('error_messages.payment_error');
      }
    );
  }

  /**
   * Download PDF
   */
  downloadPDF(id: string): void {
    this.appService.showSpinner();
    this.httpService.getRequest(`invoice/${id}/download`, true, {}, true).subscribe(
      (data) => {
        this.appService.hideSpinner();
        const url = window.URL.createObjectURL(new Blob([data as BlobPart], { type: 'application/pdf' }));
        window.open(url);
      },
      (error: HttpErrorResponse) => {
        this.appService.hideSpinner();
        this.appService.processAPIErrorMessage(error);
      }
    );
  }
}
