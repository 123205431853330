import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { TranslateService as NGXTranslateService } from '@ngx-translate/core';
import { ILocale } from '@app/shared/translation/locale.interface';
import { Locale } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const LANG_DEFAULT = 'de';
const STORAGE_LOCALE = 'langCode';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private currentLocale: string;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: string,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    @Inject(NGXTranslateService) private _translate: NGXTranslateService,
    @Inject(Meta) private _meta: Meta
  ) {}

  public initLocale(): Promise<unknown> {
    this._translate.addLangs(this.getLanguages());
    const locale = this.getLocale();
    this._translate.setDefaultLang(locale.language);
    return this.setLocale(locale).toPromise();
  }
  
  private getLocale(): ILocale {
    let locale: ILocale = undefined;
    const localeString = localStorage.getItem(STORAGE_LOCALE);
    if (localeString) {
      locale = this.getEnvironmentLocale(localeString);
    }
    if (locale) {
      return locale;
    }
    if (isPlatformBrowser(this._platformId)) {
      locale = this.getEnvironmentLocale(this._translate.getBrowserCultureLang());
      if (locale) {
        return locale;
      }
      if (typeof navigator !== 'undefined' && navigator.language) {
        locale = this.getEnvironmentLocale(navigator.language);
      }
    }
    if (!locale) {
      locale = this.getEnvironmentLocale(LANG_DEFAULT);
    }
    return locale;
  }

  private getEnvironmentLocale(localeString: string|undefined): ILocale {
    const split = localeString.split('-');
    const language = split[0];
    const country = split[1] ?? undefined;
    let locale = environment.appLocales.find((x) => x.language === language && x.country === country && !x.adminOnly);
    if (!locale) {
      locale = environment.appLocales.find((x) => x.language === language && !x.adminOnly);
    }
    return locale;
  }

  private setLocale(locale: ILocale|undefined): Observable<unknown> {
    return this._translate.use(locale.language).pipe(
      map(() => {
        const localeString = locale.language + (locale.country ? '-' + locale.country : '');
        this.currentLocale = localeString;
        this._meta.updateTag({
          property: 'og:locale',
          content: localeString,
        });
        this._document.documentElement.lang = localeString;
      })
    );
  }

  public changeLocale(localeString: string): Observable<unknown> {
    const locale = this.getEnvironmentLocale(localeString);
    if (!locale || localeString === this.currentLocale) {
      return of(null);
    }
    localStorage.setItem(STORAGE_LOCALE, locale.language + (locale.country ? '-' + locale.country : ''));
    return this.setLocale(locale);
  }

  public getCurrentLocale(): ILocale {
    return this.getEnvironmentLocale(this.currentLocale);
  }

  public getCurrentLocaleString(): string {
    return this.currentLocale;
  }

  public getDateFnsLocale(): Locale {
    switch (this.getCurrentLocale().language) {
      case 'de':
        return de;
      case 'en':
        return enUS;
      default:
        return enUS;
    }
  }

  getLanguages(includeAdmin: boolean = false): string[] {
    return environment.appLocales
      .filter((x) => !x.adminOnly || includeAdmin)
      .map((x) => x.language)
      .filter((v, i, a) => a.indexOf(v) === i);
  }

  getLocales(includeAdmin: boolean = false): string[] {
    return environment.appLocales
      .filter((x) => !x.adminOnly || includeAdmin)
      .map((x) => x.language + (x.country ? '-' + x.country : ''));
  }
}
