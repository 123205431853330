<ion-content>
  <div class="tw-absolute tw-top-0 tw-left-0">
    <div class="tw-flex tw-flex-row tw-mx-4 tw-my-2.5">
      <button class="tw-m-1 logo-btn">
        <picture>
          <source media="(max-width: 400px)" srcset="/assets/img/app_logo_small.svg" />
          <img class="app-logo" src="/assets/img/app_logo.svg" alt="Logo" />
        </picture>
      </button>
    </div>
  </div>

  <div class="illustration-wrapper">
    <img src="./assets/img/page-not-found.svg" alt="Service Unavailable" />
  </div>
  <div class="service-unavailable-title" translate="service_unavailable.title"></div>
  <div class="service-unavailable-title" translate="service_unavailable.description"></div>
  <app-button color="blue-2" [outline]="true" label="service_unavailable.try_again" (click)="reloadPage()"></app-button>
</ion-content>
