import { enableProdMode } from '@angular/core';
if (environment.production) {
  enableProdMode();
}
// DO NOT PUT ANY IMPORTS ABOVE THIS LINE

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppBrowserModule } from '@app/app.browser.module';

import { environment } from '@environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { isValid } from 'date-fns';

// import locales both in main and main-server
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/de';

import { LocalStorageHelper } from '@app/utils';
LocalStorageHelper.init(localStorage);

defineCustomElements(window);

const cookieAcceptTime = localStorage.getSettingValue('cookie_accept_timestamp') as number;
const isCookieAccepted = !!cookieAcceptTime && isValid(new Date(cookieAcceptTime));
window[`ga-disable-${environment.googleAnalyticsId}`] = !isCookieAccepted;

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .then(() => {
      document.getElementsByTagName('html')[0].classList.remove('loading');
      if ('serviceWorker' in navigator) {
        // Register a service worker hosted at the root of the
        // site using a more restrictive scope.
        navigator.serviceWorker.register('./OneSignalSDKWorker.js').then(
          function (registration) {
            // eslint-disable-next-line no-console
            console.log('Service worker registration succeeded:', registration);
          },
          function (error) {
            // eslint-disable-next-line no-console
            console.log('Service worker registration failed:', error);
          }
        );
      } else {
        // eslint-disable-next-line no-console
        console.log('Service workers are not supported.');
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Service worker registration failed:', error);
    });
});

// initialize swiper
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';
import { IonicSlides } from '@ionic/angular';
SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom, IonicSlides]);
