import { Injectable } from '@angular/core';
import { PaginationModel } from '@app/models';
import { QueryCommon } from '@app/models/app/query-common';
import { BaseModel } from '@app/models/base.model';
import { CouponListingModel } from '@app/models/coupon/coupon.listing.model';
import { CouponModel } from '@app/models/coupon/coupon.model';
import { SaveCouponRequest } from '@app/models/requests';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  private url = 'coupon';

  constructor(private httpService: HttpService) {}

  getList(expertId: string, queryParams: Partial<QueryCommon> = {}): Observable<PaginationModel<CouponModel>> {
    return this.httpService.getRequest<PaginationModel<CouponModel>>(
      `${this.url}/expert/${expertId}`,
      true,
      queryParams
    );
  }

  getCoupon(id: string): Observable<BaseModel<CouponListingModel>> {
    return this.httpService.getRequest(`${this.url}/${id}`, true);
  }

  redeemCoupon(id: string): Observable<BaseModel<void>> {
    return this.httpService.postRequest(`${this.url}/${id}/redeem`, {}, true);
  }

  viewCoupon(code: string, id: string): Observable<BaseModel<{ coupon: CouponModel }>> {
    return this.httpService.getRequest(`${this.url}/${id}/code/${code}/view`, true, {});
  }

  deleteCoupon(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  saveCoupon(expertId: string, id: string, params: SaveCouponRequest): Observable<void> {
    if (id) {
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      return this.httpService.postRequest(`${this.url}/expert/${expertId}`, params, true);
    }
  }
}
