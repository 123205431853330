import { Injectable } from '@angular/core';
import { ExpertListingModel, ExpertModel, PaginationModel } from '@app/models';
import { QueryCommon } from '@app/models/app/query-common';
import { BaseListingModel } from '@app/models/base.listing.model';
import { BaseModel } from '@app/models/base.model';
import { CouponModel } from '@app/models/coupon/coupon.model';
import { ExpertFindProfileModel } from '@app/models/expert/expert.find.profile.model';
import { MessageAnalyticsModel } from '@app/models/message/message-analytics.model';
import { PetOwnerInviteModel } from '@app/models/petowner/pet-owner-invite.model';
import { EmergencyExpertListRequest, ExpertSendMessageRequest, SaveExpertRequest } from '@app/models/requests';
import { ConnectionListingModel } from '@app/models/user/connection.listing.model';
import { ConnectionModel } from '@app/models/user/connection.model';
import { VoucherModel } from '@app/models/voucher/voucher.model';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class ExpertService {
  private url = 'expert';

  constructor(private httpService: HttpService) {}

  getList(queryParams: QueryCommon): Observable<PaginationModel<ExpertModel>> {
    return this.httpService.getRequest<PaginationModel<ExpertModel>>(this.url, true, queryParams);
  }

  getExpert(id: string): Observable<ExpertListingModel> {
    return this.httpService.getRequest<ExpertListingModel>(`${this.url}/${id}`, true);
  }

  getVouchers(id: string): Observable<PaginationModel<VoucherModel>> {
    return this.httpService.getRequest<PaginationModel<VoucherModel>>(`${this.url}/${id}/vouchers`, true);
  }

  getCoupons(id: string): Observable<PaginationModel<CouponModel>> {
    return this.httpService.getRequest<PaginationModel<CouponModel>>(`${this.url}/${id}/coupons`, true);
  }

  getEmergencyCoverage(expertId: string): Observable<BaseModel<Record<string, Record<string, boolean>>>> {
    return this.httpService.getRequest<BaseModel<Record<string, Record<string, boolean>>>>(
      `${this.url}/${expertId}/emergency/coverage`,
      true
    );
  }

  getInvitations(expertId: string): Observable<PaginationModel<PetOwnerInviteModel>> {
    return this.httpService.getRequest<PaginationModel<PetOwnerInviteModel>>(
      `${this.url}/${expertId}/invitations`,
      true,
      { page_length: 1000 }
    );
  }

  removeInvitation(expertId: string, id: string): Observable<void> {
    return this.httpService.postRequest<void>(`${this.url}/${expertId}/invitations/${id}/revoke`, {}, true);
  }

  /**
   * Find My Profile
   */
  findMyProfile(params: QueryCommon): Observable<PaginationModel<ExpertFindProfileModel>> {
    return this.httpService.getRequest(`${this.url}/profile/find`, true, params);
  }

  /**
   * Binds user to expert
   */
  claimProfile(expertId: string): Observable<PaginationModel<ExpertFindProfileModel>> {
    return this.httpService.postRequest(`${this.url}/${expertId}/profile/claim`, {}, true);
  }
  /**
   * Connect To Pet Owner From Expert
   * @param petOwnerId: Pet Owner Id
   */
  connectToPetOwner(expertId: string, petOwnerId: string): Observable<BaseModel<ConnectionListingModel>> {
    return this.httpService.postRequest(`${this.url}/${expertId}/pet_owner/${petOwnerId}/connect`, {}, true);
  }

  getEmergencyExpertList(params: EmergencyExpertListRequest): Observable<BaseListingModel<ExpertModel>> {
    return this.httpService.getRequest(`${this.url}/list/emergency`, true, params);
  }

  deleteExpert(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  sendInvite(id: string): Observable<{ data: { connection: ConnectionModel } }> {
    return this.httpService.postRequest(`${this.url}/${id}/invite`, {}, true);
  }

  saveExpert(
    id: string,
    params: Partial<SaveExpertRequest>,
    image: File | Blob = null,
    isProfileImageUpdated: boolean = false
  ): Observable<ExpertModel> {
    if (id) {
      // Need this case because user might try to save expert data without selecting image
      // In this case we must not pass image as it might be null
      if (isProfileImageUpdated) {
        return this.httpService.multiPartFormDataRequest('PUT', `${this.url}/${id}`, params, true, 'image', image);
      }
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      if (isProfileImageUpdated) {
        return this.httpService.multiPartFormDataRequest('POST', `${this.url}`, params, true, 'image', image);
      }
      return this.httpService.postRequest(`${this.url}`, params, true);
    }
  }

  preRegisterExpert(params: { email: string }): Observable<void> {
    return this.httpService.postRequest(`${this.url}/preregister`, params, true);
  }

  sendMessage(params: ExpertSendMessageRequest): Observable<void> {
    return this.httpService.postRequest('message/send', params, true);
  }

  getMessageAnalytics(
    expertId: string,
    date_from: Date | null = null,
    date_to: Date | null = null
  ): Observable<BaseListingModel<MessageAnalyticsModel>> {
    return this.httpService.getRequest(`message/expert/${expertId}/analytics`, true, {
      date_from: date_from,
      date_to: date_to,
    });
  }
}
