import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppOverlayPopupDirective } from '@app/layout/app-overlay-popup/app-overlay-popup.directive';
import { environment } from '@environments/environment';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ActionModel, ConnectionModel, MessageBroadcastModel } from '@app/models';
import {
  AppEventType,
  AppService,
  EventQueueService,
  ExpertService,
  FeatureService,
  OneSignalService,
  SocketService,
  UpdateService,
} from '@app/services';

import {
  AddConnectionAction,
  AddSubscriptionAction,
  ChangeFeatureStatusAction,
  IncreaseActionBadgeCount,
  IncreaseMessageBadgeCount,
  RemoveSubscriptionAction,
  UpdateConnectionAction,
  UpdateExpertAction,
  UpdateLanguageAction,
  UpdatePetOwnerAction,
  UpdateUserObjectAction,
} from '@app/store/app.action';
import { AppState } from '@app/store/app.state';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonContent, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ExpertModel, IToast, PetOwnerModel, UserModel } from './models';
import { ProductModel } from '@app/models/product/product.model';
import { PopupOptionsType } from '@app/layout/app-overlay-popup/app-overlay-popup.component';
import { MauticService } from '@app/services/mautic.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  @Select(AppState.toast) toast$: Observable<IToast>;
  @ViewChild(IonContent) ionContent: IonContent;
  @Select(AppState.user) user$: Observable<UserModel>;
  @Select(AppState.expert) expert$: Observable<ExpertModel>;
  userModel: UserModel;
  expertModel: ExpertModel;
  showAddToHomeScreenPopup = false;
  currentUrl = '';
  previousSocketConnectUserId: string;
  isSpinnerDisplayed = false;
  @ViewChild(AppOverlayPopupDirective, { static: true }) appOverlayPopup!: AppOverlayPopupDirective;
  private subscriptions = new Subscription();

  constructor(
    private appService: AppService,
    private appState: AppState,
    private eventQueue: EventQueueService,
    private expertService: ExpertService,
    private featureService: FeatureService,
    private mauticService: MauticService,
    private oneSignalService: OneSignalService,
    private platform: Platform,
    private router: Router,
    private socketService: SocketService,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private store: Store,
    private sw: UpdateService,
    private translateService: TranslateService
  ) {
    // check the service worker for updates
    this.sw.checkForUpdates();

    this.initializeApp();

    this.store.dispatch(new UpdateLanguageAction(this.translateService.currentLang));
    this.translateService.onLangChange.subscribe((event) => {
      this.store.dispatch(new UpdateLanguageAction(event.lang));
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
      this.appService.updateHreflangTags();
    });
  }

  initializeApp(): void {
    this.featureService.loadFeatures();

    if (!this.appService.isBrowser()) {
      this.appService.updateHreflangTags();
      return;
    }
    this.oneSignalService.init();
    void this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      // Detects if device is on iOS
      const isIos = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
      };
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      this.appOverlayPopup.showCookiePopup();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const isPwaPopupShown = localStorage.getSettingValue('is_pwa_popup_shown');
      // Checks if should display install popup notification:
      if (isIos() && isSafari && !isPwaPopupShown && environment.environment != 'e2e') {
        this.showAddToHomeScreenPopup = true;
      }
    });
  }

  ngOnInit(): void {
    this.appService.resetMeta();
    this.listenEvents();
    this.subscribe();
    if (localStorage.token) {
      // Show loading spinner while getting user info
      this.isSpinnerDisplayed = true;
      this.appService.showSpinner();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribe(): void {
    this.subscriptions.add(
      this.router.events.subscribe(e => {
        if (e instanceof NavigationStart) {
          this.appService.resetMeta();
        }
      })
    );
    this.subscriptions.add(
      this.user$.subscribe((user) => {
        this.userModel = user;
        this.isSpinnerDisplayed = false;
        this.appService.hideSpinner();
        if (user && user.id) {
          if (user.id !== this.previousSocketConnectUserId) {
            this.socketService.connect(user.id);
          }
          this.previousSocketConnectUserId = user.id;
        } else {
          this.previousSocketConnectUserId = null;
          this.socketService.disconnect();
        }
      })
    );
    this.subscriptions.add(
      this.expert$.subscribe((expert) => {
        this.expertModel = expert;
      })
    );
  }

  listenEvents(): void {
    this.eventQueue.subscribe<MessageBroadcastModel>(AppEventType.MessageReceived, (data) => {
      const redirectUrl = `/message-center/${data.message_channel_id}`;
      if (
        !this.currentUrl.includes('/message-center') &&
        data.from_user_id !== this.userModel?.id &&
        data.from_expert_id !== this.expertModel?.id
      ) {
        const toastMsg = this.translateService.instant(`common.new_message_from_sender`, {
          sender: data.sender_nickname,
        }) as string;
        this.appService.displayToastForAction(toastMsg, redirectUrl);
        this.store.dispatch(new IncreaseMessageBadgeCount());
      }
    });
    this.eventQueue.subscribe<ActionModel>(AppEventType.ActionReceived, (data) => {
      if (this.currentUrl !== '/action-center') {
        this.appService.displayToastForAction(data.title, `/action-center`);
        this.store.dispatch(new IncreaseActionBadgeCount());
      }
    });
    this.eventQueue.subscribe<PetOwnerModel>(AppEventType.PetOwnerUpdated, (data) => {
      this.store.dispatch(new UpdatePetOwnerAction(data));
    });
    this.eventQueue.subscribe<ExpertModel>(AppEventType.ExpertUpdated, (data) => {
      this.store.dispatch(new UpdateExpertAction(data));
    });
    this.eventQueue.subscribe<UserModel>(AppEventType.UserUpdated, (data) => {
      this.store.dispatch(new UpdateUserObjectAction(data));
    });
    this.eventQueue.subscribe<ConnectionModel>(AppEventType.ConnectionCreated, (data) => {
      this.store.dispatch(new AddConnectionAction(data));
    });
    this.eventQueue.subscribe<ConnectionModel>(AppEventType.ConnectionUpdated, (data) => {
      this.store.dispatch(new UpdateConnectionAction(data));
    });
    this.eventQueue.subscribe<{ feature: string; active: boolean }>(AppEventType.FeatureStatusChanged, (data) => {
      this.store.dispatch(new ChangeFeatureStatusAction(data));
    });
    this.eventQueue.subscribe<ProductModel>(AppEventType.ProductSubscribed, (data) => {
      this.store.dispatch(new AddSubscriptionAction(data.id, data.key, data.frequency));
    });
    this.eventQueue.subscribe<ProductModel>(AppEventType.ProductUnSubscribed, (data) => {
      this.store.dispatch(new RemoveSubscriptionAction(data.id, data.key, data.frequency));
    });
    this.eventQueue.subscribe<PopupOptionsType>(AppEventType.ShowOverlayPopup, (data) => {
      this.appOverlayPopup.showPopup(data);
    });
  }

  onActivate(): void {
    if (this.appService.isBrowser()) {
      this.router.events.subscribe(() => {
        void this.ionContent.scrollToTop(100);
      });
    }
  }
}
