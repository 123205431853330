import { Time } from '@app/utils';

/* eslint-disable max-len */
const isoDateRegex =
  /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$/;
/* eslint-enable max-len */

// walks through object properties deeply and replaces date strings with date objects
export function fixDateFields<T>(obj: T) {
  if (obj == null) return;

  for (const [key, value] of Object.entries(obj)) {
    switch (typeof value) {
      case 'object':
        fixDateFields(value);
        break;
      case 'string':
        if (isoDateRegex.test(value)) {
          obj[key] = new Date(value);
        } else if (Time.timeRegex.test(value)) {
          obj[key] = new Time(value);
        }
        break;
    }
  }
}
