import { Injectable } from '@angular/core';
import { PaginationModel } from '@app/models';
import { QueryCommon } from '@app/models/app/query-common';
import { BaseModel } from '@app/models/base.model';
import { SaveVoucherRequest } from '@app/models/requests';
import { VoucherUserModel } from '@app/models/voucher/voucher.user.model';
import { VoucherModel } from '@app/models/voucher/voucher.model';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';
import { VoucherReportOverviewModel } from '@app/models/voucher/voucher.report.overview.model';
import { VoucherReportDetailModel } from '@app/models/voucher/voucher.report.detail.model';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  private url = 'voucher';

  constructor(private httpService: HttpService) {}

  /**
   * Get all voucher list
   * @param queryParams: Params
   */
  getList(expertId: string, queryParams: Partial<QueryCommon> = {}): Observable<PaginationModel<VoucherModel>> {
    return this.httpService.getRequest<PaginationModel<VoucherModel>>(
      `${this.url}/expert/${expertId}`,
      true,
      queryParams
    );
  }

  /**
   * Get my vouchers
   */
  getMyVouchers(queryParams: QueryCommon): Observable<PaginationModel<VoucherUserModel>> {
    return this.httpService.getRequest<PaginationModel<VoucherUserModel>>(`user/me/vouchers`, true, queryParams);
  }

  getReportDetail(
    expert_id: string, date_from: Date, date_to: Date, voucher_id?: string
  ): Observable<PaginationModel<VoucherReportDetailModel>> {
    return this.httpService.getRequest<PaginationModel<VoucherReportDetailModel>>(
      `${this.url}/report/detail`,
      true,
      { expert_id, date_from, date_to, voucher_id }
    );
  }

  getReportOverview(expert_id: string, date_from: Date, date_to: Date): Observable<BaseModel<VoucherReportOverviewModel>> {
    return this.httpService.getRequest<BaseModel<VoucherReportOverviewModel>>(
      `${this.url}/report/overview`,
      true,
      { expert_id, date_from, date_to }
    );
  }

  /**
   * Get Voucher from id
   * @param id: Voucher id
   */
  getVoucher(id: string): Observable<BaseModel<{ voucher: VoucherModel }>> {
    return this.httpService.getRequest(`${this.url}/${id}`, true);
  }

  /**
   * Get Voucher info from code
   * @param code: Code
   */
  getVoucherInfo(code: string): Observable<BaseModel<VoucherUserModel>> {
    return this.httpService.getRequest(`${this.url}/code/${code}/view`, true);
  }

  /**
   * Give Voucher
   * @param email: Email
   * @param code: Code
   */
  giveVoucher(email: string, code: string): Observable<BaseModel<VoucherUserModel>> {
    return this.httpService.postRequest(`${this.url}/code/${code}/give`, { email }, true);
  }

  /**
   * Accept Voucher
   * @param code: Code
   */
  acceptVoucher(code: string): Observable<void> {
    return this.httpService.postRequest(`${this.url}/code/${code}/accept`, {}, true);
  }

  /**
   * Delete Voucher from id
   * @param id: Voucher Id
   */
  deleteVoucher(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  /**
   * Save Voucher
   * @param id: Id
   * @param params: Voucher info
   */
  saveVoucher(expertId: string, id: string, params: SaveVoucherRequest): Observable<void> {
    if (id) {
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      return this.httpService.postRequest(`${this.url}/expert/${expertId}`, params, true);
    }
  }

  /**
   * Save Note
   * @param id: Id
   * @param note: Note
   */
  saveNote(id: string, note: string): Observable<void> {
    return this.httpService.putRequest(`user/me/vouchers/${id}/update-note`, { personal_message: note }, true);
  }

  /**
   * Buy voucher
   * @param id: Id
   * @param amount: Amount
   */
  buyVoucher(id: string, amount: number): Observable<BaseModel<VoucherUserModel>> {
    return this.httpService.postRequest(`${this.url}/${id}/buy`, { amount }, true);
  }

  /**
   * Change Voucher Status
   * @param code: Code
   * @param isUsed: is used
   */
  changeStatus(code: string, isUsed: boolean): Observable<void> {
    return this.httpService.putRequest(`${this.url}/code/${code}/update-status`, { void: isUsed }, true);
  }
}
