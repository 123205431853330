import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AppState } from '@app/store/app.state';
import { Observable, Subscription } from 'rxjs';
import { SelectItem } from '@app/shared/components/select-list/select-list';
import { TranslationService } from '@app/shared/translation';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@app/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-language-switcher',
  styleUrls: ['./language-switcher.scss'],
  templateUrl: './language-switcher.html',
})
export class LanguageSwitcherComponent implements OnInit, OnDestroy {
  @Select(AppState.locale) locale$: Observable<string>;
  language = '';
  isModalOpen = false;
  selectItems: SelectItem[] = [];
  subscriptions = new Subscription();

  constructor(
    private appService: AppService,
    private router: Router,
    private store: Store,
    private translateService: TranslateService,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.locale$.subscribe((locale) => {
        this.language = locale?.substring(0, 2);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadSelectItems() {
    const languages = this.translationService.getLanguages();

    this.selectItems = languages
      .map((language) => {
        return {
          label: this.translateService.instant('language.' + language) as string,
          value: language,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  openModal() {
    if (!this.selectItems.length)
      this.loadSelectItems();

    this.isModalOpen = true;
  }

  selectedItem(value: unknown) {
    const language = value as string;
    const locale = language + '-' + this.store.selectSnapshot(AppState.country).short;
    const newUrl = this.appService.replaceLocaleRouteSegment(this.router.url, locale, false);
    this.isModalOpen = false;
    this.selectItems = [];
    this.router.navigateByUrl(newUrl);
  }
}