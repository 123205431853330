import {GeolocationModel} from '@app/models/geolocation.model';
import { Environment } from '@environments/enviornment.type';

const cacheTime = 7; // days

export const environment: Environment = {
  production: true,
  environment: 'live',
  apiUrl: 'https://liveapi.rosypet.com/api/',
  appLocales: [
    { language: 'de', datePickerFormat: 'DD.MM.YYYY', timePickerFormat: 'HH:mm' },
    { language: 'en', datePickerFormat: 'MMM DD, YYYY', timePickerFormat: 'HH:mm' },
  ],
  baseUrl: 'https://www.rosypet.com',
  gmapApiKey: 'AIzaSyCZtvgYBNkSynnI05v-jzUmcVY5GOK5eE4',
  googleAnalyticsId: 'G-V8N7JKVJKT',
  cacheTime,
  minimumVoucherAmount: 50,
  maximumVoucherAmount: 1000,
  oneSignalAppId: 'aaef37e9-98e3-46db-93f9-b0ebcbf728a2',
  stripeKey: 'pk_live_51HYpdsAc3KJM6F9CmLADN39hyRNpdBsRAV7aJpQchcmuSgE12rslCAlLmvrjFI6FWjGN9p8PyV2jZBJ0KqDXuLoV007ms7fxYf',
  client_version: '6690951',
  client_timestamp: '1724648714',
  emergencyCallCommission: 0.19,
  languages: [{lang: "be", important: false}, {lang: "bg", important: false}, {lang: "bs", important: false}, {lang: "cs", important: false}, {lang: "cy", important: false}, {lang: "da", important: false}, {lang: "de", important: true}, {lang: "el", important: false}, {lang: "es", important: false}, {lang: "et", important: false}, {lang: "fi", important: false}, {lang: "fr", important: true}, {lang: "hr", important: false}, {lang: "hu", important: false}, {lang: "it", important: true}, {lang: "en", important: true}, {lang: "lt", important: false}, {lang: "mk", important: false}, {lang: "nl", important: false}, {lang: "no", important: false}, {lang: "pl", important: false}, {lang: "pt", important: false}, {lang: "ro", important: false}, {lang: "ru", important: false}, {lang: "sk", important: false}, {lang: "sl", important: false}, {lang: "sq", important: false}, {lang: "sr", important: false}, {lang: "sv", important: false}, {lang: "tr", important: false}, {lang: "uk", important: false}],
  PUSHER_APP_KEY: '536a615394b078545421',
  PUSHER_APP_CLUSTER: 'eu',
  fileUploadMaxSize: 10,
  availableCountries: ['AT', 'CH', 'DE', 'US'],
  mauticBaseUrl: 'https://mautic.rosypet.com',
  sentryDSN: "https://ae4e5846306242fc92c725f589453766@o912957.ingest.sentry.io/5850336",
  micrositeHost: 'rosy.pet',
  socialMedia: [
    { type: 'instagram', url: 'https://www.instagram.com/rosypet_com', country: 'CH' },
    { type: 'facebook', url: 'https://www.facebook.com/profile.php?id=100082204819252', country: 'CH' },
    { type: 'linkedin', url: 'https://www.linkedin.com/company/www-rosypet-com', language: 'de' },
    { type: 'twitter', url: 'https://twitter.com/Rosypet_Schweiz', country: 'CH'},
    { type: 'youtube', url: 'https://www.youtube.com/@rosypet_com5809', language: 'de' },
  ],
};
