import { Directive, ViewContainerRef } from '@angular/core';
import { AppOverlayPopupComponent, PopupOptionsType } from '@app/layout/app-overlay-popup/app-overlay-popup.component';
import { environment } from '@environments/environment';
import { isValid } from 'date-fns';

@Directive({
  selector: '[appOverlayPopup]',
})
export class AppOverlayPopupDirective {
  constructor(private viewContainerRef: ViewContainerRef) {}

  public showPopup(options: PopupOptionsType): void {
    const componentRef = this.viewContainerRef.createComponent(AppOverlayPopupComponent);
    const popup = componentRef.instance;
    popup.options = options;
    popup.popupClosed.subscribe(() => {
      componentRef.destroy();
    });
  }

  public showCookiePopup(): void {
    const cookieAcceptTime = localStorage.getSettingValue('cookie_accept_timestamp') as number;
    const isCookieAccepted = !!cookieAcceptTime && isValid(new Date(cookieAcceptTime));
    if (!isCookieAccepted && environment.environment != 'e2e') {
      setTimeout(() => {
        this.showPopup({
          titleTextKey: 'cookie.title',
          htmlTextKey: 'cookie.description',
          buttons: [
            {
              key: 'accept',
              textKey: 'cookie.accept',
              handler: () => {
                localStorage.saveSettingValue('cookie_accept_timestamp', new Date().valueOf());
                window[`ga-disable-${environment.googleAnalyticsId}`] = false;
              },
            },
          ],
        });
      }, 3000);
    }
  }
}
