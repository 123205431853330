import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export type Devices = MediaDeviceInfo[];

interface CapabilityMediaDeviceInfo extends MediaDeviceInfo {
  getCapabilities(): { facingMode: string[] };
}
@Injectable()
export class DeviceService {
  $devicesUpdated: Observable<Promise<Devices>>;

  private deviceBroadcast = new ReplaySubject<Promise<Devices>>();

  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    if (navigator && navigator.mediaDevices) {
      navigator.mediaDevices.ondevicechange = () => {
        this.deviceBroadcast.next(this.getDeviceOptions());
      };
    }

    this.$devicesUpdated = this.deviceBroadcast.asObservable();
    this.deviceBroadcast.next(this.getDeviceOptions());
  }

  private async isGrantedMediaPermissions() {
    if (navigator && navigator.userAgent && navigator.userAgent.indexOf('Chrome') < 0) {
      return true; // Follows standard workflow for non-Chrome browsers.
    }

    if (navigator && navigator['permissions']) {
      try {
        const result = await navigator['permissions'].query({ name: 'camera' as PermissionName });
        if (result) {
          if (result.state === 'granted') {
            return true;
          } else {
            const isGranted = await new Promise<boolean>((resolve) => {
              result.onchange = (_: Event) => {
                const granted = _.target['state'] === 'granted';
                if (granted) {
                  resolve(true);
                }
              };
            });

            return isGranted;
          }
        }
      } catch (e) {
        // This is only currently supported in Chrome.
        // https://stackoverflow.com/a/53155894/2410379
        return true;
      }
    }

    return false;
  }

  arrayEquals(a: string[], b: string[]): boolean {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
  }

  private async getDeviceOptions(): Promise<Devices> {
    const isGranted = await this.isGrantedMediaPermissions();
    if (navigator && navigator.mediaDevices && isGranted) {
      let devices = await this.tryGetDevices();
      if (devices.every((d) => !d.label)) {
        devices = await this.tryGetDevices();
      }
      return devices.filter((d) => !!d.label);
    }

    return [];
  }

  private async tryGetDevices() {
    if (!isPlatformBrowser(this.platformId)) {
      return [];
    }
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    return (
      ['audioinput', 'audiooutput', 'videoinput']
        .reduce((options, kind) => {
          return (options[kind] = mediaDevices.filter((device) => device.kind === kind));
        }, [] as Devices)
        // remove duplicated group id and facing mode
        .filter((device, index, list) => {
          // flag to remove duplicated group id
          const groupIdFlag =
            !device.groupId || list.findIndex((device1) => device1.groupId === device.groupId) === index;
          // flag to remove duplicated facing mode
          if ((device as CapabilityMediaDeviceInfo).getCapabilities instanceof Function) {
            const facingMode = (device as CapabilityMediaDeviceInfo).getCapabilities().facingMode;
            const facingModeFlag =
              !facingMode ||
              list.findIndex((device1) =>
                this.arrayEquals((device1 as CapabilityMediaDeviceInfo).getCapabilities().facingMode, facingMode)
              ) === index;
            return groupIdFlag && facingModeFlag;
          }
          return groupIdFlag;
        })
    );
  }
}
