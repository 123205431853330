import { Component, OnInit, ViewChild } from '@angular/core';
import { InfoModel } from '@app/models/info/info.model';
import { InfoService, InfoIntroService } from '@app/services';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-info-intro',
  templateUrl: './app-info-intro.html',
  styleUrls: ['./app-info-intro.scss'],
})
export class AppInfoIntroComponent implements OnInit {
  @ViewChild('slides') slides: SwiperComponent;
  title = '';
  key = 'intro';
  infoList: InfoModel[] = [];

  constructor(private infoService: InfoService, private infoIntroService: InfoIntroService) {}

  ngOnInit(): void {
    this.infoIntroService.introSubjectSource$.subscribe((data) => {
      this.key = data[0];
      this.title = data[1];
      this.infoList = data[2];
    });
  }

  getBackgroundPosition(i: number): string {
    if (i % 3 === 0) {
      return 'center';
    } else if (i % 3 === 1) {
      return 'center 10px';
    }
    return 'bottom';
  }

  previousBtnTapped(): void {
    void this.slides.swiperRef.slidePrev();
  }

  nextBtnTapped(i: number): void {
    if (i >= this.infoList.length - 1) {
      this.closeAppIntro();
      return;
    }
    void this.slides.swiperRef.slideNext();
  }

  closeAppIntro(): void {
    const appIntroMenu: HTMLElement = document.querySelector('app-info-intro');
    appIntroMenu.style.display = 'none';
  }
}
