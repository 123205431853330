import { NgModule } from '@angular/core';
import { AppButtonComponent } from '@app/shared/components/base/app-button/app-button';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsComponent } from '@app/shared/components/base/breadcrumbs/breadcrumbs';
import { RouterModule } from '@angular/router';
import { AppLinkButtonComponent } from '@app/shared/components/base/app-link-button/app-link-button';

@NgModule({
  declarations: [
    AppButtonComponent,
    AppLinkButtonComponent,
    BreadcrumbsComponent,
  ],
  imports: [CommonModule, IonicModule, RouterModule, TranslateModule],
  exports: [
    AppButtonComponent,
    AppLinkButtonComponent,
    BreadcrumbsComponent,
  ],
})
export class BaseComponentsModule {}
