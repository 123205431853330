<div class="root" *ngIf="isSpinnerLoading">
  <div class="loading-container">
    <div class="spinner-container">
      <img src="./assets/icon/loading/paw0.svg" class="paw-icon paw-center-icon" alt="loading image" />
      <img src="./assets/icon/loading/paw1.svg" class="paw-icon paw-icon-1 loading-icon" alt="loading image" />
      <img src="./assets/icon/loading/paw2.svg" class="paw-icon paw-icon-2 loading-icon" alt="loading image" />
      <img src="./assets/icon/loading/paw3.svg" class="paw-icon paw-icon-3 loading-icon" alt="loading image" />
      <img src="./assets/icon/loading/paw4.svg" class="paw-icon paw-icon-4 loading-icon" alt="loading image" />
    </div>
    <span class="loading-message" [translate]="loadingMessage" [translateParams]="messageParams"></span>
  </div>
</div>
