import { Injectable } from '@angular/core';
import { BaseModel } from '@app/models/base.model';
import { MissedPetContactModel } from '@app/models/missed-pet/missed-pet.contact.model';
import { MissedPetListingModel, MissedPetListingModel2 } from '@app/models/missed-pet/missed-pet.listing.model';
import { MissedPetModel } from '@app/models/missed-pet/missed-pet.model';
import { PaginationModel } from '@app/models/pagination.model';
import { ListRequest, MissedPetReportRequest } from '@app/models/requests';
import { Observable } from 'rxjs';
import { HttpService, MediaService } from '@app/services';
import { concatAll, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MissedPetService {
  private url = 'missed_pet';

  constructor(private httpService: HttpService, private mediaService: MediaService) {}

  getList(params: ListRequest = {}): Observable<PaginationModel<MissedPetModel>> {
    return this.httpService.getRequest<PaginationModel<MissedPetModel>>(`${this.url}`, true, params);
  }

  getMissedPet(id: string): Observable<MissedPetListingModel> {
    return this.httpService.getRequest<MissedPetListingModel>(`${this.url}/${id}`);
  }

  submitReport(param: MissedPetReportRequest, file: Blob | File): Observable<MissedPetListingModel2> {
    return this.mediaService.uploadTempFile(file).pipe(
      map((response) => {
        const paramWithFile = {
          ...param,
          image: response.data.name,
        };
        return this.httpService.postRequest<MissedPetListingModel2>(`${this.url}`, paramWithFile, true);
      }),
      concatAll()
    );
  }

  contactMissedPet(id: string, params: { message: string }): Observable<BaseModel<MissedPetContactModel>> {
    return this.httpService.postRequest(`${this.url}/${id}/contact`, params, true);
  }

  deleteMissedPet(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }
}
