import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class IntroService {
  private url = 'main';

  constructor(private httpService: HttpService) {}

  requestExpertInformation(email: string): Observable<void> {
    return this.httpService.postRequest(`${this.url}/expert-information`, { email }, true);
  }
}
