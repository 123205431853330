import { Pipe, PipeTransform } from '@angular/core';
import { NumberParser } from '@app/utils';

@Pipe({
  name: 'DecimalFormat',
})
// eslint-disable-next-line
export class DecimalFormatterPipe implements PipeTransform {
  getLocalizedString(value: string | number, fractionSize: number = 2, useGrouping = true): string {
    let numberValue = 0;
    if (value) {
      numberValue = parseFloat(`${value}`);
    }
    return numberValue.toLocaleString(navigator.language, {
      minimumFractionDigits: fractionSize,
      maximumFractionDigits: fractionSize,
      useGrouping,
    });
  }

  transform(value: number | string, fractionSize: number = 2, useGrouping = true): string {
    return this.getLocalizedString(value, fractionSize, useGrouping);
  }

  parse(value: string): number {
    return new NumberParser(navigator.language).parse(value);
  }
}
