import { Injectable } from '@angular/core';
import { ExpertCategoryModel, PaginationModel } from '@app/models';
import { BaseModel } from '@app/models/base.model';
import { ExpertCategoryListingModel } from '@app/models/expert-category/expert.category.listing.model';
import { SaveExpertCategoryRequest } from '@app/models/requests';
import { CacheService, HttpService } from '@app/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExpertCategoryService {
  private url = 'expert_category';
  private CATEGORY_KEY = 'totalCategories';
  private CATEGORY_EXPIRE_KEY = 'category_expire_time';

  constructor(private httpService: HttpService, private cacheService: CacheService) {}

  /**
   * Get all Expert Categories
   * @param forceFetch: Force to reload items from cache (default: false)
   */
  getAll(forceFetch: boolean = false): Observable<ExpertCategoryModel[]> {
    const lang = this.httpService.lang;
    return this.cacheService.getCachedItems<ExpertCategoryModel[]>(
      () => {
        return this.getList().pipe(
          map((response) => {
            return response.data.data;
          })
        );
      },
      `${this.CATEGORY_KEY}_${lang}`,
      `${this.CATEGORY_EXPIRE_KEY}_${lang}`,
      forceFetch
    );
  }

  /**
   * Get Expert Category List
   * @param params: Query Params
   */
  getList(params: Record<string, unknown> = { page_length: 10000 }): Observable<PaginationModel<ExpertCategoryModel>> {
    return this.httpService.getRequest<PaginationModel<ExpertCategoryModel>>(`${this.url}`, false, params);
  }

  /**
   * Get Specific expert category
   * @param id: Id
   */
  getExpertCategory(id: string): Observable<BaseModel<ExpertCategoryListingModel>> {
    return this.httpService.getRequest(`${this.url}/${id}`, true);
  }

  /**
   * Delete Expert Category
   * @param id: Expert Category Id
   */
  deleteExpertCategory(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  /**
   * Store or update expert category
   * @param id: Expert Category Id(if not, it's create)
   * @param params: Params
   */
  saveExpertCategory(id: string, params: SaveExpertCategoryRequest): Observable<void> {
    if (id) {
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      return this.httpService.postRequest(`${this.url}`, params, true);
    }
  }
}
