import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './app-image-viewer.html',
  styleUrls: ['./app-image-viewer.scss'],
})
export class AppImageViewerComponent {
  @Input() class: string;
  @Input() imageURL: string;
  @Input() alt: string;
  @Input() type: string;
  @Input() imageText: string;
  constructor(public router: Router) {}
}
