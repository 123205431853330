import { Deserializable } from '@app/models/deserializable.model';
import { PetSpeciesModel } from '@app/models/pet-species/pet-species.model';

export class UserModel implements Deserializable {
  public id: string;
  public created_at: Date;
  public email: string;
  public firstname: string;
  public lastname: string;
  public active: boolean;
  public currency: string;
  public delete_authorized: boolean;
  public language_id: string;
  public language: string;
  public client_number: string;
  public salutation_id: string;
  public role: string;
  public admin_disabled: boolean;
  public card_brand: string;
  public card_last_four: string;
  public devices: string[];
  public registration_completed: boolean;
  public expert_preset: {
    practice_name: string;
    image_url: string;
  };
  public has_confirmed_payment_method: boolean;
  public interest_species: PetSpeciesModel[];
  public invitations: [];
  public stripe_id: string;
  public trial_ends_at: number;
  public actions_read_until: Date | null;
  public balance: number;
  public bank_account_name: string;
  public hard_delete_at: Date | null;
  public bank_account_number: string;
  public unread_action_count: number;
  public unread_message_count: number;
  public notify_found_pets: boolean;
  public notify_missed_pets: boolean;

  deserialize(input: unknown): this {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Object.assign(this, input);
  }
}
