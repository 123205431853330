import { Injectable } from '@angular/core';
import { ContentListingModel } from '@app/models/content/content.listing.model';
import { ContentModel } from '@app/models/content/content.model';
import { PaginationModel } from '@app/models/pagination.model';
import { HttpService } from '@app/services';
import { Observable } from 'rxjs';
import { ListRequest, SaveContentRequest } from '@app/models/requests';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private url = 'content';

  constructor(private httpService: HttpService) {}

  getList(queryParams: ListRequest): Observable<PaginationModel<ContentModel>> {
    return this.httpService.getRequest<PaginationModel<ContentModel>>(this.url, true, queryParams);
  }

  getContent(key: string | number, lang: string = 'en'): Observable<ContentListingModel> {
    return this.httpService.getRequest(`${this.url}/${key}`, true, { lang });
  }

  deleteContent(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  saveContent(id: string, params: SaveContentRequest): Observable<void> {
    if (id) {
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      return this.httpService.postRequest(`${this.url}`, params, true);
    }
  }
}
