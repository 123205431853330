import { Injectable } from '@angular/core';
import { ConnectionModel } from '@app/models/user/connection.model';
import { AppEventType, EventQueueService, HttpService } from '@app/services';
import { environment } from '@environments/environment';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import Echo from 'laravel-echo-updated';
import Pusher from 'pusher-js';

declare global {
  interface Window {
    Pusher: Pusher;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  echo: Echo;
  errorToast: HTMLIonToastElement;

  constructor(
    private httpService: HttpService,
    private translate: TranslateService,
    private toastController: ToastController,
    private eventQueue: EventQueueService
  ) {}

  public connect(userId: string): void {
    if (this.echo) {
      this.disconnect();
    }
    if (!this.echo) {
      this.init();
    }
    this.joinRoom(userId);
  }

  public disconnect(): void {
    if (this.echo) {
      this.echo.disconnect();
      this.echo = null;
    }
  }

  init(): void {
    this.echo = new Echo({
      broadcaster: 'pusher',
      pusher: Pusher,
      key: environment.PUSHER_APP_KEY,
      cluster: environment.PUSHER_APP_CLUSTER,
      forceTLS: true,
      authorizer: (channel) => {
        return {
          authorize: (socketId, callback) => {
            this.httpService
              .getRequest('broadcasting/auth', true, {
                socket_id: socketId as string,
                channel_name: channel.name as string,
              })
              .subscribe(
                (data) => {
                  callback(false, data);
                },
                (error) => {
                  callback(true, error);
                }
              );
          },
        };
      },
    });
    this.echo.connector.pusher.connection
      .bind('connected', () => {
        if (this.errorToast) {
          this.errorToast.dismiss();
          this.errorToast = null;
        }
      })
      .bind('offline', () => {
        this.showSocketConnectError();
      })
      .bind('failed', () => {
        this.showSocketConnectError();
      })
      .bind('unavailable', () => {
        this.showSocketConnectError();
      });
  }

  joinRoom(userId: string): void {
    this.echo
      .channel(`presence-${userId}`)
      .listenChunked(AppEventType.MessageReceived, (data) => {
        this.eventQueue.dispatch(AppEventType.MessageReceived, data);
      })
      .listen(AppEventType.ActionReceived, (data) => {
        this.eventQueue.dispatch(AppEventType.ActionReceived, data);
      })
      .listen(AppEventType.UserUpdated, (data) => {
        this.eventQueue.dispatch(AppEventType.UserUpdated, data);
      })
      .listen(AppEventType.ExpertUpdated, (data) => {
        this.eventQueue.dispatch(AppEventType.ExpertUpdated, data);
      })
      .listen(AppEventType.PetOwnerUpdated, (data) => {
        this.eventQueue.dispatch(AppEventType.PetOwnerUpdated, data);
      })
      .listen(AppEventType.ConnectionCreated, (data) => {
        const connection: ConnectionModel = new ConnectionModel();
        Object.assign(connection, data);
        this.eventQueue.dispatch(AppEventType.ConnectionCreated, connection);
      })
      .listen(AppEventType.ConnectionUpdated, (data) => {
        const connection: ConnectionModel = new ConnectionModel();
        Object.assign(connection, data);
        this.eventQueue.dispatch(AppEventType.ConnectionUpdated, connection);
      })
      .listen(AppEventType.FeatureStatusChanged, (data) => {
        this.eventQueue.dispatch(AppEventType.FeatureStatusChanged, data);
      })
      .listen(AppEventType.ProductSubscribed, (data) => {
        this.eventQueue.dispatch(AppEventType.ProductSubscribed, data);
      })
      .listen(AppEventType.ProductUnSubscribed, (data) => {
        this.eventQueue.dispatch(AppEventType.ProductUnSubscribed, data);
      })
      .listen(AppEventType.PetCreated, (data) => {
        this.eventQueue.dispatch(AppEventType.PetCreated, data);
      })
      .listen(AppEventType.PetUpdated, (data) => {
        this.eventQueue.dispatch(AppEventType.PetUpdated, data);
      })
      .listen(AppEventType.PetDeleted, (data) => {
        this.eventQueue.dispatch(AppEventType.PetDeleted, data);
      })
      .listen(AppEventType.IncomingCall, (data) => {
        this.eventQueue.dispatch(AppEventType.IncomingCall, data);
      })
      .listen(AppEventType.JoinCall, (data) => {
        this.eventQueue.dispatch(AppEventType.JoinCall, data);
      })
      .listen(AppEventType.RejectedCall, (data) => {
        this.eventQueue.dispatch(AppEventType.RejectedCall, data);
      })
      .listen(AppEventType.EndCall, (data) => {
        this.eventQueue.dispatch(AppEventType.EndCall, data);
      })
      .listen(AppEventType.InvoiceStatusChanged, (data) => {
        this.eventQueue.dispatch(AppEventType.InvoiceStatusChanged, data);
      })
      .error(() => {
        this.showSocketConnectError();
      });
  }

  async showSocketConnectError(): Promise<void> {
    this.errorToast = await this.toastController.create({
      message: this.translate.instant('error_messages.socket_connect') as string,
      duration: 60 * 60 * 1000,
      color: 'danger',
    });
    await this.errorToast.present();
  }
}
