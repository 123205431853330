import { TemplateTextModel } from '@app/models/template/template.text.model';

type TemplateType = 'action' | 'mail' | 'push' | 'print' | 'sms' | 'social' | 'whatsapp';
export class TemplateModel {
  id: string;
  name: string;
  basetemplate = '';
  subject: string;
  body: string;
  image_url: string;
  title: string;
  type: TemplateType;
  url: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  sample_data: object;
  texts: TemplateTextModel[] = [];
}
