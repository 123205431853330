import { Injectable } from '@angular/core';

import { SalutationModel } from '@app/models';
import { PaginationModel } from '@app/models/pagination.model';
import { SaveSalutationRequest } from '@app/models/requests';
import { SalutationListingModel } from '@app/models/salutation/salutation.listing.model';
import { CacheService, HttpService } from '@app/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SalutationService {
  private url = 'salutation';
  private SALUTATION_KEY = 'SALUTATION_KEY';
  private SALUTATION_EXPIRE_KEY = 'SALUTATION_EXPIRE_TIME';

  constructor(private httpService: HttpService, private cacheService: CacheService) {}

  getAll(): Observable<SalutationModel[]> {
    const lang = this.httpService.lang;
    return this.cacheService.getCachedItems<SalutationModel[]>(
      () => {
        return this.getList().pipe(
          map((response) => {
            return response.data.data;
          })
        );
      },
      `${this.SALUTATION_KEY}_${lang}`,
      `${this.SALUTATION_EXPIRE_KEY}_${lang}`
    );
  }

  getList(queryParams: Record<string, unknown> = {}): Observable<PaginationModel<SalutationModel>> {
    return this.httpService.getRequest<PaginationModel<SalutationModel>>(`${this.url}`, false, queryParams);
  }

  getSalutation(id: string): Observable<SalutationListingModel> {
    return this.httpService.getRequest(`${this.url}/${id}`, true);
  }

  deleteSalutation(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  saveSalutation(id: string, params: SaveSalutationRequest): Observable<void> {
    if (id) {
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      return this.httpService.postRequest(`${this.url}`, params, true);
    }
  }
}
