import { Injectable } from '@angular/core';
import { QueryCommon } from '@app/models/app/query-common';

import { PaginationModel } from '@app/models/pagination.model';
import { PetBreedListingModel } from '@app/models/pet-breed/pet-breed.listing.model';
import { PetBreedModel } from '@app/models/pet-breed/pet-breed.model';
import { SavePetBreedRequest } from '@app/models/requests';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

interface GetPetBreedList extends QueryCommon {
  lang: string;
  'pet_species[]': string[];
}

@Injectable({
  providedIn: 'root',
})
export class PetBreedService {
  private url = 'pet_breed';

  constructor(private httpService: HttpService) {}

  getList(queryParams: Partial<GetPetBreedList> = {}): Observable<PaginationModel<PetBreedModel>> {
    return this.httpService.getRequest<PaginationModel<PetBreedModel>>(`${this.url}`, true, queryParams);
  }

  getPetBreed(id: string): Observable<PetBreedListingModel> {
    return this.httpService.getRequest(`${this.url}/${id}`, true);
  }

  deletePetBreed(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  savePetBreed(id: string, params: SavePetBreedRequest): Observable<void> {
    if (id) {
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      return this.httpService.postRequest(`${this.url}`, params, true);
    }
  }
}
