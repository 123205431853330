<ng-content></ng-content>
<div *ngIf="isOpen" class="tw-fixed tw-w-full tw-h-full tw-flex tw-flex-row">
  <div #background class="tw-flex-grow tw-bg-black tw-cursor-pointer" style="opacity: 0" (click)="closeBtnTapped()">
  </div>
  <div #sideMenu class="app-sidemenu tw-h-full">
    <div class="tw-flex tw-flex-col tw-h-full">
      <ion-content id="menu-main-content" class="tw-grow">
        <div id="close-button-row">
          <button
            id="menu-desktop-close-button"
            class="tw-m-1 menu-close-button"
            (click)="closeBtnTapped()"
          >
            <ion-icon name="close"></ion-icon>
          </button>
        </div>
        <div id="user-info-view" *ngIf="userModel?.id && userModel?.role !== 'admin'" (click)="openProfile()">
          <app-image-viewer [imageURL]="userImageUrl$ | async" [imageText]="userFullName$ | async"></app-image-viewer>
          <ion-label id="user-name-label"> {{ userFullName$ | async }} </ion-label>
          <app-share-button
            *ngIf="expertModel && !petOwnerModel"
            iconColor="white"
            viewTitle="share.expert_view_title"
            [shareTitle]="expertModel.practice_name"
            shareDescription="share.expert_share_title"
            [shareURL]="getExpertUrl()"
          >
          </app-share-button>
          <app-share-button
            *ngIf="petOwnerModel"
            iconColor="white"
            viewTitle="share.pet_owner_view_title"
            [shareTitle]="petOwnerModel.username"
            shareDescription="share.pet_owner_share_title"
            [shareURL]="getPetOwnerUrl()"
          >
          </app-share-button>
        </div>
        <div id="side-menu-list" class="tw-grow">
          <div class="menu-divider"></div>
          <ng-container *ngFor="let menuItem of menuItems; let i = index">
            <div
              *ngIf="showMenuItem(menuItem) | async"
              (click)="menuItemTapped(menuItem, i)"
              class="side-menu-row"
              [ngClass]="selectedPath === menuItem.url ? 'selected' : selectedMenuIndex === i && 'submenu-active'"
            >
              <div class="side-menu-content">
                <span class="side-menu-label">{{ menuItem.title | translate }}</span>
                <ion-icon *ngIf="menuItem.icon" class="side-menu-icon" [src]="menuItem.icon"></ion-icon>
                <div class="tw-flex-1"></div>
                <ion-icon
                  *ngIf="menuItem.subMenus && menuItem.subMenus.length > 0"
                  class="right-icon"
                  [src]="'./assets/icon/' + (selectedMenuIndex === i ? 'arrow_up.svg' : 'arrow_down.svg')"
                >
                </ion-icon>
              </div>
              <ion-list *ngIf="menuItem.subMenus && selectedMenuIndex === i">
                <ng-container *ngFor="let subMenu of menuItem.subMenus">
                  <ion-item
                    *ngIf="showMenuItem(subMenu) | async"
                    lines="none"
                    (click)="subMenuItemTapped(subMenu)"
                    [ngClass]="selectedPath === subMenu.url ? 'submenu-selected' : ''"
                  >
                    <span class="submenu-title">{{ subMenu.title | translate }}</span>
                  </ion-item>
                </ng-container>
              </ion-list>
              <div class="menu-item-divider"></div>
            </div>
          </ng-container>

          <div class="side-menu-row">
            <div class="side-menu-content">
              <app-country-switcher></app-country-switcher>
              <app-language-switcher></app-language-switcher>
              <div class="tw-flex-1"></div>
            </div>
            <div class="menu-item-divider"></div>
          </div>

        </div>
      </ion-content>

      <div id="side-menu-footer">
        <app-social-media></app-social-media>
      </div>
    </div>
  </div>
</div>
