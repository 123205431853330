export class LocalStorageHelper {
  public static init(localStorage: Storage): void {
    Storage.prototype.getSettingValue = (key: string) => LocalStorageHelper.getSettingValue(localStorage, key);
    Storage.prototype.saveSettingValue = (key: string, value: unknown) =>
      LocalStorageHelper.saveSettingValue(localStorage, key, value);
    Storage.prototype.clearAllItems = () => LocalStorageHelper.clearAllItems(localStorage);
  }

  public static getSettingValue = (localStorage: Storage, key: string): unknown => {
    const settings = localStorage.getItem('settings');
    if (!settings) {
      return;
    }
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const settingsJson = JSON.parse(settings);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return settingsJson[key];
    } catch (e) {
      return;
    }
  };

  public static saveSettingValue(localStorage: Storage, key: string, value: unknown): void {
    const settings = localStorage.getItem('settings');
    let settingsJson = {};
    if (settings) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        settingsJson = JSON.parse(settings);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`Error while parsing settings JSON ${String(e)}`);
      }
    }
    if (!settingsJson) {
      settingsJson = {};
    }
    settingsJson[key] = value;
    localStorage.setItem('settings', JSON.stringify(settingsJson));
  }

  public static clearAllItems(localStorage: Storage): void {
    const settings = localStorage.getItem('settings');
    localStorage.clear();
    localStorage.setItem('settings', settings);
  }
}
