import { CountryModel, ExpertModel, ISpinnerState, IToast, PetOwnerModel, UserModel } from '@app/models';
import { ConnectionModel } from '@app/models/user/connection.model';
import { UserProductModel } from '@app/models/product/userProductModel';

export enum AppFields {
  App = 'app',
  Connections = 'app-connections',
  Country = 'app-country',
  Experts = 'app-experts',
  Features = 'app-features',
  Language = 'app-language',
  Locale = 'app-locale',
  PetOwner = 'app-petowner',
  Products = 'app-products',
  Salutations = 'app-salutations',
  SelectedExpertId = 'app-expert-id',
  Spinner = 'app-spinner',
  Toast = 'app-toast',
  User = 'app-user',
  UserProducts = 'app-user-products',
}

export interface AppStateModel {
  [AppFields.Connections]: ConnectionModel[];
  [AppFields.Country]: CountryModel;
  [AppFields.Experts]: ExpertModel[];
  [AppFields.Features]: string[] | null;
  [AppFields.Language]: string;
  [AppFields.Locale]: string;
  [AppFields.PetOwner]: PetOwnerModel;
  [AppFields.Products]: string[];
  [AppFields.SelectedExpertId]: string;
  [AppFields.Spinner]: ISpinnerState;
  [AppFields.Toast]: IToast;
  [AppFields.User]: UserModel;
  [AppFields.UserProducts]: UserProductModel[];
}

export enum AppTypes {
  AddConnection = '[User] add connection record',
  AddSubscription = '[User] add subscription',
  ChangeFeatureStatus = '[User] change feature flag status',
  GetUser = '[User] get user',
  IncreaseActionBadgeCount = '[User] increase action badge count',
  IncreaseMessageBadgeCount = '[User] increase message badge count',
  LoadUserInfo = '[User] load user info',
  LogoutUser = '[User] log out user',
  RemoveSubscription = '[User] remove subscription',
  ToggleSubscription = '[Subscription] toggle subscription',
  UpdateConnection = '[User] update connection record',
  UpdateConnectionStatus = '[User] update connection status',
  UpdateCountry = '[Country] update country',
  UpdateExpert = '[User] update expert',
  UpdateFeatures = '[User] update feature flags',
  UpdateLanguage = '[Language] update language',
  UpdateLocale = '[Locale] update locale',
  UpdatePetOwner = '[User] update pet owner',
  UpdateSpinner = '[Spinner] update spinner',
  UpdateToast = '[Toast] update toast',
  UpdateUnreadActionCountAction = '[User] update unread action count action',
  UpdateUser = '[User] update user',
  UpdateUserInfo = '[User] update user info',
  UpdateUserObject = '[User] update user from socket event',
}
