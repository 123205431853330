import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';

import {
  EVENT_PLACEHOLDER_IMAGE,
  HAZARD_PLACEHOLDER_IMAGE,
  MISSED_PET_PLACEHOLDER_IMAGE,
  NEWS_PLACEHOLDER_IMAGES,
  OFFER_PLACEHOLDER_IMAGE,
} from '@app/models';
import { AppService } from '@app/services';

@Component({
  selector: 'app-image-shell',
  templateUrl: './image-shell.component.html',
  styleUrls: ['./image-shell.component.scss'],
})
export class ImageShellComponent implements AfterViewChecked, OnDestroy {
  @ViewChild('image', { static: false }) image!: ElementRef;
  resizeObserver: ResizeObserver = null;
  resizeObserverTarget: HTMLElement;

  _src = '';
  _alt = '';
  _internalSrc = '';
  _loadingFailed = false;
  _placeholderFailed = false;
  
  @HostBinding('class.img-loaded') imageLoaded = false;
  @Input() fitImage: 'cover' | 'contain' = 'cover';
  @Input() type: string;
  @Input() imageClass: string;
  @Input() imageText: string;
  @Input() alt: string;

  @Input()
  set src(val: string) {
    this._loadingFailed = !val;
    this._placeholderFailed = false;
    this._src = val !== undefined && val !== null ? val : '';
    this._internalSrc = this._src;

    // Show loading indicator
    // When using SSR (Server Side Rendering), avoid the loading animation while the image resource is being loaded
    if (this.appService.isBrowser()) {
      this.imageLoaded = true;
    } else {
      this.imageLoaded = false;
    }
  }

  constructor(private appService: AppService) {
    if (this.appService.isBrowser()) {
      this.resizeObserver = new ResizeObserver(this.adjustImagePosition);
    }
  }

  ngAfterViewChecked(): void {
    if (this.appService.isBrowser() && this.fitImage == 'cover' && this.image && !this.resizeObserverTarget != this.image.nativeElement) {
      if (this.resizeObserverTarget) {
        this.resizeObserver.unobserve(this.resizeObserverTarget.parentElement);
        this.resizeObserver.unobserve(this.resizeObserverTarget);
      }
      this.resizeObserver.observe(this.image.nativeElement.parentElement as HTMLElement);
      this.resizeObserver.observe(this.image.nativeElement as HTMLElement);
      this.resizeObserverTarget = this.image.nativeElement as HTMLElement;
      this.adjustImagePosition();
    } else if (this.resizeObserverTarget) {
      this.resizeObserver.unobserve(this.image.nativeElement.parentElement as HTMLElement);
      this.resizeObserver.unobserve(this.image.nativeElement as HTMLElement);
      this.resizeObserverTarget = null;
    }
  }

  ngOnDestroy(): void {
    if (this.appService.isBrowser() && this.resizeObserverTarget) {
      this.resizeObserver.unobserve(this.resizeObserverTarget.parentElement);
      this.resizeObserver.unobserve(this.resizeObserverTarget);
    }
  }

  private adjustImagePosition(): void {
    if (!this.image) return;

    const parentWidth = this.image.nativeElement.parentElement.offsetWidth as number;
    const parentHeight = this.image.nativeElement.parentElement.offsetHeight as number;
    const imageWidth = this.image.nativeElement.width as number;
    const imageHeight = this.image.nativeElement.height as number;

    if (imageWidth > parentWidth) {
      const marginLeft = (imageWidth - parentWidth) / 2;
      this.image.nativeElement.style.marginLeft = `-${marginLeft}px`;
    } else {
      this.image.nativeElement.style.marginLeft = '0';
    }

    if (imageHeight > parentHeight) {
      const marginTop = (imageHeight - parentHeight) / 2;
      this.image.nativeElement.style.marginTop = `-${marginTop}px`;
    } else {
      this.image.nativeElement.style.marginTop = '0';
    }
  }

  loaded() {
    this.imageLoaded = true;
  }

  imageError() {
    if (this._loadingFailed) {
      this._placeholderFailed = true;
    } else {
      this._loadingFailed = true;
      const placeholder = this.getPlaceholder();
      if (placeholder) {
        this._internalSrc = placeholder;
      } else {
        this._placeholderFailed = true;
      }
    }
  }

  _imageLoaded(): void {
    return;
    this.imageLoaded = true;
  }

  getTextColor(): string {
    if (!this.imageText) {
      return '';
    }
    const firstString = this.imageText.substr(0, 1).toUpperCase();
    let textColor: string;
    switch (firstString) {
      case 'A':
      case 'B':
      case 'C':
      case 'D':
      case 'E':
      case 'F':
      case 'G':
      case 'I':
        textColor = '#FF6A82';
        break;
      case 'J':
      case 'K':
      case 'L':
        textColor = '#FFA11D';
        break;
      case 'M':
      case 'N':
      case 'O':
        textColor = '#81C2FF';
        break;
      case 'P':
      case 'Q':
      case 'R':
        textColor = '#0F67AE';
        break;
      case 'S':
      case 'T':
      case 'U':
        textColor = '#916AFF';
        break;
      case 'V':
      case 'W':
      case 'X':
        textColor = '#0FAE58';
        break;
      case 'Y':
      case 'Z':
        textColor = '#A5454A';
        break;
      default:
        textColor = '#FF6A82';
        break;
    }
    return textColor;
  }

  getPlaceholder() {
    switch (this.type) {
      case 'event':
        return EVENT_PLACEHOLDER_IMAGE;
      case 'hazard':
        return HAZARD_PLACEHOLDER_IMAGE;
      case 'missed_pet':
        return MISSED_PET_PLACEHOLDER_IMAGE;
      case 'news':
          return NEWS_PLACEHOLDER_IMAGES[Math.floor(Math.random() * (NEWS_PLACEHOLDER_IMAGES.length - 1))];
      case 'offer':
          return OFFER_PLACEHOLDER_IMAGE;
    }
    return null;
  }
  
  onStateChange(event: { reason: string }): void {
    return;
    if (event.reason === 'loading-failed') {
      this._loadingFailed = true;
    }
  }
}
