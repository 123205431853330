import { Injectable } from '@angular/core';
import { JoinRoomListingModel } from '@app/models/call/join.room.listing.model';
import { RoomListingModel } from '@app/models/call/room.listing.model';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';
import { RoomDetailModel } from '@app/models/call/room.detail.model';

@Injectable({
  providedIn: 'root',
})
export class CallService {
  private url = 'call';

  constructor(private httpService: HttpService) {}

  createRoom(params: { expert_id: string }): Observable<RoomListingModel> {
    return this.httpService.postRequest<RoomListingModel>(`${this.url}/room/create`, params, true);
  }

  joinRoom(roomId: string): Observable<JoinRoomListingModel> {
    return this.httpService.postRequest<JoinRoomListingModel>(`${this.url}/room/join`, { room_id: roomId }, true);
  }

  getRoom(roomId: string): Observable<RoomDetailModel> {
    return this.httpService.getRequest<RoomDetailModel>(`${this.url}/${roomId}`);
  }

  rejectCall(roomId: string): Observable<void> {
    return this.httpService.postRequest(`${this.url}/${roomId}/reject`, { room_id: roomId }, true);
  }

  closeRoom(roomId: string): Observable<void> {
    return this.httpService.postRequest(`${this.url}/room/close`, { room_id: roomId }, true);
  }
}
