import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { environment } from '@environments/environment';
import { AppEventType, EventQueueService } from '../services';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, filter, retryWhen, tap } from 'rxjs/operators';

export const retryCount = 5;
export const retryWaitMilliSeconds = 1000;

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
  constructor(private eventQueue: EventQueueService) {}

  intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(httpRequest).pipe(
      retryWhen((errors) =>
        errors.pipe(
          concatMap((error, count) => {
            if (count < retryCount && (error.status === 0 || (error.status >= 500 && error.status <= 599))) {
              return of(error);
            }
            return throwError(error);
          }),
          delay(retryWaitMilliSeconds)
        )
      ),
      filter((event) => event instanceof HttpResponse),
      tap((response: HttpResponse<unknown>) => {
        if (
          !isDevMode() &&
          response.headers.get('Client-Version') &&
          response.headers.get('Client-Version') !== environment.client_version
        ) {
          this.eventQueue.dispatch(AppEventType.UpdateAvailable, true);
        }
      })
    );
  }
}
