import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MicrositeListingModel } from '@app/models/microsite/microsite.listing.model';
import { HttpService } from '@app/services/http.service';
import { MicrositeModel } from '@app/models/microsite/microsite.model';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { AddressModel, ExpertCategoryModel, ExpertModel, PetSpeciesModel } from '@app/models';
import { TranslateService } from '@ngx-translate/core';
import { Time } from '@app/utils';
import { CalendarAvailabilityModel } from '@app/models/expert/calendar-availability.model';

@Injectable({
  providedIn: 'root',
})
export class MicrositeService {
  private url = 'microsite';

  constructor(private httpService: HttpService, private translate: TranslateService) {}

  getDemoExpert(): ExpertModel {
    const address = new AddressModel();
    address.street = this.translate.instant('microsite.demo.expert.address.street') as string;
    address.city = this.translate.instant('microsite.demo.expert.address.city') as string;
    address.zip = this.translate.instant('microsite.demo.expert.address.postal_code') as string;

    const categories: ExpertCategoryModel[] = [];
    String(this.translate.instant('microsite.demo.expert.categories')).split(',')
      .map((categoryName) => {
        const category = new ExpertCategoryModel();
        category.name = categoryName;
        category.type = 'business_type';
        categories.push(category);
      });

    const speciesList: PetSpeciesModel[] = [];
    String(this.translate.instant('microsite.demo.expert.pet_species')).split(',')
      .map((speciesName) => {
        const species = new PetSpeciesModel();
        species.name_plural = speciesName;
        speciesList.push(species);
      });

    const businessHours: CalendarAvailabilityModel[] = [
      { time_from: new Time('08:00'), time_to: new Time('12:00'), weekday: 1 },
      { time_from: new Time('13:30'), time_to: new Time('16:30'), weekday: 1 },
      { time_from: new Time('08:00'), time_to: new Time('12:00'), weekday: 2 },
      { time_from: new Time('13:30'), time_to: new Time('16:30'), weekday: 2 },
      { time_from: new Time('08:00'), time_to: new Time('12:00'), weekday: 3 },
      { time_from: new Time('13:30'), time_to: new Time('16:30'), weekday: 3 },
      { time_from: new Time('08:00'), time_to: new Time('12:00'), weekday: 4 },
      { time_from: new Time('13:30'), time_to: new Time('16:30'), weekday: 4 },
      { time_from: new Time('08:00'), time_to: new Time('12:00'), weekday: 5 },
      { time_from: new Time('13:30'), time_to: new Time('16:30'), weekday: 5 },
    ];

    const videoTimes: CalendarAvailabilityModel[] = [
      { time_from: new Time('10:00'), time_to: new Time('12:00'), weekday: 1 },
      { time_from: new Time('16:00'), time_to: new Time('18:00'), weekday: 1 },
      { time_from: new Time('08:00'), time_to: new Time('10:00'), weekday: 4 },
    ];

    const emergencyTimes: CalendarAvailabilityModel[] = [
      { time_from: new Time('07:00'), time_to: new Time('12:00'), weekday: 1 },
      { time_from: new Time('13:00'), time_to: new Time('16:30'), weekday: 1 },
      { time_from: new Time('07:00'), time_to: new Time('12:00'), weekday: 2 },
      { time_from: new Time('13:00'), time_to: new Time('16:30'), weekday: 2 },
      { time_from: new Time('07:00'), time_to: new Time('12:00'), weekday: 3 },
      { time_from: new Time('13:00'), time_to: new Time('16:30'), weekday: 3 },
      { time_from: new Time('07:00'), time_to: new Time('12:00'), weekday: 4 },
      { time_from: new Time('13:00'), time_to: new Time('16:30'), weekday: 4 },
      { time_from: new Time('07:00'), time_to: new Time('12:00'), weekday: 5 },
      { time_from: new Time('13:00'), time_to: new Time('16:30'), weekday: 5 },
      { time_from: new Time('09:00'), time_to: new Time('12:00'), weekday: 6 },
      { time_from: new Time('13:00'), time_to: new Time('20:00'), weekday: 6 },
      { time_from: new Time('09:00'), time_to: new Time('12:00'), weekday: 7 },
      { time_from: new Time('13:00'), time_to: new Time('20:00'), weekday: 7 },
    ];

    const expert = new ExpertModel();
    expert.address = address;
    expert.business_hours = businessHours;
    expert.expert_categories = categories;
    expert.expert_emergency_times = emergencyTimes;
    expert.expert_video_times = videoTimes;
    expert.social_links = {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      linkedin: 'https://www.linkedin.com',
    };
    expert.image_url = environment.baseUrl + '/assets/img/microsite/' + String(this.translate.instant('microsite.demo.logo_image'));
    expert.pet_species = speciesList;
    expert.practice_name = this.translate.instant('microsite.demo.expert.practice_name') as string;
    expert.website = 'https://demo.rosy.pet';
    return expert;
  }

  getDemoMicrosite(): MicrositeModel {
    return {
      data: { cover_image: environment.baseUrl + '/assets/img/placeholder/event_placeholder.webp' },
      items: [
        { order: 1, type: 'about', data: {
            menuTitle: this.translate.instant('microsite.edit.type.about') as string,
          }
        },
        {
          order: 2, type: 'team', data: {
            menuTitle: this.translate.instant('microsite.edit.type.team') as string,
            team: [
              {
                name: this.translate.instant('microsite.demo.team.member1') as string,
                title: this.translate.instant('microsite.demo.team.member1_title') as string,
                image: environment.baseUrl + '/assets/img/microsite/team1.webp',
              },
              {
                name: this.translate.instant('microsite.demo.team.member2') as string,
                title: this.translate.instant('microsite.demo.team.member2_title') as string,
                image: environment.baseUrl + '/assets/img/microsite/team2.webp',
              },
              {
                name: this.translate.instant('microsite.demo.team.member3') as string,
                title: this.translate.instant('microsite.demo.team.member3_title') as string,
                image: environment.baseUrl + '/assets/img/microsite/team3.webp',
              },
              {
                name: this.translate.instant('microsite.demo.team.member4') as string,
                title: this.translate.instant('microsite.demo.team.member4_title') as string,
                image: environment.baseUrl + '/assets/img/microsite/team4.webp',
              },
            ],
          },
        },
        {
          order: 3,
          type: 'content',
          data: {
            menuTitle: null,
            title: this.translate.instant('microsite.demo.content.title') as string,
            text: this.translate.instant('microsite.demo.content.text') as string,
            image: environment.baseUrl + '/assets/img/microsite/welcome.webp',
          },
        },
        {
          order: 4,
          type: 'contact',
          data: {
            menuTitle: this.translate.instant('microsite.edit.type.contact') as string,
          }
        }
      ]
    };
  }

  getMicrosite(expertId: string): Observable<MicrositeListingModel> {
    return this.httpService.getRequest<MicrositeListingModel>(`${this.url}/${expertId}`).pipe(
      map((data) => {
        if (data.data?.microsite?.items) {
          data.data.microsite.items = data.data.microsite.items.sort((a, b) => a.order - b.order);
        }
        return data;
      })
    );
  }

  getBySlug(slug: string): Observable<MicrositeListingModel> {
    return this.httpService.getRequest<MicrositeListingModel>(`${this.url}/slug/${slug}`);
  }

  submitContactForm(expertId: string, data: unknown): Observable<void> {
    return this.httpService.postRequest<void>(`${this.url}/contact/` + expertId, data);
  }

  upsert(microsite: MicrositeModel): Observable<MicrositeModel> {
    return this.httpService.postRequest<MicrositeModel>(`${this.url}/${microsite.expert_id}`, microsite);
  }
}
