import { IMenu } from './menu.interface';

export const NO_IMAGE_URL = '/assets/img/placeholder/placeholder.png';
export const NEWS_PLACEHOLDER_IMAGES = [
  '/assets/img/news-feed/placeholder1.png',
  '/assets/img/news-feed/placeholder2.png',
];
export const EVENT_PLACEHOLDER_IMAGE = '/assets/img/placeholder/event_placeholder.webp';
export const HAZARD_PLACEHOLDER_IMAGE = '/assets/img/placeholder/hazard_placeholder.webp';
export const MISSED_PET_PLACEHOLDER_IMAGE = '/assets/img/placeholder/missed_pet_placeholder.webp';
export const OFFER_PLACEHOLDER_IMAGE = '/assets/img/placeholder/offer_placeholder.webp';

export const IntroFooterMenus: IMenu[] = [
  { title: 'menu.home', url: '/', icon: 'pet-owner/home_icon.svg' },
  { title: 'menu.short-hazard', url: '/hazards', icon: 'pet-owner/hazards_icon.svg' },
  { title: 'menu.news', url: '/news', icon: 'pet-owner/news_icon.svg' },
  { title: 'menu.video', url: '/videos', icon: 'pet-owner/videos_icon.svg' },
  { title: 'menu.short-missed-pets', url: '/missed-pet', icon: 'pet-owner/missing_icon.svg' },
  { title: 'menu.events', url: '/events', icon: 'pet-owner/events_icon.svg' },
  { title: 'menu.marketplace', url: '/offers', icon: 'pet-owner/market_icon.svg' },
];

export const PetExpertFooterMenus: IMenu[] = [
  { title: 'menu.home', url: '/', icon: 'pet-owner/home_icon.svg' },
  { title: 'menu.profile.my_profile', url: '/expert/profile', icon: 'pet-expert/my_profile.svg' },
  { title: 'menu.my_pet_owners', url: '/expert/petowner', icon: 'pet-expert/my_pet_owners.svg' },
  { title: 'menu.message_center', url: '/message-center', icon: 'pet-expert/message_center.svg' },
  { title: 'menu.analytics', url: '/expert/analytics', icon: 'pet-expert/analytics.svg' },
  { title: 'menu.vouchers', url: '/expert/voucher', icon: 'pet-expert/vouchers.svg' },
  { title: 'menu.coupons', url: '/expert/coupon', icon: 'pet-expert/coupon.svg' },
];

export const AdminFooterMenus: IMenu[] = [
  { title: 'menu.dashboard', url: '/admin/dashboard', icon: 'pet-owner/home_icon.svg' },
  { title: 'admin.dashboard.templates', url: '/admin/templates', icon: 'pet-expert/my_profile.svg' },
  { title: 'admin.dashboard.contents', url: '/admin/contents', icon: 'pet-expert/my_profile.svg' },
  { title: 'admin.dashboard.payments', url: '/admin/payments', icon: 'pet-expert/my_profile.svg' },
  { title: 'admin.dashboard.infos', url: '/admin/info', icon: 'pet-expert/my_profile.svg' },
  { title: 'admin.dashboard.feed_channel', url: '/admin/feed-channel', icon: 'pet-expert/my_profile.svg' },
  { title: 'admin.dashboard.users', url: '/admin/users', icon: 'pet-owner/expert_icon.svg' },
  { title: 'admin.dashboard.pet_owners', url: '/admin/pet-owner', icon: 'pet-owner/pet_owner_icon.svg' },
  { title: 'admin.dashboard.experts', url: '/admin/expert', icon: 'pet-owner/expert_icon.svg' },
  { title: 'admin.dashboard.expert_categories', url: '/admin/expert-category', icon: 'pet-owner/expert_icon.svg' },
  { title: 'admin.dashboard.salutation', url: '/admin/salutation', icon: 'pet-owner/expert_icon.svg' },
  { title: 'admin.dashboard.products', url: '/admin/products', icon: 'pet-owner/expert_icon.svg' },
  { title: 'admin.dashboard.pet_breed', url: '/admin/pet-breed', icon: 'pet-owner/expert_icon.svg' },
  { title: 'admin.dashboard.pet_species', url: '/admin/pet-species', icon: 'pet-expert/vouchers.svg' },
  { title: 'admin.dashboard.media', url: '/admin/media', icon: 'pet-owner/expert_icon.svg' },
  { title: 'admin.dashboard.link-qr', url: '/admin/link-qr', icon: 'pet-owner/expert_icon.svg' },
];

export const HeaderMenuButtons: IMenu[] = [
  { url: '/emergency', icon: 'emergency-animated.svg' },
  { url: '/action-center', icon: 'menu/info_sky_icon.svg' },
  { url: '/message-center', icon: 'menu/chat_sky_icon.svg' },
  { url: '/search', icon: 'menu/search_sky_icon.svg' },
];

export const UnAuthorizedHeaderButtons: IMenu[] = [
  { url: '/emergency', icon: 'emergency-animated.svg' },
  { url: '/search', icon: 'menu/search_icon.svg' },
];

export const IntroMenus: IMenu[] = [
  { title: 'common.log_in', url: '/auth/login', subMenus: [] },
  { title: 'common.register', url: '/onboarding/select', subMenus: [] },
  {
    title: 'menu.information.title',
    subMenus: [
      { title: 'menu.information.privacy', url: '/content/privacy' },
      { title: 'menu.information.terms', url: '/content/terms' },
      { title: 'menu.information.imprint', url: '/content/imprint' },
      { title: 'menu.information.functions_pricing', url: '/content/pricing' },
      { title: 'menu.information.faq', url: '/content/faq' },
      { title: 'menu.information.about', url: '/content/about' },
      { title: 'menu.information.blog', url: '/content/blog' },
      { title: 'menu.information.investors', url: '/investors' },
      { title: 'menu.information.contact', url: '/contact' },
    ],
  },
];

export const PetOwnerMenus: IMenu[] = [
  {
    title: 'menu.profile.title',
    subMenus: [
      { title: 'menu.profile.my_profile', url: '/pet-owner/profile', subMenus: [] },
      { title: 'menu.profile.settings', url: '/settings' },
      { title: 'menu.profile.finances', url: '/finances' },
    ],
  },
  { title: 'menu.my_pets', url: '/pet-owner/my-pets', subMenus: [] },
  { title: 'menu.my_friends', url: '/pet-owner/my-friends', subMenus: [] },
  { title: 'menu.my_experts', url: '/pet-owner/my-experts', subMenus: [] },
  {
    title: 'menu.functionality.title',
    subMenus: [
      { title: 'menu.functionality.emergency', url: '/emergency' },
      { title: 'menu.functionality.video_call', url: '/search?tags=video' },
      { title: 'menu.functionality.vouchers', url: '/pet-owner/my-vouchers' },
      { title: 'menu.functionality.coupons', url: '/search?tags=has_coupons' },
      { title: 'menu.events', url: '/events', feature: 'events' },
      { title: 'menu.offers', url: '/offers' },
      { title: 'menu.missed-pets', url: '/missed-pet' },
      { title: 'menu.hazards', url: '/hazards' },
      { title: 'menu.news', url: '/news' },
      { title: 'menu.messages', url: '/message-center' },
    ],
  },
  {
    title: 'menu.information.title',
    subMenus: [
      { title: 'menu.information.privacy', url: '/content/privacy' },
      { title: 'menu.information.terms', url: '/content/terms' },
      { title: 'menu.information.imprint', url: '/content/imprint' },
      { title: 'menu.information.pricing', url: '/content/pricing' },
      { title: 'menu.information.faq', url: '/content/faq' },
      { title: 'menu.information.about', url: '/content/about' },
      { title: 'menu.information.blog', url: '/content/blog' },
      { title: 'menu.information.investors', url: '/investors' },
      { title: 'menu.information.contact', url: '/contact' },
    ],
  },
  { title: 'menu.logout', subMenus: [] },
];

export const PetExpertMenus: IMenu[] = [
  {
    title: 'menu.profile.title',
    subMenus: [
      { title: 'menu.profile.my_profile', url: '/expert/profile', subMenus: [] },
      { title: 'menu.profile.settings', url: '/settings' },
      { title: 'menu.profile.finances', url: '/finances' },
    ],
  },
  { title: 'menu.my_pet_owners', url: '/expert/petowner' },
  {
    title: 'menu.functionality.title',
    subMenus: [
      { title: 'menu.microsite', url: '/microsite' },
      { title: 'menu.functionality.emergency_service', url: '/expert/profile/emergency-edit' },
      { title: 'menu.functionality.video_call', url: '/expert/profile/video-call' },
      { title: 'menu.vouchers', url: '/expert/voucher' },
      { title: 'menu.coupons', url: '/expert/coupon' },
      { title: 'menu.events', url: '/events', feature: 'events' },
      { title: 'menu.offers', url: '/offers' },
      { title: 'menu.missed-pets', url: '/missed-pet' },
      { title: 'menu.hazards', url: '/hazards' },
      { title: 'menu.news', url: '/news' },
      { title: 'menu.messages', url: '/message-center' },
      { title: 'menu.analytics', url: '/expert/analytics' },
    ],
  },
  {
    title: 'menu.information.title',
    subMenus: [
      { title: 'menu.information.privacy', url: '/content/privacy' },
      { title: 'menu.information.terms', url: '/content/terms' },
      { title: 'menu.information.imprint', url: '/content/imprint' },
      { title: 'menu.information.pricing', url: '/content/pricing' },
      { title: 'menu.information.faq', url: '/content/faq' },
      { title: 'menu.information.about', url: '/content/about' },
      { title: 'menu.information.blog', url: '/content/blog' },
      { title: 'menu.information.investors', url: '/investors' },
      { title: 'menu.information.contact', url: '/contact' },
    ],
  },
  { title: 'menu.logout', url: '', subMenus: [] },
];

export const AdminMenus: IMenu[] = [
  { title: 'menu.dashboard', url: '/', subMenus: [] },
  { title: 'menu.profile.settings', url: '/settings', subMenus: [] },
  {
    title: 'menu.information.title',
    subMenus: [
      { title: 'menu.information.privacy', url: '/content/privacy' },
      { title: 'menu.information.terms', url: '/content/terms' },
      { title: 'menu.information.imprint', url: '/content/imprint' },
      { title: 'menu.information.pricing', url: '/content/pricing' },
      { title: 'menu.information.faq', url: '/content/faq' },
      { title: 'menu.information.about', url: '/content/about' },
      { title: 'menu.information.investors', url: '/investors' },
      { title: 'menu.information.contact', url: '/contact' },
    ],
  },
  { title: 'menu.logout' },
];
