import { environment } from '@environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AddressModel, ErrorData, ExpertModel } from '@app/models';
import { VideoCallType } from '@app/models/call/call.model';
import { CalendarAvailabilityModel } from '@app/models/expert/calendar-availability.model';
import { UserService } from '@app/services';
import { UpdateLanguageAction, UpdateSpinnerAction, UpdateToastAction } from '@app/store/app.action';
import { AppState } from '@app/store/app.state';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Time } from '@app/utils';
import { Meta, Title } from '@angular/platform-browser';
import { TranslationService } from '@app/shared/translation';

// TODO: reduce size, move methods out into libraries / components
@Injectable({
  providedIn: 'root',
})
export class AppService {
  @Select(AppState.language) languageState$: Observable<string>;
  private lang: string;

  constructor(
    private alertController: AlertController,
    private metaService: Meta,
    private router: Router,
    private store: Store,
    private titleService: Title,
    private toastController: ToastController,
    private translate: TranslateService,
    private translationService: TranslationService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: Platform
  ) {
    this.languageState$.subscribe((lang) => {
      if (this.lang && this.lang !== lang) {
        this.changeLanguage(lang);
      }
      this.lang = lang;
    });
  }

  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  isMobile(): boolean {
    if (typeof window == 'undefined') return false;
    return window?.innerWidth && window?.innerWidth <= 800;
  }

  /**
   * Show Spinner
   * @param message: Message
   * @param messageParams
   */
  showSpinner(message: string = 'common.loading_status', messageParams: Record<string, string | number> = {}): void {
    const spinner = { show: true, message, messageParams };
    this.store.dispatch(new UpdateSpinnerAction(spinner));
  }

  scrollAndAnimateView(el: Element): void {
    const childEl = el.querySelector('ion-card');
    childEl.classList.add('map-card-selected');
    setTimeout(() => {
      childEl.classList.remove('map-card-selected');
    }, 1000);
    el.scrollIntoView();
  }

  // Hide Spinner
  hideSpinner(): void {
    this.hideCurrentSpinner();
    setTimeout(() => {
      this.hideCurrentSpinner(); // Call this again to prevent case where show and hide spinner gets called immediately
    }, 50);
  }

  /**
   * Download file from blob data
   * @param blob: Blob Data
   * @param name: File name
   */
  downloadFileFromBlob(blob: Blob, name: string): void {
    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobURL;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (name && name.length) a.download = name;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  /**
   * Download File From Url
   * @param url: File Url
   * @param name: File name
   */

  downloadFromUrl(url: string, name: string): Promise<void> {
    this.showSpinner();
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          this.downloadFileFromBlob(blob, name);
          this.hideSpinner();
          return resolve();
        })
        .catch((error) => {
          this.hideSpinner();
          return reject(error);
        });
    });
  }

  /**
   * Validate fields (this will succeed when one language obj is succeed
   * @param obj
   * @return [success flag, error field name]
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validateFieldsForOneLanguage(obj: Record<string, any>, optionalFields?: string[]): [boolean, string] {
    let flag = false;
    let fieldName = '';
    for (const language in obj) {
      if (obj.hasOwnProperty(language)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const tempData = obj[language];
        let validationSucceed = true;
        let succeedFieldCount = 0;
        let prevSucceedFieldCount = 0;
        for (const key in tempData) {
          if (optionalFields && optionalFields.filter((item) => item == key).length) continue;
          if (tempData.hasOwnProperty(key)) {
            if (tempData[key] !== '' && tempData[key]) {
              succeedFieldCount += 1;
              continue;
            }
            if (!fieldName || succeedFieldCount > prevSucceedFieldCount) {
              fieldName = `${language}-${key}`;
            }
            prevSucceedFieldCount = succeedFieldCount;
            validationSucceed = false;
            break;
          }
        }
        if (validationSucceed) {
          flag = true;
          break;
        }
      }
    }
    return [flag, fieldName];
  }

  /**
   * Change UI language to selected language
   * @param lang: Language
   * @param updateStore: flag to update language in store
   */
  changeLanguage(lang: string, updateStore = false): void {
    if (this.lang === lang) {
      return;
    }
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);

    localStorage.setItem('app-language', lang);
    updateStore && this.store.dispatch(new UpdateLanguageAction(lang));
  }

  hideCurrentSpinner(): void {
    const spinner = { show: false, message: null };
    this.store.dispatch(new UpdateSpinnerAction(spinner));
  }

  // Show Toast
  success(message: string, delay: number = 5000): void {
    const toast = {
      message,
      option: { delay, classname: 'bg-success text-light' },
    };
    this.store.dispatch(new UpdateToastAction(toast));
    this.closeAlert(delay);
  }

  // This function takes in latitude and longitude of two location and returns the distance between them as the crow
  // flies (in km)
  /**
   * takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
   * @param lat1: First latitude
   * @param lon1 First longitude
   * @param lat2 Second latitude
   * @param lon2 Second longitude
   */
  calcCrow(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // km
    const dLat = this.toRad(lat2 - lat1);
    const dLon = this.toRad(lon2 - lon1);
    const newLat1 = this.toRad(lat1);
    const newLat2 = this.toRad(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(newLat1) * Math.cos(newLat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  // Converts numeric degrees to radians
  toRad(value: number): number {
    return (value * Math.PI) / 180;
  }

  /**
   * Close Alert
   * @param delay: Delay
   * @private
   */
  private closeAlert(delay: number = 5000): void {
    setTimeout(() => {
      this.store.dispatch(new UpdateToastAction(null));
    }, delay);
  }

  /**
   * Get Week Day string from number
   * @param i
   */
  getWeekDayString(i: number): string {
    const weekDayAry = [
      'week.monday',
      'week.tuesday',
      'week.wednesday',
      'week.thursday',
      'week.friday',
      'week.saturday',
      'week.sunday',
    ];
    return weekDayAry[i - 1];
  }

  /**
   * Get Map Pin Icon Url from map pin type
   * @param type: Map Pin Type
   */
  public getMapPinIconUrl(type: string): string {
    const mapIconUrl = '/assets/icon/map_icon/';
    let pinIcon: string;
    switch (type) {
      case 'event':
        pinIcon = 'pin_event';
        break;
      case 'expert':
        pinIcon = 'pin_expert';
        break;
      case 'hazard':
        pinIcon = 'pin_hazard';
        break;
      case 'missed':
        pinIcon = 'pin_missed';
        break;
      case 'myself':
        pinIcon = 'default';
        break;
      case 'offer':
          pinIcon = "pin_offer";
          break;
      case 'pet_owner':
        pinIcon = 'pin_pet_owner';
        break;
      case 'pet':
        pinIcon = 'pin_pet';
        break;
      case 'robidog_toilet':
        pinIcon = 'pin_robidog_toilet';
        break;
      case 'robidog_dispenser':
        pinIcon = 'pin_robidog_dispenser';
        break;
      default:
        pinIcon = 'pin_other';
        break;
    }
    return mapIconUrl + pinIcon + '.svg';
  }
  
  /**
   * Check if expert is eligible for emergency/video call
   * @param expertData: Expert Model
   * @param type: Video call type
   */
  checkExpertCallEligibility(expertData: ExpertModel, type: VideoCallType): boolean {
    if (type === 'emergency' && !expertData.has_emergency_call) {
      this.presentErrorToast('common.expert_is_not_available_for_emergency_call');
      return false;
    }
    if (type === 'video' && !expertData.has_video_call) {
      this.presentErrorToast('common.expert_is_not_available_for_video_call');
      return false;
    }
    const availabilities = type === 'emergency' ? expertData.expert_emergency_times : expertData.expert_video_times;
    return this.checkCalendarAvailability(availabilities);
  }

  /**
   * Check calendar availability to check if current time is eligible for passed times
   * @param availabilities: CalendarAvailabilityModel Arrray
   * @param showPopup: Show error popup flag
   */
  checkCalendarAvailability(availabilities: CalendarAvailabilityModel[], showPopup: boolean = true): boolean {
    const now = new Date();
    const isAvailable = availabilities.some((availability) => {
      if (availability.time_from == null || availability.time_to == null) {
        return false;
      }
      if (now.getDay() !== availability.weekday && now.getDay() + 7 !== availability.weekday) {
        return false;
      }
      return Time.isBetween(now, availability.time_from, availability.time_to);
    });
    if (!isAvailable && showPopup) {
      this.presentErrorToast('common.calendar_not_available');
    }
    return isAvailable;
  }

  /**
   * Process API error message and show alert
   * @param error: HTTP error
   */
  public processAPIErrorMessage(error: HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      const errorData = this.getServerErrorMessage(error);
      void this.presentAlert(errorData.title, null, errorData.msg, 'common.ok');
    } else {
      void this.presentAlert('error_messages.error', null, 'error_messages.common_error', 'common.ok');
    }
  }

  /**
   * Get Error Message from server error response
   * @param error: Server error response
   * @return object(title and message)
   */
  public getServerErrorMessage(error: ErrorData): { title: string; msg: string } {
    let headerString: string = error.error.message;
    let errorData: ErrorData['error']['data'] | ErrorData['error']['data']['errors'];
    const errorObj = error.error;
    if (errorObj.data !== undefined) {
      if (errorObj.data.errors !== undefined) {
        errorData = errorObj.data.errors;
      } else {
        errorData = errorObj.data;
      }
    } else if (errorObj.errors !== undefined) {
      errorData = error.error.errors;
    } else if (errorObj.body !== undefined && errorObj.body.text) {
      errorData = [errorObj.body.text];
    }
    let errorMessage = '';
    if (errorData !== undefined) {
      if (typeof errorData === 'string') {
        errorMessage = errorData;
      } else {
        for (const key in errorData) {
          if (!errorData.hasOwnProperty(key)) {
            continue;
          }
          const errorList = errorData[key] as string[] | string;
          if (typeof errorList === 'string') {
            errorMessage += errorList + '\n';
          } else {
            errorList.forEach((value) => {
              errorMessage += value + '\n';
            });
          }
        }
      }
    } else {
      errorMessage = error.error.error;
    }
    if (!headerString) {
      headerString = 'error_messages.error';
    }
    return { title: headerString, msg: errorMessage };
  }

  /**
   * Validate Email
   * @param email: Email to validate
   */
  public validateEmail(email: string): boolean {
    const re =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * Present alert into screen
   * @param: header: Alert title
   * @param: subheader: Alert subtitle
   * @param: msg: Alert msg
   * @param: buttonTitle: Button Title
   */

  public async presentAlert(
    header: string = 'common.alert',
    subHeader: string = '',
    msg: string = 'error_messages.error',
    buttonTitle: string = 'common.ok'
  ): Promise<void> {
    const headerString = this.translate.instant(header) as string;
    let subHeaderString = '';
    if (subHeader) {
      subHeaderString = this.translate.instant(subHeader) as string;
    }
    const messageString = this.translate.instant(msg) as string;
    const bTitle = this.translate.instant(buttonTitle) as string;
    const alert = await this.alertController.create({
      header: headerString,
      subHeader: subHeaderString,
      message: messageString,
      buttons: [bTitle],
    });
    await alert.present();
  }

  /**
   * Present toast message
   * @param msg: Toast Message
   * @param duration: Toast duration
   * @param buttons: Toast Buttons
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async presentSuccessToast(msg: string = '', duration: number = 3, buttons: any = null): Promise<void> {
    await this.dismissToast();
    const toast = await this.toastController.create({
      message: this.translate.instant(msg) as string,
      duration: duration * 1000,
      color: 'success',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      buttons,
    });
    await toast.present();
  }

  displayToastForAction(msg: string, redirectLink: string, duration = 10): void {
    this.presentSuccessToast(msg, duration, [
      {
        text: this.translate.instant('common.open') as string,
        role: 'cancel',
        handler: () => {
          this.router.navigateByUrl(redirectLink);
        },
      },
    ]);
  }

  /**
   * Get Full Address String from address model
   * @param addressModel: Address Model
   * the ', ' after street number is only added if there's at least street or number
   */

  public getFullAddressString(addressModel: AddressModel): string {
    if (!addressModel) {
      return '';
    }

    let addressString = '';
    const streetString = this.getStreetString(addressModel);
    const cityString = this.getCityString(addressModel);

    if (streetString !== '') {
      addressString = streetString;
      if (cityString !== '') {
        addressString += ', ' + cityString;
      }
    } else if (cityString !== '') {
      addressString = cityString;
    }

    return addressString;
  }

  /**
   * Get Street String from address model
   * the space between street and street number is only added if there's both street and number
   * @param addressModel: Address Model
   */
  public getStreetString(addressModel: AddressModel): string {
    if (!addressModel) {
      return '';
    }

    let streetString = '';
    if (addressModel.street) {
      streetString = addressModel.street;
      if (addressModel.streetnumber) {
        streetString += ' ' + addressModel.streetnumber;
      }
    } else if (addressModel.streetnumber) {
      streetString = addressModel.streetnumber;
    }

    return streetString;
  }

  /**
   * Get City String from address model
   * the space between postcode and city is only added if there's both postcode and city
   * @param addressModel: Address Model
   */
  public getCityString(addressModel: AddressModel): string {
    if (!addressModel) {
      return '';
    }

    let cityString = '';
    if (addressModel.postbox) {
      cityString = addressModel.postbox;
      if (addressModel.city) {
        cityString += ' ' + addressModel.city;
      }
    } else if (addressModel.city) {
      cityString = addressModel.city;
    }

    return cityString;
  }

  /**
   * Present Error toast message
   * @param msgKey: Toast Message
   * @param transParams: Translate params
   * @param duration: Toast duration
   */

  // eslint-disable-next-line @typescript-eslint/ban-types
  public async presentErrorToast(msgKey: string = '', transParams: object = null, duration: number = 3): Promise<void> {
    await this.dismissToast();
    const message = this.translate.instant(msgKey, transParams) as string;
    const toast = await this.toastController.create({
      message,
      duration: duration * 1000,
      color: 'danger',
    });
    await toast.present();
  }

  /**
   * Present Error toast message Validation required messages
   * @param errorKey: Validation Error Key
   * @param duration: Toast duration
   */
  public async presentErrorToastForValidation(errorKey: string = '', duration: number = 3): Promise<void> {
    await this.dismissToast();
    let key = errorKey;

    // Correct errorKey to match for translation
    if (errorKey === 'salutation_id') {
      key = 'salutation';
    } else if (errorKey === 'firstname') {
      key = 'first_name';
    } else if (errorKey === 'lastname') {
      key = 'last_name';
    }
    const message = this.translate.instant(`validation_error.${key}.required`) as string;
    const toast = await this.toastController.create({
      message,
      duration: duration * 1000,
      color: 'danger',
    });
    await toast.present();
  }

  /**
   * Dismiss Toast
   */
  public dismissToast(): Promise<void> {
    return new Promise((resolve) => {
      void this.toastController.getTop().then((res) => {
        if (res !== undefined) {
          void res.dismiss().then(() => {
            resolve();
          });
        } else {
          resolve();
        }
      });
    });
  }

  /**
   * Show Confirm Popup
   * @param messageKey: Localized Message Key
   * @param yesButtonKey: Yes Button Localization Key
   * @param noButtonKey: No Button Localization Key
   * @param messageParams
   */
  public async confirmPopup(
    messageKey: string = 'common.delete_confirm',
    yesButtonKey: string = 'common.yes',
    noButtonKey: string = 'common.no',
    messageParams: Record<string, string> = {}
  ): Promise<boolean> {
    return new Promise((resolve) => {
      void this.alertController
        .create({
          header: this.translate.instant('common.warning') as string,
          message: this.translate.instant(messageKey, messageParams) as string,
          buttons: [
            {
              text: this.translate.instant(noButtonKey) as string,
              role: 'cancel',
              cssClass: 'app-alert-button',
              handler: () => {
                return resolve(false);
              },
            },
            {
              text: this.translate.instant(yesButtonKey) as string,
              cssClass: 'app-alert-button',
              handler: () => {
                return resolve(true);
              },
            },
          ],
        })
        .then((confirm: HTMLIonAlertElement) => {
          return confirm.present();
        });
    });
  }

  /**
   * Show Success Message Popup
   * @param messageKey: Localized Message Key
   */
  public async successPopup(messageKey: string): Promise<void | boolean> {
    return new Promise((resolve) => {
      void this.alertController
        .create({
          header: 'Success',
          message: this.translate.instant(messageKey) as string,
          buttons: [
            {
              text: this.translate.instant('common.yes') as string,
              handler: () => {
                return resolve(true);
              },
            },
          ],
        })
        .then((confirm) => {
          return confirm.present();
        });
    });
  }

  updateHreflangTags() {
    const locales = this.translationService.getLocales();
    locales.push('x-default');
    const hreflangTags = locales.map((locale) => {
      return {
        rel: 'alternate',
        hreflang: locale,
        href: this.replaceLocaleRouteSegment(this.router.url, locale),
      };
    });

    this.metaService.getTags('rel="alternate"')
      .forEach((tag) => this.metaService.removeTagElement(tag));
    this.metaService.addTags(hreflangTags, true);
  }

  replaceLocaleRouteSegment(url: string, locale: string, addBaseUrl = true): string {
    const parsedUrl = this.router.parseUrl(url);
    const routeSegments = parsedUrl.root.children.primary?.segments;
    if (routeSegments && routeSegments.length > 0
        && routeSegments[0].path.match(/^[a-zA-Z]{2}(-[a-zA-Z]{2})?$/)) {
      routeSegments.shift();
    }
    let newUrl = parsedUrl.toString();
    if (newUrl == '/') {
      newUrl = '';
    } else if (newUrl.startsWith('/?')) {
      newUrl = newUrl.substring(1);
    }

    const localeSegment = (locale == 'x-default') ? '' : ('/' + locale)
    return (addBaseUrl ? environment.baseUrl : '') + localeSegment + newUrl;
  }

  setMeta(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaService.updateTag({
      property: 'og:title',
      content: title,
    });
    this.metaService.updateTag({
      property: 'og:description',
      content: description,
    });
    // TODO: og:url
  }

  setMetaImage(image: string) {
    this.metaService.updateTag({
      property: 'og:image',
      content: image,
    });
  }

  setMetaTranslate(titleKey: string, descriptionKey: string) {
    this.setMeta(this.translate.instant(titleKey) as string, this.translate.instant(descriptionKey) as string);
  }

  setCanonical(path: string) {
    const locale = this.translationService.getCurrentLocale();
    const localeString = locale.country ? locale.language + '-' + locale.country : locale.language;
    path = this.replaceLocaleRouteSegment(path, localeString);
    this.updateLinkTag('canonical', path);
  }

  resetCanonical() {
    this.removeLinkTag('canonical');
  }

  resetMeta() {
    this.setMetaTranslate('common.meta_title', 'common.meta_description');
    this.setMetaImage('');
    this.resetCanonical();
  }

  setPrevNextLinks(currentPage: number, lastPage: number, pageQueryParameter = 'page') {
    if (currentPage == 1) {
      this.removeLinkTag('prev');
    } else {
      const url = this.router.parseUrl(this.router.url)
      url.queryParams[pageQueryParameter] = currentPage - 1;
      this.updateLinkTag('prev', environment.baseUrl + url.toString());
    }

    if (currentPage == lastPage) {
      this.removeLinkTag('next');
    } else {
      const url = this.router.parseUrl(this.router.url)
      url.queryParams[pageQueryParameter] = currentPage + 1;
      this.updateLinkTag('next', environment.baseUrl + url.toString());
    }
  }

  removeLinkTag(rel: string) {
    const el: HTMLLinkElement = document.querySelector(`link[rel=${rel}]`);
    if (el) {
      el.remove();
    }
  }

  updateLinkTag(rel: string, href: string) {
    let el: HTMLLinkElement = document.querySelector(`link[rel=${rel}]`);
    if (!el) {
      el = document.createElement('link');
      el.rel = rel;
      document.head.appendChild(el);
    }
    el.href = href;
  }
}
