import { ExpertCategoryTextModel } from '@app/models/expert-category/expert.category.text.model';

export class ExpertCategoryModel {
  id: string;
  name: string;
  type: string;
  emergency_service_allowed: boolean;
  parent_category_id: string;
  texts: ExpertCategoryTextModel[] = [];
}
