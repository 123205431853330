import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import { AppEventType, EventQueueService } from '@app/services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { captureException, createErrorHandler, init, routingInstrumentation } from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

interface ErrorInterface {
  message: string;
  status: number;
  statusText: string;
  url: string;
  originalError: unknown;
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  ignoredErrorMessages: string[];
  constructor(private translate: TranslateService, private eventQueue: EventQueueService) {
    this.ignoredErrorMessages = [
      'The string supplied did not seem to be a phone number',
      'Invalid country calling code',
      'Share canceled',
    ];
    if (!isDevMode()) {
      init({
        dsn: environment.sentryDSN,
        denyUrls: ['localhost', '127.0.0.1'],
        environment: environment.environment,
        integrations: [
          // Registers and configures the Tracing integration,
          // which automatically instruments your application to monitor its
          // performance, including custom Angular routing instrumentation
          new Integrations.BrowserTracing({
            tracingOrigins: [environment.apiUrl],
            routingInstrumentation: routingInstrumentation,
          }),
        ],
        release: environment.client_version,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }

    createErrorHandler({
      showDialog: false,
    });
  }

  handleError(error: ErrorInterface): void {
    // If 503 error caught, then display service unavailable page
    if (
      (error.status === 503 || error.status === 0) &&
      (error.url.includes(environment.apiUrl) || error.url.includes(location.host))
    ) {
      this.eventQueue.dispatch(AppEventType.ServiceUnavailable);
      return;
    }

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      this.eventQueue.dispatch(AppEventType.UpdateAvailable);
      return;
    }
    const isIgnoredError = this.ignoredErrorMessages.some((msg) => error.message.includes(msg));
    if (isIgnoredError) {
      return;
    }
    // eslint-disable-next-line no-console
    console.error(error);
    // If not localhost, then submit error to sentry
    if (!isDevMode()) {
      captureException(error.originalError || error);
    }
  }
}
