import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { GeolocationModel, MapPinModel } from '@app/models';
import { TranslateService } from '@ngx-translate/core';
import { differenceInMinutes } from 'date-fns';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { AppEventType, EventQueueService, HttpService } from '@app/services';
import { isPlatformBrowser } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import AsyncLock from 'async-lock';
import { Store } from '@ngxs/store';
import { AppState } from '@app/store/app.state';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private status: 'uninitialized' | 'user-prompt' | 'user-denied' | 'initialized' = 'uninitialized';
  private locationBroadcast = new ReplaySubject<GeolocationModel>(1);
  private exactLocationBroadcast = new ReplaySubject<GeolocationModel>(1);
  private currentExactLocation: GeolocationModel;
  private currentLocation: GeolocationModel;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  private lock: AsyncLock = new AsyncLock();

  constructor(
    private apiService: HttpService,
    private eventQueue: EventQueueService,
    private store: Store,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.store.select(AppState.country).subscribe((country) => {
      if (!country) return;
      if (!this.currentLocation || this.currentLocation.source !== 'device') {
        this.currentLocation = new GeolocationModel();
        this.currentLocation.lat = country.lat;
        this.currentLocation.lng = country.lng;
        this.locationBroadcast.next(this.currentLocation);
      }
    });
  }

  // location is only updating if the device lcation chahged more than a kilometer
  public getLocationObserver(): Observable<GeolocationModel> {
    this.initialize();
    return this.locationBroadcast.asObservable();
  }

  // exact location is updating on every change of the device location
  public getExactLocationObserver(): Observable<GeolocationModel> {
    this.initialize();
    return this.exactLocationBroadcast.asObservable();
  }

  /**
   * Waits for a location which was obtained from the device (no default app location)
   * @param maxWaitMs: max time to wait (default = infinitely)
   */
  public waitForDeviceLocation(maxWaitMs = 0): Observable<GeolocationModel> {
    return new Observable((subscriber) => {
      if (!this.isBrowser()) {
        subscriber.next(this.currentExactLocation);
        subscriber.complete();
        return;
      }

      const observer = this.getExactLocationObserver();
      const unsubscribeSignal = new Subject<void>();
      observer.pipe(takeUntil(unsubscribeSignal.asObservable())).subscribe((location) => {
        if (location.source == 'device') {
          unsubscribeSignal.next();
          subscriber.next(location);
          subscriber.complete();
        }
      });

      if (maxWaitMs) {
        setTimeout(() => {
          unsubscribeSignal.next();
          subscriber.next(this.currentExactLocation);
          subscriber.complete();
        }, maxWaitMs);
      }
    });
  }

  /**
   * Start listening to the device location. The location permission is only requested after prior user consent.
   * This is to prevent users denying the permission as it would be cumbersome for them to grant the permission again once they denied it.
   */
  private async initialize() {
    this.lock.acquire('initialize', async () => {
      if (['initialized', 'user-prompt', 'user-denied'].some((x) => x == this.status)) return;

      const state = await this.getPermissionState();
      switch (state) {
        case 'granted':
          this.status = 'initialized';
          this.startWatching();
          return;
        case 'denied':
          this.status = 'user-denied';
          return;
        case 'prompt':
        case 'unknown':
          this.status = 'user-prompt';
          this.displayPermissionRequest();
      }
    });
  }

  private async displayPermissionRequest() {
    if (!this.isBrowser()) return;

    setTimeout(() => {
      this.eventQueue.dispatch(AppEventType.ShowOverlayPopup, {
        titleTextKey: 'common.location',
        htmlTextKey: 'common.location_permission_popup',
        buttons: [
          {
            key: 'yes',
            textKey: this.translate.instant('common.yes') as string,
            handler: () => {
              this.status = 'initialized';
              this.startWatching();
            },
          },
        ],
      });
    }, 3000);
  }

  private startWatching(): void {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.watchPosition(
        async (pos) => {
          const location: GeolocationModel = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
            source: 'device',
          };

          this.currentExactLocation = location;
          this.exactLocationBroadcast.next(location);

          const distance = this.currentLocation ? this.calculateDistance(location, this.currentLocation) : 1;
          if (distance / 1000 >= 1) {
            // If distance is more than 1 km, then broadcast event
            this.currentLocation = location;
            this.locationBroadcast.next(location);
            await this.checkAndUpdateUserLocation();
          }
        },
        (err) => {
          this.locationBroadcast.error(err);
        },
        {
          enableHighAccuracy: false,
          timeout: 3 * 60 * 1000,
          maximumAge: 0,
        }
      );
    }
  }

  calculateDistance(location1: GeolocationModel, location2: GeolocationModel): number {
    const R = 6371e3; // R is earth’s radius
    const lat1radians = this.toRadians(location1.lat);
    const lat2radians = this.toRadians(location2.lat);

    const latRadians = this.toRadians(location2.lat - location1.lat);
    const lonRadians = this.toRadians(location2.lng - location1.lng);

    const a =
      Math.sin(latRadians / 2) * Math.sin(latRadians / 2) +
      Math.cos(lat1radians) * Math.cos(lat2radians) * Math.sin(lonRadians / 2) * Math.sin(lonRadians / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }

  toRadians(val: number): number {
    return (val / 180.0) * Math.PI;
  }

  getMyLocationPin(location: GeolocationModel): MapPinModel {
    return { ...new MapPinModel(), type: 'myself', ...location };
  }

  async checkAndUpdateUserLocation(): Promise<void> {
    const lastTime = localStorage.getItem('last_location_update_time');
    const now = new Date();
    if (lastTime) {
      const lastTimeDate = new Date(lastTime);
      if (differenceInMinutes(lastTimeDate, now) < 10) {
        return;
      }
    }
    localStorage.setItem('last_location_update_time', now.toISOString());
    if (localStorage.token) {
      await this.updateUserLocation(this.currentLocation).toPromise();
    }
  }

  public updateUserLocation(location: GeolocationModel): Observable<void> {
    return this.apiService.putRequest('user/me/location/refresh', { lat: location.lat, lng: location.lng }, true);
  }

  /**
   * Checks whether the location permission has been granted. This is not possible in all browsers.
   * @protected
   */
  protected getPermissionState(): Promise<'granted' | 'prompt' | 'denied' | 'unknown'> {
    return new Promise<'granted' | 'prompt' | 'denied' | 'unknown'>((resolve) => {
      // some browsers do not support permission queries
      if (!navigator.permissions?.query) return resolve('unknown');

      navigator.permissions
        .query({ name: 'geolocation' })
        .then((status) => {
          return resolve(status.state);
        })
        .catch(() => {
          return resolve('unknown');
        });
    });
  }

  protected isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
