import { Injectable } from '@angular/core';
import { InfoListingModel } from '@app/models/info/info.listing.model';
import { InfoModel } from '@app/models/info/info.model';
import { PaginationModel } from '@app/models/pagination.model';
import { HttpService } from '@app/services';
import { Observable } from 'rxjs';
import { ListRequest, SaveInfoRequest } from '@app/models/requests';

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  private url = 'info';

  constructor(private httpService: HttpService) {}

  getList(queryParams: ListRequest & { key?: string } = {}): Observable<PaginationModel<InfoModel>> {
    return this.httpService.getRequest<PaginationModel<InfoModel>>(this.url, true, queryParams);
  }

  getInfo(id: string, lang: string = 'en'): Observable<InfoListingModel> {
    return this.httpService.getRequest(`${this.url}/${id}`, true, { lang });
  }

  deleteInfo(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  saveInfo(id: string, params: SaveInfoRequest): Observable<void> {
    if (id) {
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      return this.httpService.postRequest(`${this.url}`, params, true);
    }
  }
}
