import { Injectable } from '@angular/core';
import { FeedChannelListingModel } from '@app/models/feed-channel/feed-channel.listing.model';
import { FeedChannelModel } from '@app/models/feed-channel/feed-channel.model';
import { PaginationModel } from '@app/models/pagination.model';
import { HttpService } from '@app/services';
import { Observable } from 'rxjs';
import { ListRequest } from '@app/models/requests';

@Injectable({
  providedIn: 'root',
})
export class FeedChannelService {
  private url = 'feed_channel';

  constructor(private httpService: HttpService) {}

  getList(queryParams: ListRequest): Observable<PaginationModel<FeedChannelModel>> {
    return this.httpService.getRequest<PaginationModel<FeedChannelModel>>(this.url, true, queryParams);
  }

  getFeedChannel(id: string): Observable<FeedChannelListingModel> {
    return this.httpService.getRequest(`${this.url}/${id}`, true);
  }

  deleteFeedChannel(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  saveFeedChannel(id: string, params: FeedChannelModel): Observable<void> {
    if (id) {
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      return this.httpService.postRequest(`${this.url}`, params, true);
    }
  }
}
