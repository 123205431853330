import { Component } from '@angular/core';
import { AppService } from '@app/services';
import { AppEventType, EventQueueService } from '@app/services';

@Component({
  selector: 'app-audio',
  templateUrl: './app-audio.component.html',
  styleUrls: ['./app-audio.component.scss'],
})
export class AppAudioComponent {
  incomingAudio: HTMLAudioElement;
  outgoingAudio: HTMLAudioElement;
  disconnectAudio: HTMLAudioElement;

  constructor(private eventQueue: EventQueueService, private appService: AppService) {
    this.initAudios();
    this.initEvents();
  }

  initAudios(): void {
    if (!this.appService.isBrowser()) {
      return;
    }

    this.incomingAudio = new Audio();
    this.incomingAudio.src = './assets/audio/incoming.mp3';
    this.incomingAudio.loop = true;
    this.incomingAudio.load();
    this.outgoingAudio = new Audio();
    this.outgoingAudio.src = './assets/audio/outgoing.mp3';
    this.outgoingAudio.loop = true;
    this.outgoingAudio.load();
    this.disconnectAudio = new Audio();
    this.disconnectAudio.src = './assets/audio/disconnect.mp3';
    this.disconnectAudio.load();
  }

  initEvents(): void {
    this.eventQueue.subscribe(AppEventType.PlayIncomingAudio, () => {
      if (!this.getPlayStatus(this.incomingAudio)) {
        void this.incomingAudio.play();
      }
    });
    this.eventQueue.subscribe(AppEventType.PauseIncomingAudio, () => {
      this.incomingAudio.pause();
    });
    this.eventQueue.subscribe(AppEventType.PlayOutgoingAudio, () => {
      if (!this.getPlayStatus(this.outgoingAudio)) {
        void this.outgoingAudio.play();
      }
    });
    this.eventQueue.subscribe(AppEventType.PauseOutgoingAudio, () => {
      this.outgoingAudio.pause();
    });
    this.eventQueue.subscribe(AppEventType.PlayDisconnectAudio, () => {
      this.incomingAudio.pause();
      this.outgoingAudio.pause();
      if (!this.getPlayStatus(this.disconnectAudio)) {
        void this.disconnectAudio.play();
      }
    });
  }

  getPlayStatus(audioEl: HTMLAudioElement): boolean {
    return (
      audioEl.currentTime > 0 && !audioEl.paused && audioEl.ended && audioEl.readyState > audioEl.HAVE_CURRENT_DATA
    );
  }
}
