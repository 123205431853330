import { Injectable } from '@angular/core';
import { CountryModel, ExpertModel, ISpinnerState, IToast, NO_IMAGE_URL, PetOwnerModel, UserModel } from '@app/models';
import { ConnectionModel, ConnectionStatus } from '@app/models/user/connection.model';
import { ExpertService, PetOwnerService } from '@app/services';
import {
  AddConnectionAction,
  AddSubscriptionAction,
  ChangeFeatureStatusAction,
  IncreaseActionBadgeCount,
  IncreaseMessageBadgeCount,
  LogoutUserAction,
  RemoveSubscriptionAction,
  UpdateConnectionAction,
  UpdateConnectionStatusAction,
  UpdateCountryAction,
  UpdateExpertAction,
  UpdateFeaturesAction,
  UpdateLanguageAction,
  UpdateLocaleAction,
  UpdatePetOwnerAction,
  UpdateSpinnerAction,
  UpdateToastAction,
  UpdateUnreadActionCountAction,
  UpdateUserAction,
  UpdateUserInfoAction,
  UpdateUserObjectAction,
} from '@app/store/app.action';
import { AppFields, AppStateModel } from '@app/store/app.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { UserProductModel } from '@app/models/product/userProductModel';

const initialState: AppStateModel = {
  [AppFields.Connections]: [],
  [AppFields.Country]: null,
  [AppFields.Experts]: [],
  [AppFields.Features]: null,
  [AppFields.Language]: null,
  [AppFields.Locale]: null,
  [AppFields.PetOwner]: null,
  [AppFields.Products]: [],
  [AppFields.SelectedExpertId]: null,
  [AppFields.Spinner]: null,
  [AppFields.Toast]: null,
  [AppFields.User]: null,
  [AppFields.UserProducts]: [],
};

@State<AppStateModel>({
  name: 'app',
  defaults: initialState,
})
@Injectable({
  providedIn: 'root',
})
export class AppState {
  constructor(private expertService: ExpertService, private petOwnerService: PetOwnerService) {}

  @Selector()
  static connections(state: AppStateModel): ConnectionModel[] {
    return [...state[AppFields.Connections]];
  }

  @Selector()
  static country(state: AppStateModel): CountryModel {
    return state[AppFields.Country];
  }

  @Selector()
  static expert(state: AppStateModel): ExpertModel | null {
    return state[AppFields.Experts].find((expert) => expert.id === state[AppFields.SelectedExpertId]);
  }

  @Selector()
  static experts(state: AppStateModel): ExpertModel[] {
    return state[AppFields.Experts];
  }

  @Selector()
  static features(state: AppStateModel): string[] {
    return state[AppFields.Features];
  }

  @Selector()
  static language(state: AppStateModel): string {
    return state[AppFields.Language];
  }

  @Selector()
  static locale(state: AppStateModel): string {
    return state[AppFields.Locale];
  }

  @Selector()
  static petowner(state: AppStateModel): PetOwnerModel {
    return state[AppFields.PetOwner];
  }

  @Selector()
  static products(state: AppStateModel): string[] {
    return [...state[AppFields.Products]];
  }

  @Selector()
  static spinner(state: AppStateModel): ISpinnerState {
    return state[AppFields.Spinner];
  }

  @Selector()
  static toast(state: AppStateModel): IToast {
    return state[AppFields.Toast];
  }

  @Selector()
  static user(state: AppStateModel): UserModel {
    return state[AppFields.User];
  }

  @Selector()
  static userProducts(state: AppStateModel): UserProductModel[] {
    return state[AppFields.UserProducts];
  }

  @Selector()
  static userFullName(state: AppStateModel): string {
    const user = state[AppFields.User];
    const experts = state[AppFields.Experts];
    if (experts?.length) {
      return experts[0].practice_name;
    }
    if (!user?.firstname && !user?.lastname) {
      // TODO: text resource
      return 'ROSYPET USER';
    }
    return `${user.firstname ?? ''} ${user.lastname ?? ''}`;
  }

  @Selector()
  static userImageUrl(state: AppStateModel): string {
    const user = state[AppFields.User];
    if (user?.role === 'petowner') {
      const petOwner = state[AppFields.PetOwner];
      return petOwner?.image_url ? petOwner.image_url : NO_IMAGE_URL;
    } else if (user?.role === 'expert') {
      const experts = state[AppFields.Experts];
      return experts[0]?.image_url ? experts[0].image_url : NO_IMAGE_URL;
    }
    return NO_IMAGE_URL;
  }

  @Action(UpdateFeaturesAction)
  updateFeatures({ patchState }: StateContext<AppStateModel>, action: UpdateFeaturesAction): void {
    patchState({
      [AppFields.Features]: action.features,
    });
  }

  @Action(UpdateUserInfoAction)
  updateUserInfo({ patchState, getState }: StateContext<AppStateModel>, action: UpdateUserInfoAction): void {
    const updates: Partial<AppStateModel> = {};

    updates[AppFields.User] = action.userInfo?.user;
    updates[AppFields.PetOwner] = action.userInfo?.pet_owner[0];
    updates[AppFields.Experts] = action.userInfo?.experts;
    updates[AppFields.UserProducts] = action.userInfo?.products;

    const selectedExpertId = getState()[AppFields.SelectedExpertId];
    if (!selectedExpertId) {
      updates[AppFields.SelectedExpertId] = action.userInfo?.experts[0]?.id;
    }

    const products: string[] = [];
    if (action.userInfo?.products) {
      action.userInfo.products.forEach((userProduct) => {
        products.push(`${userProduct?.product?.key}_${userProduct?.product?.frequency}`);
      });
    }
    updates[AppFields.Products] = products;

    const connections: ConnectionModel[] = [];
    if (action.userInfo?.connections) {
      action.userInfo.connections.forEach((item) => {
        const connection = new ConnectionModel();
        Object.assign(connection, item);
        connections.push(connection);
      });
    }
    updates[AppFields.Connections] = connections;

    patchState(updates);
  }

  @Action(UpdateUserAction)
  updateUser({ patchState }: StateContext<AppStateModel>, action: UpdateUserAction): void {
    patchState({
      [AppFields.User]: action.user,
    });
  }

  @Action(LogoutUserAction)
  logoutUser({ patchState }: StateContext<AppStateModel>): void {
    patchState({
      [AppFields.User]: null,
      [AppFields.Experts]: [],
      [AppFields.PetOwner]: null,
      [AppFields.SelectedExpertId]: null,
      [AppFields.Products]: [],
      [AppFields.Connections]: [],
    });
  }

  @Action(UpdateCountryAction)
  updateCountry({ patchState }: StateContext<AppStateModel>, action: UpdateCountryAction): void {
    patchState({
      [AppFields.Country]: action.country,
    });
  }

  @Action(UpdateLanguageAction)
  updateLanguage({ patchState }: StateContext<AppStateModel>, action: UpdateLanguageAction): void {
    localStorage.setItem('APP_LANGUAGE', action.lang);
    patchState({
      [AppFields.Language]: action.lang,
    });
  }

  @Action(UpdateLocaleAction)
  updateLocale({ patchState }: StateContext<AppStateModel>, action: UpdateLocaleAction): void {
    patchState({
      [AppFields.Locale]: action.locale,
    });
  }

  @Action(UpdateSpinnerAction)
  updateSpinner({ patchState }: StateContext<AppStateModel>, action: UpdateSpinnerAction): void {
    patchState({
      [AppFields.Spinner]: action.spinnerState,
    });
  }

  @Action(UpdateToastAction)
  updateToast({ patchState }: StateContext<AppStateModel>, action: UpdateToastAction): void {
    patchState({
      [AppFields.Toast]: action.toast,
    });
  }

  @Action(UpdateUnreadActionCountAction)
  updateUnreadActionCountAction(
    { patchState, getState }: StateContext<AppStateModel>,
    action: UpdateUnreadActionCountAction
  ): void {
    const user = new UserModel().deserialize(getState()[AppFields.User]);
    user.unread_action_count = action.actionCount;
    patchState({
      [AppFields.User]: user,
    });
  }

  @Action(IncreaseActionBadgeCount)
  increaseActionBadgeCount({ patchState, getState }: StateContext<AppStateModel>): void {
    const user = new UserModel().deserialize(getState()[AppFields.User]);
    user.unread_action_count = Number(user.unread_action_count) + 1;
    patchState({
      [AppFields.User]: user,
    });
  }

  @Action(IncreaseMessageBadgeCount)
  increaseMessageBadgeCount({ patchState, getState }: StateContext<AppStateModel>): void {
    const user = new UserModel().deserialize(getState()[AppFields.User]);
    user.unread_message_count = Number(user.unread_message_count) + 1;
    patchState({
      [AppFields.User]: user,
    });
  }

  @Action(UpdateUserObjectAction)
  updateUserObject({ patchState, getState }: StateContext<AppStateModel>, action: UpdateUserObjectAction): void {
    const user = new UserModel().deserialize(getState()[AppFields.User]);
    Object.assign(user, action.user);
    patchState({
      [AppFields.User]: user,
    });
  }

  @Action(UpdatePetOwnerAction)
  updatePetOwner({ patchState }: StateContext<AppStateModel>, action: UpdatePetOwnerAction): void {
    this.petOwnerService.getPetOwner(action.petOwner.id).pipe(
      tap((result) => {
        const petOwner = result.data.pet_owner;
        patchState({
          [AppFields.PetOwner]: petOwner,
        });
      })
    );
  }

  @Action(AddSubscriptionAction)
  addSubscription({ patchState, getState }: StateContext<AppStateModel>, action: AddSubscriptionAction): void {
    const products = [...getState()[AppFields.Products]] ?? [];
    const key = `${action.key}_${action.frequency}`;
    const currentIndex = products.indexOf(key);
    if (currentIndex < 0) {
      patchState({
        [AppFields.Products]: [...products, key],
      });
    }
  }

  @Action(RemoveSubscriptionAction)
  removeSubscription({ patchState, getState }: StateContext<AppStateModel>, action: RemoveSubscriptionAction): void {
    const products = [...getState()[AppFields.Products]] ?? [];
    const key = `${action.key}_${action.frequency}`;
    const currentIndex = products.indexOf(key);
    if (currentIndex > -1) {
      products.splice(currentIndex, 1);
      patchState({
        [AppFields.Products]: products,
      });
    }
  }

  @Action(UpdateExpertAction)
  updateExpert({ patchState }: StateContext<AppStateModel>, action: UpdateExpertAction): void {
    // TODO: load all user's experts
    this.expertService.getExpert(action.expert.id).subscribe((result) => {
      const experts = [result.data.expert];
      patchState({
        [AppFields.Experts]: experts,
      });
    });
  }

  @Action(AddConnectionAction)
  addConnection({ patchState, getState }: StateContext<AppStateModel>, action: AddConnectionAction): void {
    const connections: ConnectionModel[] = [];
    getState()[AppFields.Connections].forEach((item) => {
      if (item.id != action.connection.id) {
        connections.push(item);
      }
    });
    connections.push(action.connection);

    patchState({
      [AppFields.Connections]: connections,
    });
  }

  @Action(ChangeFeatureStatusAction)
  changeFeatureStatus({ patchState, getState }: StateContext<AppStateModel>, action: ChangeFeatureStatusAction): void {
    const storeFeatures = getState()[AppFields.Features];
    if (storeFeatures == null) return;

    const features: string[] = [];
    let added = false;
    storeFeatures.forEach((item: string) => {
      if (item != action.data.feature) {
        features.push(item);
      } else {
        if (action.data.active) {
          features.push(item);
          added = true;
        }
      }
    });
    if (action.data.active && !added) {
      features.push(action.data.feature);
    }

    patchState({
      [AppFields.Features]: features,
    });
  }

  @Action(UpdateConnectionAction)
  updateConnection({ patchState, getState }: StateContext<AppStateModel>, action: UpdateConnectionAction): void {
    const connections: ConnectionModel[] = [];
    getState()[AppFields.Connections].forEach((item) => {
      if (item.id != action.connection.id) {
        connections.push(item);
      }
    });
    connections.push(action.connection);

    patchState({
      [AppFields.Connections]: connections,
    });
  }

  @Action(UpdateConnectionStatusAction)
  updateConnectionStatus(
    { patchState, getState }: StateContext<AppStateModel>,
    action: UpdateConnectionStatusAction
  ): void {
    const connections = [];
    getState()[AppFields.Connections].forEach((item) => {
      if (item.id == action.connectionId) {
        const connection: ConnectionModel = new ConnectionModel();
        Object.assign(connection, item);
        connection.status = action.status as ConnectionStatus;
        connection.invited_by = action.invited_by;
      }
      connections.push(item);
    });
    patchState({
      [AppFields.Connections]: connections,
    });
  }
}
