import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { AppEventType, EventQueueService } from '@app/services';
import { AppState } from '@app/store/app.state';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { fixDateFields } from '@app/utils/object.type.fixer';
import { LogoutUserAction } from '@app/store/app.action'

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  lang: string;
  apiURL: string;
  @Select(AppState.language) languageState$: Observable<string>;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private router: Router,
    private store: Store,
    private eventQueue: EventQueueService
  ) {
    this.apiURL = environment.apiUrl;
    this.lang = this.translateService.currentLang;
    this.languageState$.subscribe((lang) => {
      this.lang = lang;
    });
  }

  /**
   * Get the JSON web token from the local storage
   * @returns HttpHeaders
   */
  private static formatHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    if (localStorage.getItem('token') != null) {
      headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    }
    return headers;
  }

  /**
   * Handle http error responses
   * @param error: HTTP error
   */
  handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      switch (error.status) {
        case 0:
          this.eventQueue.dispatch(AppEventType.ServiceUnavailable);
          return of();
        case 403:
          // Forbidden, this means that user tried to enter page which is not allowed. Then navigate to main page.
          void this.router.navigate(['/']);
          return of();
        case 401: // Unauthorized, this means user tries to open page which must be authenticated
          if (this.router.url.includes('auth/otp-login')) {
            return throwError(error);
          }
          this.store.dispatch(new LogoutUserAction());
          localStorage.removeItem('token');
          void this.router.navigate(['/auth/login']);
          return of();
        default:
          break;
      }
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  /**
   * Serialize object into query string
   * @param obj: Query string obj
   */
  /* eslint-disable @typescript-eslint/no-unsafe-argument */
  // eslint-disable-next-line @typescript-eslint/ban-types
  serialize(obj: object): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p) && obj[p] != null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const elem = obj[p];
        if (Array.isArray(elem)) {
          for (const value of elem) {
            if (value instanceof Date) {
              str.push(encodeURIComponent(p) + '=' + encodeURIComponent(value.toISOString()));
            } else {
              str.push(encodeURIComponent(p) + '=' + encodeURIComponent(value));
            }
          }
        } else if (elem instanceof Date) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(elem.toISOString()));
        } else {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(elem));
        }
      }
    }
    /* eslint-enable */
    return str.join('&');
  }

  /**
   * Check if version update is really necessary and emit update event
   */
  checkVersion(): void {
    this.http
      .get(this.apiURL + 'main/footer/counters', { observe: 'response' })
      .subscribe((response: HttpResponse<unknown>) => {
        if (
          !isDevMode() &&
          response.headers.get('Client-Version') &&
          response.headers.get('Client-Version') !== environment.client_version
        ) {
          this.eventQueue.dispatch(AppEventType.UpdateAvailable, true);
        }
      });
  }

  deleteRequest<T>(url: string, params: Record<string, string>, auth: boolean = true): Observable<T> {
    return this.request('DELETE', url, params, {}, auth);
  }

  /**
   * Perform a GET request
   * @param url: Reqeust URL
   * @param auth: requires authentication flag (if so, it will add token to header)
   * @param params: Get params
   * @param isBlob: Is Blob
   */
  getRequest<T>(url: string, auth: boolean = true, params = {}, isBlob: boolean = false): Observable<T> {
    return this.request('GET', url, params, {}, auth, isBlob);
  }

  /**
   * Perform a POST request
   * @param url: request url
   * @param body: request body
   * @param auth: requires authentication flag (if so, it will add token to header)
   * @param isBlob: Is Blob
   */
  postRequest<T>(url: string, body = {}, auth: boolean = true, isBlob: boolean = false): Observable<T> {
    return this.request('POST', url, {}, body, auth, isBlob);
  }

  putRequest<T>(url: string, body = {}, auth: boolean = true): Observable<T> {
    return this.request('PUT', url, {}, body, auth);
  }

  request<T>(
    method: 'DELETE' | 'GET' | 'POST' | 'PUT',
    url: string,
    params = {},
    body = {},
    auth: boolean = true,
    isBlob: boolean = false
  ): Observable<T> {
    let newBody = body;
    let newParams = params;
    let queryParam = '';
    if (method == 'POST' || method == 'PUT') {
      newBody = { ...body, lang: this.lang };
    } else {
      newParams = { ...params, lang: this.lang };
      if (params != null) {
        queryParam = this.serialize(newParams);
      }
    }

    let header: HttpHeaders = null;
    if (auth) {
      header = HttpService.formatHeader();
    }

    const options: Record<string, unknown> = {
      headers: header,
    };

    if (isBlob) {
      options.responseType = 'blob';
    }

    url = this.apiURL + url + (queryParam ? '?' + queryParam : '');

    let observable: Observable<T> = null;
    if (method == 'DELETE') {
      observable = this.http.delete<T>(url, options);
    } else if (method == 'GET') {
      observable = this.http.get<T>(url, options);
    } else if (method == 'POST') {
      observable = this.http.post<T>(url, newBody, options);
    } else if (method == 'PUT') {
      observable = this.http.put<T>(url, newBody, options);
    }

    return observable?.pipe(
      map((obj: T) => {
        fixDateFields(obj);
        return obj;
      }),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

    /**
   * Build from data from data and parent key
   * @param formData: FormData to build
   * @param data: Data to convert to form data
   * @param parentKey: Parent Key
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  buildFormData(formData: FormData, data: any, parentKey: string = null): void {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      // eslint-disable-next-line @typescript-eslint/ban-types
      Object.keys(data as object).forEach((key) => {
        if (data[key]) {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        }
      });
    } else if (typeof data === 'boolean') {
      formData.append(parentKey, data ? '1' : '0');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const value: string = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  /**
   * Convert JSON to Form Data
   * @param data: Data
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  jsonToFormData(data: any): FormData {
    const formData = new FormData();

    this.buildFormData(formData, data);

    return formData;
  }

  /**
   * Send Multi-Part Form Data request
   * @param url: request url
   * @param body: request body
   * @param auth: requires authentication flag (if so, it will add token to header)
   * @param fileFieldName: File Name
   * @param file: File | Blob
   */
  multiPartFormDataRequest<T>(
    method: 'POST' | 'PUT',
    url: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: object,
    auth: boolean = true,
    fileFieldName: string,
    file: File | Blob
  ): Observable<T> {
    const formData = this.jsonToFormData(body);
    if (file) {
      formData.append(fileFieldName, file);
    }
    let header: HttpHeaders = null;

    if (auth) {
      header = HttpService.formatHeader();
    }
    let observable: Observable<T> = null;
    if (method == 'POST') {
      observable = this.http
        .post<T>(this.apiURL + url, formData, { headers: header });
    } else if (method == 'PUT') {
      // the server does not process form data with PUT method
      // use POST method and method spoofing
      formData.append('_method', 'PUT')
      observable = this.http
        .post<T>(this.apiURL + url, formData, { headers: header });
    }
    return observable
      ?.pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  /**
   * Send Multi-Part Form Data request Multiple Files
   * @param url: request url
   * @param body: request body
   * @param auth: requires authentication flag (if so, it will add token to header)
   * @param fileName: File Name
   * @param files: File[] | Blob[]
   */
  multiPartFormDataRequestMultiple<T>(
    method: 'POST' | 'PUT',
    url: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: object,
    auth: boolean = true,
    fileName: string,
    files: File[] | Blob[]
  ): Observable<T> {
    const formData = this.jsonToFormData(body);
    files.forEach((file) => {
      if (file) {
        formData.append(fileName + '[]', file);
      }
    });
    let header: HttpHeaders = null;

    if (auth) {
      header = HttpService.formatHeader();
    }

    let observable: Observable<T> = null;
    if (method == 'POST') {
      observable = this.http.post<T>(this.apiURL + url, formData, { headers: header })
    } else if (method == 'PUT') {
      observable = this.http.put<T>(this.apiURL + url, formData, { headers: header })
    }
    return observable
      ?.pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }
}
