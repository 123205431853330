import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppState } from '@app/store/app.state';
import { map } from 'rxjs/operators';
import { UserModel } from '@app/models';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class RegistrationCompletedGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.store.selectOnce(AppState.user).pipe(
      map((user: UserModel) => {
        if (!user) return true;
        if (!user.registration_completed) {
          this.router.navigate(['/onboarding/select']);
          return false;
        }
        return true;
      })
    );
  }
}
