import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppState } from '@app/store/app.state';
import { ILocale, TranslationService } from '@app/shared/translation';
import { AppService, MapService, RegionService } from '@app/services';
import { Store } from '@ngxs/store';
import { UpdateCountryAction, UpdateLocaleAction } from '@app/store/app.action';
import { CountryModel } from '@app/models';

const DEFAULT_COUNTRY = 'CH';

@Injectable({
  providedIn: 'root',
})
export class LocaleGuard implements CanActivate {
  constructor(
    private appService: AppService,
    private mapService: MapService,
    private regionService: RegionService,
    private router: Router,
    private store: Store,
    private translationService: TranslationService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const routeLocale = route.paramMap.has('locale') ? route.paramMap.get('locale') : null;

    return this.getFinalLocale(routeLocale).then(locale => {
      if (locale != routeLocale && locale.length == 5) {
        const newUrl = this.appService.replaceLocaleRouteSegment(state.url, locale, false);
        const extrasState = this.router.getCurrentNavigation()?.extras?.state;
        this.router.navigateByUrl(newUrl, { state: extrasState });
        return false;
      }

      return true;
    }).catch(() => {
      return true;
    });
  }

  protected async getFinalLocale(routeLocale: string): Promise<string> {
    let locale = routeLocale;
    let language = locale ? locale.split('-')[0] : null;
    if (language) language = language.toLowerCase();
    let countryCode = locale && locale.indexOf('-') ? locale.split('-')[1] : null;
    if (countryCode) countryCode = countryCode.toLocaleUpperCase();
    let country: CountryModel = null;

    if (!language || !countryCode) {
      const currentLocale = this.translationService.getCurrentLocale();

      if (!language) {
        language = currentLocale.language;
      }

      if (!countryCode) {
        ({ countryCode, country } = await this.getCountry(currentLocale));
      }
    }

    locale = countryCode ? `${language.toLowerCase()}-${countryCode.toUpperCase()}` : language.toLowerCase();

    if (!country || country.short != countryCode) {
      try {
        country = await this.regionService.getCountryByCode(countryCode).toPromise();
      } catch(e) {
        // ignore
      }
    }

    await this.setLocaleAndCountry(locale, country);

    return locale;
  }

  protected async getCountry(currentLocale: ILocale): Promise<{ countryCode: string, country: CountryModel }> {
    let country = await this.store.selectOnce(AppState.country).toPromise();
    let countryCode = country?.short;
    if (!countryCode && this.appService.isBrowser()) {
      try {
        const countryResult = await this.mapService.getMyCountry().toPromise();
        country = countryResult?.data?.country;
        countryCode = countryResult?.data?.code;
      } catch(e) {
        // ignore
      }
    }
    if (!countryCode) {
      countryCode = currentLocale.country;
    }
    if (!countryCode) {
      countryCode = DEFAULT_COUNTRY;
    }

    return { country, countryCode };
  }

  protected async setLocaleAndCountry(locale: string, country: CountryModel) {
    if (locale)
      await this.translationService.changeLocale(locale).toPromise();
      this.store.dispatch(new UpdateLocaleAction(locale));
    if (country)
      this.store.dispatch(new UpdateCountryAction(country));
  }
}
