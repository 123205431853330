/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { MessageGroupModel } from '@app/models/message/message.group.model';

export class MessageModel {
  id: string;
  body?: string;
  created_at: Date;
  data: any;
  from_channel_user_id: string;
  message_channel_id: string;
  message_group: MessageGroupModel;
  message_group_id: string;
  subject: string;
  type: string;
}
