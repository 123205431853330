import { CountryModel, ExpertModel, ISpinnerState, IToast, PetOwnerModel, UserModel } from '@app/models';
import { ConnectionModel } from '@app/models/user/connection.model';
import { UserInfoModel } from '@app/models/user/user.info.model';
import { AppTypes } from '@app/store/app.model';

export class AddConnectionAction {
  static readonly type = AppTypes.AddConnection;
  constructor(public connection: ConnectionModel) {}
}

export class AddSubscriptionAction {
  static readonly type = AppTypes.AddSubscription;
  constructor(public productId: string, public key: string, public frequency: string) {}
}

export class ChangeFeatureStatusAction {
  static readonly type = AppTypes.ChangeFeatureStatus;
  constructor(public data: { feature: string; active: boolean }) {}
}

export class IncreaseActionBadgeCount {
  static readonly type = AppTypes.IncreaseActionBadgeCount;
}

export class IncreaseMessageBadgeCount {
  static readonly type = AppTypes.IncreaseMessageBadgeCount;
}

export class LogoutUserAction {
  static readonly type = AppTypes.LogoutUser;
}

export class RemoveSubscriptionAction {
  static readonly type = AppTypes.RemoveSubscription;
  constructor(public productId: string, public key: string, public frequency: string) {}
}

export class UpdateConnectionAction {
  static readonly type = AppTypes.UpdateConnection;
  constructor(public connection: ConnectionModel) {}
}

export class UpdateConnectionStatusAction {
  static readonly type = AppTypes.UpdateConnectionStatus;
  constructor(public connectionId: string, public status: string, public invited_by: number) {}
}

export class UpdateCountryAction {
  static readonly type = AppTypes.UpdateCountry;
  constructor(public country: CountryModel) {}
}

export class UpdateExpertAction {
  static readonly type = AppTypes.UpdateExpert;
  constructor(public expert: ExpertModel) {}
}

export class UpdateFeaturesAction {
  static readonly type = AppTypes.UpdateFeatures;
  constructor(public features: string[]) {}
}

export class UpdateLanguageAction {
  static readonly type = AppTypes.UpdateLanguage;
  constructor(public lang: string) {}
}

export class UpdateLocaleAction {
  static readonly type = AppTypes.UpdateLocale;
  constructor(public locale: string) {}
}

export class UpdatePetOwnerAction {
  static readonly type = AppTypes.UpdatePetOwner;
  constructor(public petOwner: PetOwnerModel) {}
}

export class UpdateSpinnerAction {
  static readonly type = AppTypes.UpdateSpinner;
  constructor(public spinnerState: ISpinnerState) {}
}

export class UpdateToastAction {
  static readonly type = AppTypes.UpdateToast;
  constructor(public toast: IToast) {}
}

export class UpdateUnreadActionCountAction {
  static readonly type = AppTypes.UpdateUnreadActionCountAction;
  constructor(public actionCount: number) {}
}

export class UpdateUserInfoAction {
  static readonly type = AppTypes.UpdateUserInfo;
  constructor(public userInfo: UserInfoModel) {}
}

export class UpdateUserAction {
  static readonly type = AppTypes.UpdateUser;
  constructor(public user: UserModel) {}
}

export class UpdateUserObjectAction {
  static readonly type = AppTypes.UpdateUserObject;
  constructor(public user: UserModel) {}
}

export type AppActionTypes =
  | AddConnectionAction
  | AddSubscriptionAction
  | ChangeFeatureStatusAction
  | IncreaseActionBadgeCount
  | IncreaseMessageBadgeCount
  | LogoutUserAction
  | RemoveSubscriptionAction
  | UpdateConnectionAction
  | UpdateConnectionStatusAction
  | UpdateCountryAction
  | UpdateExpertAction
  | UpdateFeaturesAction
  | UpdateLanguageAction
  | UpdateLocaleAction
  | UpdatePetOwnerAction
  | UpdateSpinnerAction
  | UpdateToastAction
  | UpdateUnreadActionCountAction
  | UpdateUserAction
  | UpdateUserInfoAction
  | UpdateUserObjectAction;
