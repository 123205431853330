/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { RegionModel } from '@app/models/region/region.model';

export class AddressModel {
  id: string;
  street: string;
  streetnumber: string;
  zip: string;
  city: string;
  country_id: string;
  postbox: string;
  region: RegionModel = new RegionModel();
}
