import { Injectable } from '@angular/core';
import { PaginationModel } from '@app/models';
import { QueryCommon } from '@app/models/app/query-common';
import { ConnectionDataModel } from '@app/models/user/connection.data.model';
import { ConnectionModel } from '@app/models/user/connection.model';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private url = 'connection';

  static findConnection(
    connections: ConnectionModel[],
    user1_id: string,
    expert1_id: string,
    user2_id: string,
    expert2_id: string
  ): ConnectionModel {
    if (!connections) return null;
    const connection = connections.filter(
      (connection) =>
        (((user1_id && connection.user1_id == user1_id) || (expert1_id && connection.expert1_id == expert1_id)) &&
          ((user2_id && connection.user2_id == user2_id) || (expert2_id && connection.expert2_id == expert2_id))) ||
        (((user2_id && connection.user1_id == user2_id) || (expert2_id && connection.expert1_id == expert2_id)) &&
          ((user1_id && connection.user2_id == user1_id) || (expert1_id && connection.expert2_id == expert1_id)))
    )[0];
    if (!connection) {
      return null;
    }
    return new ConnectionModel().deserialize(connection);
  }

  constructor(private httpService: HttpService, private store: Store) {}

  /**
   * Get all voucher list
   * @param queryParams: Params
   */
  getList(queryParams: Partial<QueryCommon> = {}): Observable<PaginationModel<ConnectionDataModel>> {
    return this.httpService.getRequest<PaginationModel<ConnectionDataModel>>(`${this.url}`, true, queryParams);
  }

  /**
   * Find a connection for given pair (both ways)
   @param user1_id: number
   @param expert1_id: number
   @param user2_id: number
   @param expert2_id: number
   */
  getConnection(user1_id: string, expert1_id: string, user2_id: string, expert2_id: string): ConnectionModel {
    const connections = this.store.selectSnapshot(AppState.connections);
    return ConnectionService.findConnection(connections, user1_id, expert1_id, user2_id, expert2_id);
  }

  /**
   * Find a connection for this user/expert with given pair (both ways)
   @param user_id: number
   @param expert_id: number
   */
  getMyConnectionWith(user_id: string, expert_id: string): ConnectionModel {
    const user = this.store.selectSnapshot(AppState.user);
    const experts = this.store.selectSnapshot(AppState.experts);
    const expert = experts[0];
    const connections = this.store.selectSnapshot(AppState.connections);
    const connection = ConnectionService.findConnection(connections, user?.id, expert?.id, user_id, expert_id);
    if (!connection) return null;
    const newConnection = new ConnectionModel();
    Object.assign(newConnection, connection);
    return newConnection;
  }

  /**
   * Connect/Disconnect connection
   * @param id: Connection Id
   * @param flag: Indicate whether connect or disconnect
   */
  toggleConnection(id: string, flag: boolean): Observable<void> {
    const statusURL = flag ? 'connect' : 'disconnect';
    return this.httpService.postRequest(`${this.url}/${id}/${statusURL}`, {}, true);
  }

  /**
   * Block Connecttion
   * @param id: Connection Id
   */
  blockConnection(id: string): Observable<void> {
    return this.httpService.postRequest(`${this.url}${id}/block`, {}, true);
  }
}
