import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export enum AppEventType {
  ActionReceived = '.actionReceived',
  ConnectionCreated = '.connectionCreated',
  ConnectionUpdated = '.connectionUpdated',
  EndCall = '.endCall',
  ExpertUpdated = '.expertUpdated',
  FeatureStatusChanged = '.featureStatusChanged',
  IncomingCall = '.incomingCall',
  InvoiceStatusChanged = '.invoiceStatusChanged',
  JoinCall = '.joinCall',
  MessageReceived = '.messageReceived',
  PauseIncomingAudio = 'pauseIncomingAudio',
  PauseOutgoingAudio = 'pauseOutgoingAudio',
  PetCreated = '.petCreated',
  PetDeleted = '.petDeleted',
  PetOwnerUpdated = '.petOwnerUpdated',
  PetUpdated = '.petUpdated',
  PlayDisconnectAudio = 'playDisconnectAudio',
  PlayIncomingAudio = 'playIncomingAudio',
  PlayOutgoingAudio = 'playOutgoingAudio',
  ProductSubscribed = '.subscribed',
  ProductUnSubscribed = '.unsubscribed',
  RejectedCall = '.rejectedCall',
  ServiceUnavailable = 'ServiceUnavailable',
  ShowOverlayPopup = '.showOverlayPopup',
  SignIn = 'SIGN_IN',
  SignOut = 'SIGN_OUT',
  StartCall = 'StartCall',
  UpdateAvailable = 'updateAvailable',
  UserUpdated = '.userUpdated',
  OpenSideMenu = '.openSideMenu',
  CloseSideMenu = '.closeSideMenu',
}

export class AppEvent {
  // eslint-disable-next-line
  constructor(public type: AppEventType, public payload: any) {}
}

@Injectable()
export class EventQueueService {
  private eventHandler: Subject<AppEvent> = new Subject<AppEvent>();

  subscribe<T>(eventType: AppEventType, callback: (payload: T) => void): Subscription {
    return this.eventHandler
      .pipe(filter((event) => event.type === eventType))
      .pipe(map((event) => event.payload as T))
      .subscribe(callback);
  }

  dispatch(type: AppEventType, payload: unknown = null): void {
    this.eventHandler.next({ type, payload });
  }
}
