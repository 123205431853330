import { Injectable } from '@angular/core';
import { ExpertModel } from '@app/models';
import { QueryCommon } from '@app/models/app/query-common';
import { BaseListingModel } from '@app/models/base.listing.model';
import { BaseModel } from '@app/models/base.model';
import { ExpertPetOwnerModel } from '@app/models/expert/expert.pet-owner.model';
import { PaginationModel } from '@app/models/pagination.model';
import { PetOwnerImportModel } from '@app/models/petowner/pet-owner.import.model';
import { PetOwnerImportParseModel } from '@app/models/petowner/pet-owner.import.parse.model';
import { PetOwnerListingModel } from '@app/models/petowner/pet-owner.listing.model';
import { SavePetOwnerRequest } from '@app/models/requests';
import { ConnectionListingModel } from '@app/models/user/connection.listing.model';
import { PetOwnerModel } from '@app/models/user/pet.owner.model';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class PetOwnerService {
  private url = 'pet_owner';

  constructor(private httpService: HttpService) {}

  /**
   * Get Pet Owner List
   * @param params: Params
   */
  getList(params: Partial<QueryCommon> = {}): Observable<PaginationModel<PetOwnerModel>> {
    return this.httpService.getRequest<PaginationModel<PetOwnerModel>>(`${this.url}`, true, params);
  }
  /**
   * Get Expert Pet Owner List
   * @param params: Params
   */
  getExpertPetOwnerList(params: Partial<QueryCommon> = {}): Observable<PaginationModel<ExpertPetOwnerModel>> {
    return this.httpService.getRequest<PaginationModel<ExpertPetOwnerModel>>(`${this.url}`, true, params);
  }

  /**
   * Get Expert List
   * @param params: Params
   */
  getExpertList(params: Record<string, unknown> = {}): Observable<BaseListingModel<ExpertModel>> {
    return this.httpService.getRequest<BaseListingModel<ExpertModel>>(`${this.url}/expert`, true, params);
  }

  /**
   * Disconnect expert
   * @param id: Id
   */
  disconnectExpert(id: string): Observable<void> {
    return this.httpService.postRequest(`${this.url}/${id}/disconnect`, {}, true);
  }

  /**
   * Get Pet Owner from Id
   * @param id: Id
   */
  getPetOwner(id: string): Observable<PetOwnerListingModel> {
    return this.httpService.getRequest(`${this.url}/${id}`, true);
  }

  /**
   * Connect to Pet owner
   * @param id: Id
   */
  connectToPetOwner(id: string): Observable<BaseModel<ConnectionListingModel>> {
    return this.httpService.postRequest(`${this.url}/${id}/connect`, {}, true);
  }

  /**
   * Delete Pet Owner
   * @param id: Id
   */
  deletePetOwner(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }

  /**
   * Disconnect Pet Owner From Expert
   * @param id: Pet Owner Id
   */

  disconnectPetOwner(id: string): Observable<void> {
    return this.httpService.postRequest(`${this.url}/${id}/disconnect`, {}, true);
  }

  /**
   * Save Pet Owner
   * @param id: Pet Owner id (if null, it means it's create)
   * @param params: Params
   * @param image: Pet Owner Image
   * @param isProfileImageUpdated: Whether or not profile image is updated or not
   */

  savePetOwner(
    id: string,
    params: Partial<SavePetOwnerRequest>,
    image: File | Blob = null,
    isProfileImageUpdated: boolean = false
  ): Observable<void> {
    if (id) {
      // Need this case because user might try to save expert data without selecting image
      // In this case we must not pass image as it might be null
      if (isProfileImageUpdated) {
        return this.httpService.multiPartFormDataRequest('PUT', `${this.url}/${id}`, params, true, 'image', image);
      }
      return this.httpService.putRequest(`${this.url}/${id}`, params, true);
    } else {
      if (isProfileImageUpdated) {
        return this.httpService.multiPartFormDataRequest('POST', `${this.url}`, params, true, 'image', image);
      }
      return this.httpService.postRequest(`${this.url}`, params, true);
    }
  }

  /**
   * Prepare file upload for Pet Owner Import with file
   * @param file: File
   */
  prepareFileUpload(file: File): Observable<BaseModel<PetOwnerImportModel>> {
    return this.httpService.multiPartFormDataRequest('POST', `${this.url}/import/prepare`, {}, true, 'file', file);
  }

  parseFile(fileName: string, hasHeader: boolean, mapping: string[]): Observable<BaseModel<PetOwnerImportParseModel>> {
    const params = {
      filename: fileName,
      has_header: hasHeader,
      mapping,
    };
    return this.httpService.postRequest(`${this.url}/import/parse`, params, true);
  }
}
