import { MapPinModel, UserModel } from '@app/models';
import { PetBreedModel } from '@app/models/pet-breed/pet-breed.model';
import { PetSpeciesModel } from '@app/models/pet-species/pet-species.model';

export class MissedPetModel {
  id: string;
  user_id: string;
  title: string;
  created_at: Date;
  description: string;
  pin_id: string;
  image_url: string;
  type: string;
  pet_species_id: string;
  pet_breed_id: string;
  pet_owner_id: string;
  user: UserModel;
  external_url: string;
  external_source_url: string;
  pet_breeds: PetBreedModel[] = [];
  pet_species = new PetSpeciesModel();
  pin: MapPinModel;
  missed_since: Date;
  language: string;
}
