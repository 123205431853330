import { Injectable } from '@angular/core';
import { PaginationModel } from '@app/models';
import { QueryCommon } from '@app/models/app/query-common';
import { ActionModel } from '@app/models/user/action.model';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  private url = 'action';

  constructor(private httpService: HttpService) {}

  getList(params: Partial<QueryCommon> = {}): Observable<PaginationModel<ActionModel>> {
    return this.httpService.getRequest<PaginationModel<ActionModel>>(`${this.url}`, true, params);
  }

  readAction(readUntil: Date): Observable<void> {
    return this.httpService.postRequest(`${this.url}/read`, { read_until: readUntil }, true);
  }
}
