import { Injectable } from '@angular/core';
import { EventListingModel } from '@app/models/event/event.listing.model';
import { EventModel } from '@app/models/event/event.model';
import { PaginationModel } from '@app/models/pagination.model';
import { Observable } from 'rxjs';
import { HttpService, MediaService } from '@app/services';
import { ListRequest } from '@app/models/requests';
import { EventCategoryModel } from '@app/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private url = 'event';

  constructor(private httpService: HttpService, private mediaService: MediaService) {}

  getCategories(type: string|null, parentCategoryId: string|null, includeChildren = false): Observable<EventCategoryModel[]> {
    return this.httpService.getRequest<PaginationModel<EventCategoryModel>>(`event-category`, true, {
      parent_category_id: parentCategoryId,
      include_children: includeChildren,
      type: type,
    }).pipe(
      map(item => item.data as any as EventCategoryModel[])
    );
  }

  getList(params: ListRequest = {}): Observable<PaginationModel<EventModel>> {
    return this.httpService.getRequest<PaginationModel<EventModel>>(`${this.url}`, true, params);
  }

  getEvent(id: string): Observable<EventListingModel> {
    return this.httpService.getRequest<EventListingModel>(`${this.url}/${id}`);
  }

  deleteEvent(id: string): Observable<void> {
    return this.httpService.deleteRequest(`${this.url}/${id}`, {}, true);
  }
}
