import { Deserializable } from '@app/models/deserializable.model';

export type ConnectionStatus = 'blocked' | 'connected' | 'disconnected' | 'invited';
export class ConnectionModel implements Deserializable {
  public id: string;
  public user1_id: string;
  public expert1_id: string;
  public user2_id: string;
  public expert2_id: string;
  public status: ConnectionStatus;
  public invited_by: number; // 1 = user1 / expert1 invited; 2 = user2 / expert2 invited

  deserialize(input: unknown): this {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Object.assign(this, input);
  }

  isInviting(userId: string, expertId: string): boolean {
    if (this.invited_by == 1) {
      if (expertId && expertId == this.expert1_id) return true;
      if (userId && userId == this.user1_id) return true;
    } else if (this.invited_by == 2) {
      if (expertId && expertId == this.expert2_id) return true;
      if (userId && userId == this.user2_id) return true;
    } else {
      return false;
    }
  }
}
