import { ExpertModel, PetOwnerModel, UserModel } from '@app/models';
import { Deserializable } from '@app/models/deserializable.model';
import { UserProductModel } from '@app/models/product/userProductModel';
import { ConnectionModel } from '@app/models/user/connection.model';

export class UserInfoModel implements Deserializable {
  public connections: ConnectionModel[];
  public experts: ExpertModel[];
  public features: string[];
  public pet_owner: PetOwnerModel[];
  public products: UserProductModel[];
  public user: UserModel;

  deserialize(input: unknown): this {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Object.assign(this, input);
  }
}
