import { Injectable } from '@angular/core';
import { BaseListingModel } from '@app/models/base.listing.model';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';
import { AppState } from '@app/store/app.state';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { UpdateFeaturesAction } from '@app/store/app.action';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private url = 'main/features';

  constructor(private httpService: HttpService, private store: Store) {}

  loadFeatures(): void {
    this.httpService.getRequest<BaseListingModel<string>>(`${this.url}`, true).subscribe((response) => {
      this.store.dispatch(new UpdateFeaturesAction(response.data));
    });
  }

  isActive(feature: string): Observable<boolean> {
    return this.store.select(AppState.features).pipe(
      map((features: string[] | null) => {
        return features && !!features.find((item) => item == feature);
      })
    );
  }
}
