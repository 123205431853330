export class OfferMapDataModel {
  available_until: Date;
  categories: { name: string }[] = [];
  channel_id: string;
  created_at: Date;
  currency: string;
  deleted_at: string;
  description: string;
  external_id: string;
  external_source_url: string;
  id: string;
  image_urls: string[];
  is_auction: boolean;
  location: string;
  pin_id: string;
  price: number;
  published_at: Date;
  subtitle: string;
  title: string;
  updated_at: string
}
