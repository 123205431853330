import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from './components/components.module';
import { LibrariesModule } from './libraries/libraries.module';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [FormsModule, IonicModule, RouterModule, LibrariesModule, ComponentsModule],
})
export class SharedModule {}
