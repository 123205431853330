import { Injectable } from '@angular/core';
import { PaginationModel } from '@app/models';
import { BaseListingModel } from '@app/models/base.listing.model';
import { BaseModel } from '@app/models/base.model';
import { ChannelListingModel } from '@app/models/message/channel.listing.model';
import { ChannelModel } from '@app/models/message/channel.model';
import { MessageListingModel } from '@app/models/message/message.listing.model';
import { MessageModel } from '@app/models/message/message.model';
import { ParticipantModel } from '@app/models/message/participant.model';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services';

interface SendMessageInterface {
  method?: string;
  subject?: string;
  message?: string;
  recipients: ParticipantModel[];
  type?: string;
}
@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private url = 'message';

  constructor(private httpService: HttpService) {}

  /**
   * Get Channel List
   */
  getChannelList(): Observable<BaseListingModel<ChannelModel>> {
    return this.httpService.getRequest<BaseListingModel<ChannelModel>>(`${this.url}/channel`, true);
  }

  /**
   * Get Channel From Pet Owner Id, This api creates and return channel info if not exists
   * @param id: Channel Id
   */
  getChannelById(id: string): Observable<BaseModel<ChannelListingModel>> {
    return this.httpService.getRequest(`${this.url}/channel/${id}/get`, true);
  }

  /**
   * Get Channel From Pet Owner Id, This api creates and return channel info if not exists
   * @param petOwnerId: Pet Owner ID
   * @param expertId: Expert ID
   */
  getChannel(petOwnerId: string, expertId: string): Observable<BaseModel<ChannelModel>> {
    let params = {};
    if (petOwnerId) {
      params = { pet_owner_id: petOwnerId };
    }
    if (expertId) {
      params = { expert_id: expertId };
    }
    return this.httpService.getRequest<BaseModel<ChannelModel>>(`${this.url}/channel/get`, true, params);
  }

  /**
   * Read Channel
   * @param id: Channel Id
   * @param readUntil: Read Until
   */
  readChannel(id: string, readUntil: Date): Observable<void> {
    return this.httpService.postRequest(`${this.url}/channel/${id}/read`, { read_until: readUntil }, true);
  }

  /**
   * Get Message List
   * @param id: Channel Id
   * @param params: Params
   */
  getMessageList(
    id: string,
    params: { page_length: number } = {} as { page_length: number }
  ): Observable<PaginationModel<MessageModel>> {
    return this.httpService.getRequest<PaginationModel<MessageModel>>(`${this.url}/${id}/message_list`, true, params);
  }

  sendMessage(params: SendMessageInterface, file?: File): Observable<MessageListingModel> {
    if (!params.method) {
      params.method = 'chat';
    }
    if (!params.type) {
      params.type = 'text';
    }
    if (file) {
      return this.httpService.multiPartFormDataRequest('POST', `${this.url}/send`, params, true, 'file', file);
    } else {
      return this.httpService.postRequest(`${this.url}/send`, params, true);
    }
  }
}
