import { Component } from '@angular/core';
import { UserShortInfoModel } from '@app/models/user/user.short.info.model';
import { IncomingCallModel } from '@app/models/call/incoming.call.model';
import { AppService, UserService, EventQueueService, CallService, AppEventType } from '@app/services';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-incoming-call-popup',
  templateUrl: './app-incoming-call-popup.component.html',
  styleUrls: ['./app-incoming-call-popup.scss'],
})
export class AppIncomingCallPopupComponent {
  incomingUser: UserShortInfoModel;
  roomData: IncomingCallModel;

  constructor(
    private eventQueue: EventQueueService,
    private userService: UserService,
    private appService: AppService,
    private router: Router,
    private callService: CallService
  ) {
    this.eventQueue.subscribe<IncomingCallModel>(AppEventType.IncomingCall, (data) => {
      this.roomData = data;
      this.eventQueue.dispatch(AppEventType.PlayIncomingAudio);
      this.didGetIncomingCall(data);
    });
    this.eventQueue.subscribe(AppEventType.EndCall, () => {
      if (this.isViewActive) {
        this.removePopup();
        this.eventQueue.dispatch(AppEventType.PlayDisconnectAudio);
      }
    });
  }

  get isViewActive(): boolean {
    const incomingPopup: HTMLElement = document.querySelector('app-incoming-call-popup');
    return incomingPopup.style.display !== 'none';
  }

  didGetIncomingCall(data: IncomingCallModel): void {
    this.toggleIncomingPopup(true);
    this.userService.getUserShortInfo(data.user_id).subscribe((userData) => {
      if (!userData.data.user) return;
      const userShortInfo = new UserShortInfoModel();
      Object.assign(userShortInfo, userData.data.user);
      this.incomingUser = userShortInfo;
    });
  }

  toggleIncomingPopup(flag: boolean): void {
    const incomingPopup: HTMLElement = document.querySelector('app-incoming-call-popup');
    incomingPopup.style.display = flag ? 'unset' : 'none';
  }

  async acceptCallBtnTapped(): Promise<void> {
    if (!this.roomData || !this.incomingUser) {
      return;
    }
    this.appService.showSpinner();
    this.callService.joinRoom(this.roomData.room_id).subscribe(
      (data) => {
        this.appService.hideSpinner();
        const token = data.data.token;
        this.eventQueue.dispatch(AppEventType.StartCall, {
          roomId: this.roomData.room_id,
          token,
          name: this.incomingUser.full_name,
          imageUrl: this.incomingUser.getImageUrl(),
          userId: this.roomData.user_id,
          fromIncoming: true,
        });
        this.closePopup();
      },
      (error: HttpErrorResponse) => {
        this.appService.processAPIErrorMessage(error);
        this.appService.hideSpinner();
      }
    );
  }

  removePopup(): void {
    this.toggleIncomingPopup(false);
    this.roomData = null;
    this.incomingUser = null;
  }

  closePopup(): void {
    this.removePopup();
    this.eventQueue.dispatch(AppEventType.PauseIncomingAudio);
  }

  async rejectCallBtnTapped(): Promise<void> {
    if (!this.roomData) {
      return;
    }
    this.appService.showSpinner();
    this.callService.rejectCall(this.roomData.room_id).subscribe(
      () => {
        this.removePopup();
        this.eventQueue.dispatch(AppEventType.PlayDisconnectAudio);
        this.appService.hideSpinner();
      },
      () => {
        this.appService.hideSpinner();
      }
    );
  }
}
